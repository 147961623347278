import { DealsLoading } from 'components/Common/Deals/DealPanel/DealsLoading';
import React from 'react';

export function Loading({ isSimo, numberOfSkeletonsToShow = 6 }): JSX.Element {
	if (isSimo) {
		return <DealsLoading />;
	}

	return (
		<>
			{Array.from(Array(numberOfSkeletonsToShow)).map((x, index) => (
				<div
					className="o-flex-item u-grid--6--bp-small u-grid--4--bp-medium"
					key={index}
				>
					<div className="u-d--block u-px--micro--bp-medium u-pos--relative u-mx--auto u-mb--xsmall">
						<div
							className="loading-skeleton"
							style={{ height: '470px', borderRadius: '10px' }}
						></div>
					</div>
				</div>
			))}
		</>
	);
}
