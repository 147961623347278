import React, { useContext } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { DealContext, TariffExtrasModalContent } from '.';
import { Deal as DealType } from 'components/types';
import { LinkIcon } from 'components/Common/Icons/LinkIcon';
import { trackProductView } from 'components/Common/analytics';

const ViewPlanDetailsPropTypes = {
	openModalWithDeal: PropTypes.func.isRequired,
};

function ViewPlanDetails({
	openModalWithDeal,
}: InferProps<typeof ViewPlanDetailsPropTypes>) {
	const { deal }: { deal: DealType } = useContext(DealContext);

	function trackViewPlanDetailsClicked() {
		globalThis.dataLayer.push({
			event: 'View Plan Details Click',
			eventCategory: 'Ecommerce',
			eventAction: window.MPD.Analytics.pageType,
			eventLabel: 'Plan Details Clicked',
		});
	}

	return (
		<div>
			<button
				onClick={e => {
					e.preventDefault();
					openModalWithDeal({
						deal,
						Renderer: TariffExtrasModalContent,
						title: 'Plan Details',
					});
					trackProductView(deal, 'tariff');
					trackViewPlanDetailsClicked();
				}}
				className="c-btn--deal-modal-link u-mb--tiny u-font-group--link-caption u-ta--left u-fw--700 u-p--none theme-plan-details"
				aria-label="View plan details"
			>
				Plan details{' '}
				<span className="u-ml--micro">
					<LinkIcon colour="#0074CC" />
				</span>
			</button>
		</div>
	);
}

ViewPlanDetails.propTypes = ViewPlanDetailsPropTypes;

export { ViewPlanDetails };
