// Shared resources like polyfills

// Uncomment for IE11 Polyfills (adds 124kb to bundle)
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
// import 'core-js/stable/promise';

import route from 'ziggy';
import { Ziggy } from './ziggy';

window.Ziggy = Ziggy;
window.Router = route;

const token = document.head.querySelector('meta[name="csrf-token"]');

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
