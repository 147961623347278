/**
 * Props required for DealPanel
 */
import PropTypes from 'prop-types';

const ColourPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  primaryHex: PropTypes.string.isRequired,
}).isRequired;

const CapacityPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
});

const HandsetImages = PropTypes.shape({
  all: PropTypes.object.isRequired,
  featured: PropTypes.string.isRequired,
});

const HandsetPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  is5g: PropTypes.bool.isRequired,
  launchAt: PropTypes.string.isRequired,
  defaultColour: ColourPropType,
  defaultCapacity: CapacityPropType,

  promo: PropTypes.shape({
    tagLine: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
  }),

  manufacturer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,

  capacities: PropTypes.arrayOf(CapacityPropType).isRequired,

  colours: PropTypes.arrayOf(ColourPropType).isRequired,
  images: PropTypes.objectOf(HandsetImages).isRequired,
});

const TariffPropType = PropTypes.shape({
  lineRental: PropTypes.number.isRequired,
  lineRentalDisplay: PropTypes.string.isRequired,
  lineRentalPromo: PropTypes.number.isRequired,
  lineRentalPromoDisplay: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  minutes: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  texts: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  dataDisplay: PropTypes.string.isRequired,
  dataSpeed: PropTypes.string.isRequired,
  dataPromo: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  dataPromoDisplay: PropTypes.string.isRequired,

  type: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,

  network: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,

  extras: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      tooltip: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ),

  promo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    tagLine: PropTypes.string.isRequired,
    primaryHex: PropTypes.string.isRequired,
    secondaryHex: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
  }),
});

const DealPropType = PropTypes.shape({
  cashback: PropTypes.number.isRequired,
  upfront: PropTypes.number.isRequired,

  variant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    oracleCode: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    launchAt: PropTypes.string.isRequired,
    dataSpeed: PropTypes.string.isRequired,
    deliveryDate: PropTypes.string,
    stock: PropTypes.string.isRequired,
    images: HandsetImages,

    handset: HandsetPropType,
  }),

  tariff: TariffPropType,
});

export { DealPropType };
