import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/Common/Image/';
import { Brand as BrandType, FeaturedHandset } from 'components/types';
import { urlToRelative } from 'components/Common/url-to-relative';
import { ChevronRightIcon } from 'components/Common/Icons/ChevronRightIcon';

function BrandSlide({ name, slug, logos, featuredHandsets }: BrandType) {
	const brandUrl = urlToRelative(
		globalThis
			.Router('brands.show', {
				brand_slug: slug,
			})
			.toString()
	);

	function trackBrandSlideClicked() {
		globalThis.dataLayer.push({
			event: 'Phone Brands Slide Click',
			eventCategory: 'HomePage',
			eventAction: 'Brands',
			eventLabel: slug,
		});
	}

	return (
		<div className="u-pl--small u-px--small u-pl--none--bp-medium">
			<a
				href={brandUrl}
				className={`brand-slide brand-slide-${slug}`}
				onClick={() => trackBrandSlideClicked()}
			>
				<div
					className="brand-slide-inner u-p--small u-pos--relative"
					style={{ minHeight: '210px' }}
				>
					<div className="u-d--flex u-jc--space-between">
						<h3 className="u-mb--tiny u-fw--600 u-fz--display-small">
							{name} {name === 'Apple' ? 'iPhones' : 'Phones'}
						</h3>

						{slug !== 'apple' && (
							<div className="brand-logo">
								<Image
									src={logos.standard}
									alt={`${name} Logo`}
									cdnOptions={{ height: 110, fit: 'contain' }}
								/>
							</div>
						)}
					</div>

					<div
						className=" u-pos--absolute"
						style={{
							right: '15px',
							bottom: '20px',
						}}
					>
						<ChevronRightIcon colour="#0f2636" />
					</div>

					<div className="brand-handsets u-pos--absolute double-image">
						{featuredHandsets.map(
							(handset: FeaturedHandset, index) =>
								handset.images.featured === 'combined' ? (
									<Image
										key={index}
										path={
											handset.images.all[
												handset.images.featured
											].image
										}
										alt={
											handset.images.all[
												handset.images.featured
											].alt_text || handset.name
										}
										cdnOptions={{
											height: 125,
											fit: 'contain',
										}}
									/>
								) : (
									<div
										key={index}
										className="u-pos--relative"
										style={{
											height: '125px',
											width: '94px',
										}}
									>
										<Image
											className="u-pos--absolute"
											path={handset.images.all.back.image}
											alt={
												handset.images.all.back
													.alt_text || handset.name
											}
											cdnOptions={{
												height: 125,
												fit: 'contain',
											}}
											style={{ left: '0px' }}
										/>
										<Image
											className="u-pos--absolute"
											path={
												handset.images.all.front.image
											}
											alt={
												handset.images.all.front
													.alt_text || handset.name
											}
											cdnOptions={{
												height: 125,
												fit: 'contain',
											}}
											style={{ right: '0px' }}
										/>
									</div>
								)
						)}
					</div>
				</div>
			</a>
		</div>
	);
}

BrandSlide.propTypes = {
	name: PropTypes.string.isRequired,
	featuredHandsets: PropTypes.array.isRequired,
	slug: PropTypes.string.isRequired,
};

export { BrandSlide };
