import { CSSProperties } from 'react';
import { TariffPromo } from 'components/types';

// TODO: Be cool if we could replace this with an API request and a service in cloud9 that can set a current theme.
export const IS_THEME_ACTIVE = false;
const SPECIAL_THEME_COLOUR = 'ff39ef';
const SPECIAL_THEME_SECONDARY_COLOUR = '021442';

const isCurrentDealSpecial = primaryHex => primaryHex.toLowerCase() === SPECIAL_THEME_COLOUR;

export const getPanelFooterStylesFromPromo = (promo: TariffPromo): CSSProperties => {
  const style: CSSProperties = {};

  const isSpecialColour = isCurrentDealSpecial(promo.primaryHex);
  const backgroundColor = isSpecialColour ? SPECIAL_THEME_SECONDARY_COLOUR : promo.primaryHex;
  const borderColour = isSpecialColour ? SPECIAL_THEME_COLOUR : promo.primaryHex;
  const textColour = isSpecialColour ? '#ffffff' : `#${promo.secondaryHex}`;

  style.backgroundColor = `#${backgroundColor}`;
  style.color = textColour;
  style.borderColor = `#${borderColour}`;
  style.borderRadius = '0 0 8px 8px';

  return style;
};
