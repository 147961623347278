import React from 'react';

interface IconProps {
	colour?: string;
}

export const ErrorCircle = ({ colour }: IconProps) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM13.5355 14.5363C13.8009 14.5363 14.0554 14.4308 14.243 14.243C14.4308 14.0554 14.5363 13.8009 14.5363 13.5355C14.5363 13.2701 14.4308 13.0156 14.243 12.828L11.414 10L14.243 7.172C14.4308 6.98443 14.5363 6.72991 14.5363 6.4645C14.5363 6.19909 14.4308 5.94457 14.243 5.757C14.0554 5.56922 13.8009 5.46371 13.5355 5.46371C13.2701 5.46371 13.0156 5.56922 12.828 5.757L10 8.586L7.172 5.757C6.98443 5.56922 6.72991 5.46371 6.4645 5.46371C6.19909 5.46371 5.94457 5.56922 5.757 5.757C5.56922 5.94457 5.46371 6.19909 5.46371 6.4645C5.46371 6.72991 5.56922 6.98443 5.757 7.172L8.586 10L5.787 12.766C5.40161 13.1474 5.3853 13.7648 5.75 14.166C5.93627 14.3705 6.19816 14.4899 6.47472 14.4963C6.75129 14.5027 7.01844 14.3957 7.214 14.2L10 11.414L12.828 14.243C13.0156 14.4308 13.2701 14.5363 13.5355 14.5363Z"
			fill={colour || '#B50016'}
		/>
	</svg>
);
