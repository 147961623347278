import { position } from 'components/Common/Carousel/Carousel';
import {
	Directions,
	ReducerProps,
	ActionProps,
} from 'components/Common/Carousel/Carousel.props';

// We want to detect if we're on a overflow banner e.g. first or last.
const isOverflow = (size: number, current: number): boolean =>
	current <= 0 || current >= size - 1;

/**
 * If next slide is associated with a overflow element we want to simulate an
 * infinate scroll so we jump the position to first or last.
 *
 * @param direction {enum} - enum Direction.Next | Direction.Previous
 * @param size {number} - Size is the amount of slides
 * @param current {number} - current Slide
 * @param slides {useRef<HTMLDivElement>} - used for changing the position of the element.
 * @returns number | null
 */
const jumpSlide = ({ direction, size, current, slides }): number | null => {
	// Lets detect if we're on a "fake" slide.
	if (isOverflow(size, current)) {
		switch (direction) {
			case Directions.Previous:
				// Only jump if we're on the first slide
				if (current < 1) {
					slides.current.style.transform = position(size, size - 2);
					return size - 2;
				}
				break;
			case Directions.Next:
				// Only jump if we're on the last slide
				if (current > size - 2) {
					slides.current.style.transform = position(size, 1);
					return 1;
				}
				break;
		}
	}
	return null;
};

export const carouselReducer = (
	state: ReducerProps,
	action: ActionProps
): ReducerProps => {
	// Pause the scrolling during mouse over.
	if ('pause' in action) {
		state.paused = action.pause;
	}

	// Next or Previous button has been clicked.
	if (action.type) {
		// disable animations to snap it to next/prev slide.
		state.slides.current.classList.add('no-transition');
		const currentChange = jumpSlide({
			direction: action.type,
			size: state.slideCount,
			current: state.current,
			slides: state.slides,
		});
		// trick to update the element to make sure translate3d updates.
		state.slides.current.offsetHeight;
		// enable transition animation again.
		state.slides.current.classList.remove('no-transition');

		// if jump has happened we'll update the current slide number.
		if (currentChange !== null) {
			state.current = currentChange;
		}

		switch (action.type) {
			case Directions.Previous:
				state.current = state.current - 1;
				break;
			case Directions.Next:
				state.current = state.current + 1;
				break;
		}

		// Stop the animation if a user has clicked the button(s)
		if (!action.nonUser) state.locked = true;
	}

	// Dots have been clicked.
	if (action.to) {
		state.current = action.to;
		// Stop the animation if a user has clicked the button(s)
		if (!action.nonUser) state.locked = true;
	}

	// Apply the new location to slide to next.
	state.slides.current.style.transform = position(
		state.slideCount,
		state.current
	);

	state.theme = state.slideTheme[state.current] || 'default';

	// Set the active status for dots.
	// Technically we can have 2 active at once (helps with debugging) due to having "fake" slides.
	switch (state.current) {
		case 0:
			state.active = [0, state.slideCount - 2];
			break;
		case state.slideCount - 1:
			state.active = [1, state.slideCount - 1];
			break;
		default:
			state.active = [state.current];
	}

	return { ...state };
};
