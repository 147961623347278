import React from 'react';
import { HttpMethod, useFetch } from 'hooks/use-fetch';
import './Banner.scss';

export enum BannerLocation {
	Homepage = 'Homepage',
	O2 = 'O2',
	Vodafone = 'Vodafone',
	Three = 'Three',
}

interface SelfServeBanner {
	locationReleaseDesktopUrl: string;
	locationReleaseMobileUrl: string;
}

export function Banner({
	location,
}: {
	location: BannerLocation;
}): JSX.Element {
	const { response } = useFetch<SelfServeBanner>({
		url: `/api/banner/${location}`,
		method: HttpMethod.GET,
	});

	if (location === BannerLocation.O2) return <></>;

	if (!response || !response.locationReleaseDesktopUrl) return <></>;

	const desktop = `https://media.mobilephonesdirect.co.uk${response.locationReleaseDesktopUrl}`;
	const mobile = `https://media.mobilephonesdirect.co.uk${response.locationReleaseMobileUrl}`;

	return (
		<picture>
			<source media="(min-width: 990px)" srcSet={desktop} />
			<source media="(max-width: 990px)" srcSet={mobile} />
			<img
				aria-label="Refurbished Mobiles Banner"
				src={mobile}
				title="Mobile Phones Direct"
				alt="Mobile Phones Direct"
			/>
		</picture>
	);
}
