import React, { useEffect, useReducer, useRef } from 'react';
import {
	CarouselItemsProps,
	ContextProps,
	ReducerProps,
} from './CarouselItems.props';
import { CarouselItemReducer } from './CarouselItems.reducer';
import { Controls } from './Controls';

export const Context = React.createContext<ReducerProps>(null);

/**
 *
 * @param breakpoint { '1080': 3 } will dictate how many items will show at x window size e.g. at 1080 it'll show 3 items.
 * @param itemCount how many items will be in the carousel.
 * @param hideControls optional
 * @returns JSX.Element
 */
export function CarouselItems({
	children,
	breakpoint,
	itemCount,
	hideControls,
	className,
	...props
}: CarouselItemsProps): JSX.Element {
	const scrollContainer = useRef<HTMLDivElement>(null);

	const firstBreakpointValue = breakpoint[Object.keys(breakpoint)[0]];
	const lastBreakpointValue =
		breakpoint[Object.keys(breakpoint)[Object.keys(breakpoint).length - 1]];

	const initialState: ContextProps = {
		itemCount,
		breakpoint: {
			'0': firstBreakpointValue,
			...breakpoint,
			'99999': lastBreakpointValue,
		},
		active: [0, 1, 2],
		scrollContainer,
		hideControls: false,
		forceHideControls: hideControls,
		componentId: Math.floor(Math.random() * 1e6),
	};

	const [state, dispatch] = useReducer(CarouselItemReducer, initialState);

	let css = `
	.carousel_multi.component_id_${state.componentId} .slide_container > div{
		width: ${100 / itemCount}%;
	}`;

	let previous = { amount: 1, bp: '0' };

	Object.keys(state.breakpoint).forEach(function (bp, index) {
		const amount = state.breakpoint[bp];

		if (index === 0)
			css += `
				.carousel_multi.component_id_${state.componentId} .slide_container{
					width: ${(itemCount / amount) * 100}%;
				}

				.carousel_multi.component_id_${state.componentId} .controls ul.dots li{
					display: none;
				}`;

		if (previous.bp !== bp)
			css += `
			@media screen and (min-width: ${previous.bp}px) and (max-width: ${bp}px){
				.carousel_multi.component_id_${state.componentId} .slide_container{
					width: ${(itemCount / amount) * 100}%;
				}
				.carousel_multi.component_id_${
					state.componentId
				} .controls ul.dots li:nth-child(${amount}n - ${amount - 1}){
					display: inline-block!important;
				}
			}`;

		previous = { amount, bp };
	});

	// Handle the scroll/resize event to change which dots are to be active.
	let updateTimeout;
	const handleUpdate = () => {
		clearTimeout(updateTimeout);
		updateTimeout = setTimeout(() => {
			dispatch({ update: true });
		}, 50);
	};

	useEffect(() => {
		dispatch({ update: true });

		window.addEventListener('resize', handleUpdate);
		return () => window.removeEventListener('resize', handleUpdate);
	}, []);

	return (
		<Context.Provider
			value={{
				state,
				dispatch,
			}}
		>
			<style>{css}</style>
			<div
				className={`carousel_multi component_id_${state.componentId} ${className}`}
				onScroll={handleUpdate}
				{...props}
			>
				<div className="scroll_container" ref={scrollContainer}>
					<div className="slide_container">{children}</div>
				</div>
				<Controls />
			</div>
		</Context.Provider>
	);
}
