import React, { PropsWithChildren } from 'react';
import { Flags } from 'services/LaunchDarkly/flags';
import { useLdContext } from './LaunchDarkly.context';

interface LaunchDarklyVariantProps {
	flag?: Flags;
	value: boolean | string;
}

export function LaunchDarklyVariant({
	flag,
	value,
	children,
}: PropsWithChildren<LaunchDarklyVariantProps>): JSX.Element {
	const { flags, flag: ldFlag } = useLdContext('Variant');

	if (!flag && !ldFlag) throw new Error('No flag has been set');

	if (!flags) return <></>;

	if (!flag) flag = ldFlag;

	if (!(flag in flags)) throw new Error(`${flag} was not sent from LD`);

	if (flags[flag] === value) return <>{children}</>;

	return <></>;
}
