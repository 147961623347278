import React, { PropsWithChildren, useEffect, useState } from 'react';
import { LaunchDarklyBoolean } from './LaunchDarkly.Boolean';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { LdContext } from './LaunchDarkly.context';
import { Flags } from 'services/LaunchDarkly/flags';
import { LaunchDarklyString } from './LaunchDarkly.String';

interface LaunchDarklyProps {
	flag?: Flags;
	watch?: boolean;
}

interface LDProps {
	current: any;
	previous: any;
}

function LaunchDarkly({
	flag,
	watch = false,
	children,
}: PropsWithChildren<LaunchDarklyProps>): JSX.Element {
	const [flags, setFlags] = useState<Record<Flags, any>>();
	const changeString = 'change' + (flag ? `:${flag}` : '');

	function LaunchDarklySubscribe(resp: any) {
		setFlags(current => {
			if (flag) {
				current[flag] = resp;
			} else {
				const respObject = Object.entries<LDProps>(resp);
				for (const [key, value] of respObject) {
					current[key] = value.current;
				}
			}
			return current;
		});
	}

	useEffect(() => {
		launchDarklyClient.getFlags().then(setFlags);
		if (watch) {
			launchDarklyClient.on(changeString, LaunchDarklySubscribe);
		}
		return () => {
			launchDarklyClient.close();
			if (watch) {
				launchDarklyClient.off(changeString, LaunchDarklySubscribe);
			}
		};
	}, []);

	return (
		<LdContext.Provider value={{ flags, flag }}>
			{children}
		</LdContext.Provider>
	);
}

LaunchDarkly.Boolean = LaunchDarklyBoolean;
LaunchDarkly.String = LaunchDarklyString;

export default LaunchDarkly;
