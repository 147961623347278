import React from 'react';
import { MEDIA_BASE_URL } from 'config/media';

function SimoImage({ networkSlug, ...props }) {
  return (
    <img
      src={`${MEDIA_BASE_URL}/images/core/sim-only/${networkSlug}.svg`}
      alt="Sim Card"
      {...props}
    />
  );
}

export { SimoImage };
