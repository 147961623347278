import React from 'react';

function NewContractIcon({ ...props }) {
  return (
    <svg
      width="30"
      height="59"
      viewBox="0 0 30 59"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.4643 7.44141H2.74805V50.366H26.4643V7.44141Z"
        className="primary-colour"
      />
      <path
        d="M0.0029513 29.0585C0.0029513 21.1086 -0.000368913 13.1619 0.0029513 5.2119C0.00627152 1.63291 1.57673 0.0190548 5.10944 0.012427C11.4145 -0.00414234 17.7196 -0.00414234 24.0247 0.012427C27.6902 0.0190548 29.2906 1.59315 29.2939 5.24835C29.3072 21.0356 29.3072 36.8229 29.2939 52.6069C29.2906 56.5537 27.7168 58.0682 23.7325 58.0682C17.4839 58.0682 11.2352 58.0748 4.98328 58.0649C1.66306 58.0616 0.00627152 56.398 0.0029513 53.0642C-0.00368913 45.0645 0.0029513 37.0615 0.0029513 29.0585ZM0.90605 28.9657C0.90605 37.0218 0.892769 45.0811 0.912691 53.1371C0.919331 55.9341 2.17437 57.1403 4.97996 57.1436C11.2817 57.1502 17.5868 57.1469 23.8886 57.1469C27.159 57.1469 28.2779 56.0633 28.2812 52.8157C28.2879 36.9224 28.2879 21.029 28.2812 5.13568C28.2779 2.04052 27.0428 0.817698 23.9782 0.814385C17.786 0.807757 11.5938 0.807757 5.40162 0.814385C2.10133 0.817698 0.912691 1.99412 0.90605 5.29143C0.89941 13.1851 0.90605 21.0754 0.90605 28.9657Z"
        className="secondary-colour"
      />
      <path
        d="M1.82269 28.7729C1.82269 21.9264 1.86254 15.0766 1.78949 8.23016C1.77289 6.75217 2.2344 6.37771 3.6787 6.39096C11.0363 6.46055 18.3972 6.45061 25.7548 6.39759C27.0397 6.38765 27.4946 6.72898 27.4913 8.06778C27.4514 21.9827 27.4481 35.901 27.4946 49.816C27.4979 51.178 27.0198 51.4762 25.7581 51.4696C18.3507 51.4199 10.94 51.4066 3.53261 51.4795C2.07171 51.4928 1.78949 50.9427 1.79945 49.6503C1.84594 42.6878 1.82269 35.7287 1.82269 28.7729ZM26.4653 7.44146C18.4636 7.44146 10.6379 7.44146 2.74903 7.44146C2.74903 21.8071 2.74903 36.07 2.74903 50.3661C10.7043 50.3661 18.5699 50.3661 26.4653 50.3661C26.4653 36.0203 26.4653 21.7972 26.4653 7.44146Z"
        className="secondary-colour"
      />
      <path
        d="M14.5551 52.041C15.7537 52.0377 16.6801 53.0286 16.6834 54.3044C16.6834 55.6266 15.6741 56.6274 14.3825 56.581C13.1806 56.5413 12.1978 55.5206 12.2111 54.3243C12.2244 53.0683 13.2769 52.041 14.5551 52.041Z"
        className="secondary-colour"
      />
      <path
        d="M16.5442 4.00485C15.0866 4.00485 13.6324 4.00485 12.1748 4.00485C12.1682 3.86566 12.1615 3.72317 12.1582 3.58398C13.6158 3.58398 15.0734 3.58398 16.5309 3.58398C16.5342 3.72317 16.5376 3.86235 16.5442 4.00485Z"
        className="secondary-colour"
      />
    </svg>
  );
}
export { NewContractIcon };
