import React from 'react';
import { NewContractIcon } from 'components/Common/QuickLink/NewContractIcon';
import { UpgradeIcon } from 'components/Common/QuickLink/UpgradeIcon';
import { SimOnlyIcon } from 'components/Common/QuickLink/SimOnlyIcon';
import { UnlockedIcon } from 'components/Common/QuickLink/UnlockedIcon';
import { QuickLink } from 'components/Common/QuickLink/QuickLink';
import { HandsetQuickLinks } from 'components/Common/QuickLink/HandsetQuickLinks';

window.MPD = window.MPD || {};
const { enableiPhone13QuickLinks = true } = window.MPD;

const quickLinks = [
	{
		url: `/deals/new-contract`,
		title: 'New Contract',
		text: 'Take a look at our range of smartphones & network plans',
		Icon: NewContractIcon,
		altText: 'New contract icon',
	},
	{
		url: `/deals/upgrade`,
		title: 'Upgrade',
		text: 'That time again? See our deals on the latest phones',
		Icon: UpgradeIcon,
		altText: 'Upgrade icon',
	},
	{
		url: `/deals/sim-only`,
		title: 'SIM Only',
		text: 'Seek out a better plan for your current phone',
		Icon: SimOnlyIcon,
		altText: 'Sim only icon',
	},
	{
		url: `/deals/sim-free`,
		title: 'Sim Free',
		text: 'Buy an unlocked phone to go with your current SIM',
		Icon: UnlockedIcon,
		altText: 'Unlocked icon',
	},
];

export function QuickLinks() {
	return enableiPhone13QuickLinks ? (
		<HandsetQuickLinks />
	) : (
		<div className="o-wrapper u-mb--large u-px--large">
			<div className="mpd-quick-links u-px--xsmall">
				{quickLinks.map((quickLink, i) => (
					<QuickLink key={i} {...quickLink} />
				))}
			</div>
		</div>
	);
}
