import React, { CSSProperties, MouseEvent, useEffect, useState } from 'react';
import './PropositionBar.scss';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { Flags } from 'services/LaunchDarkly/flags';
import { StickyBannerChunky } from '../StickyBannerChunky';
import { StickyBannerSkinny } from '../StickyBannerSkinny';
import { useScreenSize } from 'components/Common/use-screen-size';
import { PropositionBarModal } from './PropositionBarModal';
import { Day } from 'enums/Day.enum';
import { SalesPhoneNumber } from 'components/Common/ContactInformation';

export function isOOH(open = '9:00', closed = '15:00'): boolean {
	if (!open && !closed) return true;

	const date = new Date();

	const [openHours, openMinutes] = open.split(':').map(num => +num);
	const [closedHours, closedMinutes] = closed.split(':').map(num => +num);

	const currentHour = date.getHours();
	const currentMinute = date.getMinutes();

	const isBeforeStart =
		currentHour < openHours ||
		(currentHour === openHours && currentMinute < openMinutes);
	const isAfterEnd =
		currentHour > closedHours ||
		(currentHour === closedHours && currentMinute > closedMinutes);

	return isBeforeStart || isAfterEnd;
}

export function PropositionBar(): JSX.Element {
	const { screenSize } = useScreenSize();
	const isMobile =
		screenSize === 'xsmall' ||
		screenSize === 'small' ||
		screenSize === 'medium';

	const currentDay = new Date().getDay();
	const { open, close } = globalThis.site.json_opening_hours[
		Day[currentDay].toLowerCase()
	];

	const [isOutOfHours, setIsOutOfHours] = useState(isOOH(open, close));
	const [modalOpen, setModalOpen] = useState(false);

	function handleClick(e: MouseEvent<HTMLAnchorElement>) {
		if (isOutOfHours) {
			e.preventDefault();
			globalThis.dataLayer.push({
				event: 'OOH - Click',
				eventCategory: 'OOH',
				eventAction: 'OOH - Clicked - Displaying Modal',
				eventLabel: 'Displaying modal',
			});
			setModalOpen(true);
		}
	}

	useEffect(() => {
		if (isOutOfHours)
			globalThis.dataLayer.push({
				event: 'OOH - Show',
				eventCategory: 'OOH',
				eventAction: 'OOH - Loaded',
				eventLabel: 'Displayed to user',
			});
		const interval = setInterval(() => {
			const currentDay = new Date().getDay();
			const { open, close } = globalThis.site.json_opening_hours[
				Day[currentDay].toLowerCase()
			];
			const current = isOOH(open, close);
			if (isOutOfHours != current) setIsOutOfHours(current);
		}, 30000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const PropositionBarMedia = isMobile
		? PropositionBarMobile
		: PropositionBarDesktop;

	return (
		<>
			<PropositionBarModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
			/>
			<div className="PropositionBar">
				<PropositionBarMedia
					isOutOfHours={isOutOfHours}
					onClick={handleClick}
				/>
			</div>
		</>
	);
}

function PropositionBarDesktop({ isOutOfHours, onClick }): JSX.Element {
	return (
		<div className={`PropositionBarDesktop`}>
			<a
				href="/help/contact-us"
				onClick={onClick}
				className={`PropositionBar--Container  ${
					isOutOfHours ? 'PropositionBar--Container--OOH' : ''
				}`}
			>
				<span className="PropositionBar__bold">
					{isOutOfHours ? (
						"Need help? Click here and we'll give you a call!"
					) : (
						<>
							<span>
								Buy quicker and easier by calling free on{' '}
							</span>
							<SalesPhoneNumber returnText />
						</>
					)}
				</span>
			</a>
		</div>
	);
}

function PropositionBarMobile({ isOutOfHours, onClick }): JSX.Element {
	const [isBannerSticky, setIsBannerSticky] = useState(false);
	const [bannerToShow, setBannerToShow] = useState<
		'version-one' | 'version-two'
	>('version-one');

	useEffect(() => {
		const megaMenuSearch = document.getElementById('header-search-mobile');

		const megaMenuSearchBottom =
			megaMenuSearch.offsetTop + megaMenuSearch.offsetHeight;

		const handleScroll = (): void => {
			const yOffset = window.pageYOffset;

			setIsBannerSticky(yOffset >= megaMenuSearchBottom);
		};

		window.addEventListener('scroll', handleScroll);

		launchDarklyClient
			.getFlag<'version-one' | 'version-two'>(Flags.StickyBannerVersion)
			.then(version => {
				setBannerToShow(version);

				globalThis.dataLayer.push({
					event: 'Sticky Banner Split Variant Loaded',
					eventCategory: 'Sticky Call Us Banner',
					eventAction: 'Split Variant Loaded',
					eventLabel: `${
						version === 'version-one'
							? 'Original messaging (with phone number) loaded'
							: 'Skinny banner (without phone number) loaded'
					}`,
				});
			});

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const placeholderStyles: CSSProperties = {
		height: bannerToShow === 'version-one' ? '46px' : '36px',
	};

	if (!isBannerSticky) {
		placeholderStyles.position = 'absolute';
	}

	if (isOutOfHours)
		return (
			<div className={`PropositionBarMobile`}>
				<a
					href="/help/contact-us"
					onClick={onClick}
					className={`PropositionBar--Container  ${
						isOutOfHours ? 'PropositionBar--Container--OOH' : ''
					}`}
				>
					<span className="PropositionBar__bold">
						Need help? Tap here and
						<br />
						we&apos;ll give you a call!
					</span>
				</a>
			</div>
		);

	return (
		<>
			<div id="callUsBannerPlaceholder" style={placeholderStyles}></div>

			{(!isBannerSticky || bannerToShow === 'version-one') && (
				<StickyBannerChunky
					bannerVersion={bannerToShow}
					isBannerSticky={isBannerSticky}
					isOutOfHours={isOutOfHours}
				>
					{bannerToShow === 'version-one' && (
						<>
							Buy quicker and easier by calling free on{' '}
							<SalesPhoneNumber />
						</>
					)}
					{bannerToShow === 'version-two' && (
						<>
							Buy quicker and easier over the phone.
							<br />
							Tap to call free.
						</>
					)}
				</StickyBannerChunky>
			)}

			{isBannerSticky && bannerToShow === 'version-two' && (
				<StickyBannerSkinny isOutOfHours={isOutOfHours} />
			)}
		</>
	);
}
