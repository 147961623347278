/**
 * iPhone 13/handset quick Link component
 */
import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'components/Common/Image';
import { DealBadge } from '../Deals/DealPanel/DealBadge';
import './HandsetQuickLink.scss';

function HandsetQuickLink({
	url,
	brand,
	title,
	images,
	badgeText = null,
	linkText = null,
}) {
	const onClick = () => {
		globalThis.dataLayer.push({
			event: 'homePod',
			eventCategory: 'Quick link',
			eventAction: title,
			eventLabel: '',
		});
	};

	const css: CSSProperties = {};
	if (badgeText === 'SPECIAL OFFER') css.backgroundColor = '#1A0E60';

	return (
		<a className={`HandsetQuickLink`} href={url} onClick={onClick}>
			{badgeText && <DealBadge style={css}>{badgeText}</DealBadge>}

			{images.featured !== 'combined' ? (
				<div
					className="u-pos--relative u-mr--xsmall"
					style={{ height: '65px', width: '43px' }}
				>
					<Image
						className="mpd-quick-link__icon u-pos--absolute"
						path={images.all.back.image}
						width="30"
						height="65"
						alt={`${title} back`}
						style={{ left: '0px' }}
						cdnOptions={{ height: 65, fit: 'contain' }}
					/>
					<Image
						className="mpd-quick-link__icon u-pos--absolute"
						path={images.all.front.image}
						width="30"
						height="65"
						alt={`${title} front`}
						style={{ right: '0px' }}
						cdnOptions={{ height: 65, fit: 'contain' }}
					/>
				</div>
			) : (
				<Image
					className="mpd-quick-link__icon u-mr--xsmall"
					path={images.all.combined.image}
					width="43"
					height="65"
					alt={title}
					cdnOptions={{ height: 65, fit: 'contain' }}
				/>
			)}

			<div className="HandsetQuickLink--Copy">
				<div className="HandsetQuickLink--Copy--Brand">{brand}</div>
				<h3 className="HandsetQuickLink--Copy--Title">{title}</h3>
				<div className="HandsetQuickLink--Copy--CTA">
					{linkText ? linkText : 'Learn More'}
				</div>
			</div>
		</a>
	);
}

HandsetQuickLink.propTypes = {
	/**
	 * @param url url for quick link
	 */
	url: PropTypes.string.isRequired,
	/**
	 * @param brand brand for quick link
	 */
	brand: PropTypes.string.isRequired,
	/**
	 * @param title title for quick link
	 */
	title: PropTypes.string.isRequired,
	/**
	 * @param images handset image object
	 */
	images: PropTypes.object.isRequired,
	/**
	 * @param badgeText handset badge text
	 */
	badgeText: PropTypes.string,
	/**
	 * @param linkText handset link text
	 */
	linkText: PropTypes.string,
};

export { HandsetQuickLink };
