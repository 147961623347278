export function getDataFromScriptById(id: string) {
  let data;
  try {
    data = JSON.parse(document.getElementById(id).innerHTML);
  } catch (e) {
    throw new Error(
      `Couldn't access data from script tag (id: ${id}): ${e.message}`
    );
  }
  return data;
}
