import React, { useState, useEffect } from 'react';

// Strata breakpoints
const screenSizes = [
  {
    queryString: '(max-width: 543px)',
    size: 'xsmall',
  },
  {
    queryString: '(max-width: 767px)',
    size: 'small',
  },
  {
    queryString: '(max-width: 989px)',
    size: 'medium',
  },
  // TODO: review breakpoints (this should be 1199px?)
  {
    queryString: '(min-width: 990px)',
    size: 'large',
  },
];

function getScreenSize(): string {
  for (const screenSize of screenSizes) {
    const { matches } = window.matchMedia(screenSize.queryString);
    if (matches) return screenSize.size;
  }
  return '';
}

const ScreenSizeContext = React.createContext({
  screenSize: '',
});

/**
 * Listens for viewport size changes to allow for conditional
 * rendering of layout depending on breakpoint
 *
 * You must have a <ScreenSizeProvider> component somewhere in your
 * tree above the component where you want to use the useScreenSize
 * hook. This is for performance in case you want to use the
 * hook multiple times
 *
 * Usage:
 *
 * Parent:
    <ScreenSizeProvider>
      <ChildComponent/>
    </ScreenSizeProvider>

    const ChildComponent = () =>{
      const { screenSize } = useScreenSize();
    }
 */
const ScreenSizeProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <ScreenSizeContext.Provider value={{ screenSize }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
const useScreenSize = () => React.useContext(ScreenSizeContext);

export { useScreenSize, ScreenSizeProvider };
