import React from 'react';

function LinkIcon({ colour, ...props }) {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 11 10"
			xmlns="http://www.w3.org/2000/svg"
			fill={colour}
			{...props}
		>
			<path d="M11 0.46875V2.96875C11 3.38867 10.5035 3.59375 10.217 3.30078L9.53524 2.60352L4.88507 7.35938C4.70556 7.54297 4.41528 7.54297 4.23767 7.35938L3.80608 6.91797C3.62656 6.73438 3.62656 6.4375 3.80608 6.25586L8.45625 1.49805L7.77448 0.800781C7.48611 0.505859 7.69045 0 8.09722 0H10.5417C10.7957 0 11 0.208984 11 0.46875ZM7.77257 5.28906L7.46701 5.60156C7.38108 5.68945 7.33333 5.80859 7.33333 5.93359V8.75H1.22222V2.5H6.26389C6.38611 2.5 6.5026 2.45117 6.58854 2.36328L6.8941 2.05078C7.18247 1.75586 6.97813 1.25 6.56944 1.25H0.916667C0.41059 1.25 0 1.66992 0 2.1875V9.0625C0 9.58008 0.41059 10 0.916667 10H7.63889C8.14497 10 8.55556 9.58008 8.55556 9.0625V5.62109C8.55556 5.20312 8.06094 4.99414 7.77257 5.28906Z" />
		</svg>
	);
}
export { LinkIcon };
