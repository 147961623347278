import React from 'react';
import ReactDOM from 'react-dom';

import { getDataFromScriptById } from 'components/Common/get-data-from-script-by-id';

import { Homepage } from 'components/Home/Homepage';
import { ScreenSizeProvider } from 'components/Common/use-screen-size';
import { ScrollPercentageProvider } from 'hooks/useScrollPercentageContext';

// window.addEventListener('message', function (e) {
//   if (typeof e.data === 'object' && e.data.type !== 'slider-preview') {
//     return;
//   }
//   console.log(e.data);
//   console.log(e.data.slides);

//   const slideData = e.data.slides;
//   console.log(slideData);
//   globalThis.MPD.renderCarousel([]);
//   globalThis.MPD.renderCarousel(slideData);
// });
globalThis.MPD = globalThis.MPD || {};

const merchPods = getDataFromScriptById('merch-pods');

ReactDOM.render(
	<ScreenSizeProvider>
		<ScrollPercentageProvider containerId="home-page">
			<Homepage merchPods={merchPods} />
		</ScrollPercentageProvider>
	</ScreenSizeProvider>,
	document.getElementById('homepage')
);
