import React from 'react';

function InfoIcon({ colour, ...props }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M7.24717 8.00345C7.09532 8.00345 6.97212 8.00767 6.84931 8.00268C6.62762 7.99308 6.51108 7.88292 6.50481 7.66874C6.49853 7.45801 6.49814 7.2469 6.5052 7.03617C6.51187 6.8335 6.62762 6.71105 6.83754 6.70875C7.50378 6.70146 8.17002 6.69992 8.83627 6.70722C9.05639 6.70952 9.16586 6.83619 9.16586 7.05306C9.16664 8.49745 9.16704 9.94146 9.16429 11.3859C9.1635 11.721 8.92651 11.9678 8.58319 11.99C8.37484 12.0035 8.16532 12.0008 7.95657 11.9965C7.49083 11.9877 7.24874 11.7455 7.24795 11.2895C7.24678 10.267 7.24756 9.24441 7.24756 8.22224C7.24717 8.15852 7.24717 8.09557 7.24717 8.00345Z"
        fill={colour}
      />
      <path
        d="M9.16486 5.02563C9.16682 5.60983 8.71089 6.06699 8.12233 6.07083C7.52004 6.07505 7.04802 5.6202 7.04842 5.03561C7.04881 4.45754 7.51259 4.00154 8.10232 4C8.69519 3.99847 9.16329 4.45025 9.16486 5.02563Z"
        fill={colour}
      />
      <circle cx="8" cy="8" r="7.5" stroke={colour} />
    </svg>
  );
}
export { InfoIcon };
