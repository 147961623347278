import React from 'react';

export function Cross({ ...props }) {
	return (
		<svg
			color="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden="true"
			focusable="false"
			role="img"
			viewBox="0 0 32 32"
			{...props}
		>
			<path
				{...props}
				d="M20.001 16l-0.001-0.001 11.999-11.999-4-4-11.999 11.999-11.999-11.999-4 4 11.999 12-11.999 11.999 4 4 11.999-11.999 11.999 11.999 4-4-11.999-11.999z"
			/>
		</svg>
	);
}
