import React, { CSSProperties, useContext } from 'react';
import { Deal as DealType } from 'components/types';
import { DealContext } from '.';

import './Promo.scss';

function PromoLabel() {
	const { deal }: { deal: DealType } = useContext(DealContext);

	const style: CSSProperties = {};

	let promoTagLine = '';
	const simFreeHandsetSticker =
		deal.type === 'sim-free' &&
		deal['variant']['handset']['sticker']['sim_free']
			? deal['variant']['handset']['sticker']['sim_free']
			: null;

	if (deal.promo) {
		style.color = `#${deal.promo.secondaryHex}`;
		style.backgroundColor = `#${deal.promo.primaryHex}`;
		promoTagLine = deal.promo.tagLine;
	}

	if (deal.type && deal.type === 'sim-free' && simFreeHandsetSticker) {
		style.color = `${simFreeHandsetSticker.text_colour}`;
		style.backgroundColor = `${simFreeHandsetSticker.background_colour}`;
		promoTagLine = simFreeHandsetSticker.text;
	}

	return (
		<>
			<div className="u-fz--base-small u-font-group--display u-fw--600 deal-promo-label u-w--100 u-ta--center">
				<div
					className="u-d--inline-block u-py--micro u-px--small deal-promo-label-inner u-pos--relative"
					style={style}
				>
					{promoTagLine}
				</div>
			</div>
		</>
	);
}

export { PromoLabel };
