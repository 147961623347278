import React from 'react';

import { DealList } from 'components/Common/Deals/DealList';
import { ErrorBoundary } from 'components/Common/ErrorBoundary';
import { HomeTitle } from '../HomeTitle';
import { SimOnlyDealPanel } from './SimOnlyDealPanel';
import { pushDealsToDataLayer } from 'components/Common/analytics';

import './SliderNav.scss';
import { CarouselItems } from 'components/Common/CarouselItems';
import { HttpMethod, useFetch } from 'hooks/use-fetch';
import { Deal } from 'components/types';

const trackSimOnlyViewAll = {
	event: 'Sim Only Slide Click',
	eventCategory: 'HomePage',
	eventAction: 'Sim Only',
	eventLabel: 'View all',
};

export function SimOnlyDeals() {
	const url = new URL(window.location.href);
	url.pathname = `/api/home/simonly`;

	const { response } = useFetch<Deal[]>({
		url: url.toString(),
		method: HttpMethod.GET,
	});

	if (!response) return <></>;
	pushDealsToDataLayer(response, 'Top SIMO Deals', 0, 'tariff');
	return (
		<ErrorBoundary>
			<div className="o-wrapper u-mb--small u-pt--micro u-px--medium--bp-medium u-mb--medium--bp-medium u-pb--tiny--bp-medium">
				<div className="u-px--small u-mb--xsmall">
					<HomeTitle
						title="Our Top SIM Only Deals"
						description="You could still save some money on your network plan with our current offers."
						viewAllLink="/deals/sim-only"
						viewAllProps={{
							className: 'u-c--action u-fz--base-small u-fw--500',
						}}
						trackingObject={trackSimOnlyViewAll}
					/>
				</div>

				<CarouselItems
					itemCount={response.length}
					breakpoint={{ '600': 1, '920': 2, '1200': 3 }}
				>
					<DealList
						panelWrapperClassName="u-px--small"
						deals={response}
						panelContentRenderer={SimOnlyDealPanel}
						list="Home: SIM-Only"
					/>
				</CarouselItems>
			</div>
		</ErrorBoundary>
	);
}
