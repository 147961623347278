import React from 'react';

function ChevronRightIcon({ colour, ...props }) {
  return (
    <svg
      width="18"
      height="30"
      viewBox="0 0 18 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9993 14.0909L3.14464 0.318182C2.96234 0.0909091 2.73447 0 2.46102 0C2.18758 0 1.9597 0.0909091 1.7774 0.318182L0.319021 1.77273C0.0911489 1.95455 0 2.18182 0 2.45455C0 2.72727 0.0911489 2.95455 0.319021 3.13636L11.9861 14.7727L0.319021 26.4091C0.0911489 26.6364 0 26.8636 0 27.0909C0 27.3636 0.0911489 27.5909 0.319021 27.7727L1.82298 29.2727C1.9597 29.4545 2.18758 29.5455 2.46102 29.5455C2.73447 29.5455 2.96234 29.4545 3.14464 29.2727L16.9993 15.4545C17.1816 15.2727 17.3183 15.0455 17.3183 14.7727C17.2727 14.5455 17.1816 14.3182 16.9993 14.0909Z"
        fill={colour}
      />
    </svg>
  );
}
export { ChevronRightIcon };
