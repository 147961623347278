import React from 'react';

import {
  Row,
  Column,
  Panel,
  PanelHeader,
  PanelContent,
} from '@ao-internal/react-components';

function DealsLoadingItem() {
  return (
    <Panel className="u-mb--large">
      <PanelHeader>
        <h3>
          <div
            className="loading-skeleton"
            style={{
              width: '100%',
              height: '24px',
              borderRadius: '10px',
            }}
          />
        </h3>
      </PanelHeader>
      <PanelContent>
        <Row>
          <Column size={3} small={2} medium={1}>
            <div
              className="loading-skeleton"
              style={{
                width: '45px',
                height: '45px',
                borderRadius: '100px',
              }}
            />
          </Column>
          <Column size={9} small={10} medium={11}>
            <div
              className="loading-skeleton"
              style={{
                width: '100%',
                height: '24px',
                borderRadius: '6px',
              }}
            />
            <div
              className="loading-skeleton"
              style={{
                width: '80%',
                height: '24px',
                borderRadius: '6px',
                margin: '10px 0',
              }}
            />
          </Column>
        </Row>
      </PanelContent>
    </Panel>
  );
}

function DealsLoading({ count = 4 }: { count?: number }) {
  const times = Array.from(Array(count));
  return (
    <div className="u-my--small">
      {times.map((x, index) => (
        <DealsLoadingItem key={index} />
      ))}
    </div>
  );
}

export { DealsLoading };
