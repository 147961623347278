import React from 'react';
import { HandsetQuickLink } from '../../Common/QuickLink/HandsetQuickLink';
import { handsetQuickLinks } from 'components/Common/QuickLink/QuickLinks';
import './HandsetQuickLinks.scss';

function HandsetQuickLinks() {
	return (
		<div className="HandsetQuickLinks">
			{handsetQuickLinks.map((quickLink, i) => (
				<HandsetQuickLink key={i} {...quickLink} />
			))}
		</div>
	);
}
export { HandsetQuickLinks };
