/**
 * Common generic date functions/helpers
 */

/**
 * Using getNow() instead of new Date() allows
 * us to mock out the value of "now", giving us
 * more control over time in tests
 */
export const getNow = (): Date => new Date();
