import React from 'react';
import { Deal as DealType } from 'components/types';
import { addDays, format } from 'date-fns';
import { getVariantLaunched } from './selectors';
import { checkIsDealRefurbished } from 'helpers/utils';

function DealMetaData({ deal }: { deal: DealType }) {
	const isRefurb = checkIsDealRefurbished(deal);
	const condition = isRefurb ? 'RefurbishedCondition' : 'NewCondition';

	const handsetLaunched = getVariantLaunched(deal);
	const availability = handsetLaunched ? 'InStock' : 'PreOrder';

	const validUntil = format(addDays(new Date(), 1), 'yyyy-MM-dd');

	return (
		<>
			<meta
				itemProp="availability"
				content={`https://schema.org/${availability}`}
			/>
			<meta itemProp="priceCurrency" content="GBP" />
			<meta itemProp="priceValidUntil" content={validUntil} />
			<meta
				itemProp="itemCondition"
				content={`https://schema.org/${condition}`}
			/>
			<div
				itemProp="seller"
				itemType="http://schema.org/Organization"
				itemScope
				style={{ display: 'none' }}
			>
				<meta itemProp="name" content="Mobile Phones Direct" />
			</div>
			<meta itemProp="price" content={deal.upfront.toString()} />
			<link itemProp="url" href={deal.basketLink} />
		</>
	);
}

export { DealMetaData };
