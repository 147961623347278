import React, { useEffect } from 'react';
import { RegisterInterestBanner } from 'components/Home/RegisterInterestBanner';
import { HomeTitle } from 'components/Home/HomeTitle';
import { ErrorBoundary } from 'components/Common/ErrorBoundary';
import { MerchPods } from 'components/Home/HomeDeals/MerchPods';
import { TopDeals } from 'components/Common/TopDeals/TopDeals';
import { LazyLoad } from 'components/Common/LazyLoad';
import { PhoneBrands } from 'components/Home/PhoneBrands';
import { LatestPhones } from 'components/Home/LatestPhones';
import { SimOnlyDeals } from 'components/Home/SimOnlyDeals';
import { Banner, BannerLocation } from 'components/SelfServe/Banner';
import { useScreenSize } from 'components/Common/use-screen-size';
import { Carousel } from 'components/Common/Carousel';
import { QuickLinks } from 'components/Home/QuickLinks';
import './Homepage.scss';
import { Terms } from './Terms';
import { useScrollPercentageContext } from 'hooks/useScrollPercentageContext';

const trackHomeDealsViewAll = {
	event: 'Home Deals Slide Click',
	eventCategory: 'HomePage',
	eventAction: 'Our Best Phone Deals',
	eventLabel: 'View all',
};

const FIVE_MINUTES_IN_MS = 5000;

export function Homepage({ merchPods }): JSX.Element {
	const { onScrollStop } = useScrollPercentageContext();
	const { screenSize } = useScreenSize();
	const isMobile =
		screenSize === 'xsmall' ||
		screenSize === 'small' ||
		screenSize === 'medium';

	useEffect(() => {
		document.getElementById('home-carousel').style.display = 'none';
		onScrollStop(percentageScrolled => {
			globalThis.dataLayer.push({
				event: 'Homepage scroll',
				eventCategory: 'HomePage',
				eventAction: 'Scrolled on home page',
				percentageScrolled,
			});
		});
	}, [onScrollStop]);

	const trackBannerClick = (): void => {
		globalThis.dataLayer.push({
			event: 'merchClick',
			eventCategory: 'Merchandise strip banners',
			eventAction: 'Bottom strip banner - Bundles',
		});
	};

	return (
		<ErrorBoundary>
			<Carousel
				slides={JSON.parse(
					document.getElementById('merch-slides')?.innerHTML
				)}
				duration={FIVE_MINUTES_IN_MS}
			/>
			<div>
				<QuickLinks />
			</div>
			<div>
				<Terms />
			</div>
			<div>
				<MerchPods merchPods={merchPods} smallMerchPods={isMobile} />
			</div>

			<div className="o-wrapper u-px--medium--bp-medium">
				<div className="sg-inner u-pt--none u-pt--tiny--bp-medium RegisterInterestBannerContainer">
					<div className="o-flex-container u-jc--center u-my--small u-mx--none">
						<RegisterInterestBanner />
					</div>
				</div>
			</div>

			<div
				id="home-top-picks"
				className="o-wrapper u-mb--small u-pt--micro u-px--medium--bp-medium u-mb--large--bp-medium u-pb--tiny--bp-medium"
			>
				<div className="u-px--small">
					<HomeTitle
						className="u-pt--medium--bp-medium u-px--small u-px--none--bp-medium"
						title="Our Best Phone Deals"
						viewAllLink="/deals/new-contract"
						viewAllProps={{
							className: 'u-c--action u-fz--base-small u-fw--500',
						}}
						trackingObject={trackHomeDealsViewAll}
					/>
				</div>

				<LazyLoad>
					<TopDeals />
				</LazyLoad>
			</div>

			<div id="home-phone-brands">
				<div className="o-wrapper u-px--micro u-px--medium--bp-medium u-mb--micro">
					<LazyLoad>
						<PhoneBrands />
					</LazyLoad>
				</div>
			</div>

			<div id="home-latest-phones">
				<div className="o-wrapper u-px--micro u-px--medium--bp-medium u-mb--micro">
					<LazyLoad>
						<LatestPhones />
					</LazyLoad>
				</div>
			</div>

			<div id="home-sim-only-deals">
				<div className="o-wrapper u-mb--small u-pt--micro u-px--medium--bp-medium u-mb--medium--bp-medium u-pb--tiny--bp-medium">
					<LazyLoad>
						<SimOnlyDeals />
					</LazyLoad>
				</div>
			</div>

			<div id="self-serve-banner">
				<div className="sg-inner u-p--tiny o-wrapper">
					<div
						className="o-flex-container u-jc--center u-mb--small"
						style={{ padding: '0 62px' }}
					>
						<LazyLoad>
							<a
								href="/deals/new-contract/refurbished"
								onClick={trackBannerClick}
							>
								<Banner location={BannerLocation.Homepage} />
							</a>
						</LazyLoad>
					</div>
				</div>
			</div>
		</ErrorBoundary>
	);
}
