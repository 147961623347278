import React from 'react';
import classNames from 'classnames';

import './DealBadge.scss';

function DealBadge({ children, className = '', ...props }) {
  return (
    <span
      className={classNames('u-c--inverted u-fw--600 deal-badge', className)}
      {...props}
    >
      {children}
    </span>
  );
}

export { DealBadge };
