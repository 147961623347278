import React from 'react';

interface IconProps {
	colour?: string;
}

export const InfoCircle = ({ colour }: IconProps) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.2909 19.4738C5.25694 22.0508 -2.05006 14.7438 0.525936 6.70879C1.45994 3.79579 3.79494 1.46079 6.70894 0.526786C14.7429 -2.05021 22.0499 5.25679 19.4739 13.2908C18.5399 16.2038 16.2049 18.5388 13.2909 19.4738M8.99294 8.20079V15.7998C8.99294 15.9108 9.08294 15.9998 9.19294 15.9998H10.7929C10.9029 15.9998 10.9929 15.9108 10.9929 15.7998V8.20079C10.9929 8.08979 10.9029 7.99979 10.7929 7.99979H9.19294C9.08294 7.99979 8.99294 8.08979 8.99294 8.20079M8.79494 4.74779C8.46194 5.76079 9.04994 6.58279 9.99994 6.58279C10.7819 6.58279 11.3129 6.04579 11.3129 5.30679C11.3129 4.41079 10.5639 3.80079 9.53494 4.06079C9.19294 4.14779 8.90594 4.41179 8.79494 4.74779"
			fill={colour || '#FF9E36'}
		/>
	</svg>
);
