import React from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from 'components/Common/ErrorBoundary';
import { PhoneSlide } from './PhoneSlide';
import { HomeTitle } from '../HomeTitle';

import './LatestPhones.scss';
import { Handset } from 'components/types';
import { HttpMethod, useFetch } from 'hooks/use-fetch';
import { CarouselItems } from 'components/Common/CarouselItems';
import { Terms } from '../Terms';

function LatestPhones() {
	const url = new URL(window.location.href);
	url.pathname = `/api/home/handsets`;

	const { response } = useFetch<Handset[]>({
		url: url.toString(),
		method: HttpMethod.GET,
	});

	if (!response) return <></>;
	return (
		<ErrorBoundary>
			<div className="u-pb--tiny u-pb--large--bp-medium">
				<div className="o-wrapper">
					<div className="u-p--small u-pb--xsmall">
						<HomeTitle
							title="The Latest Phones"
							description="Stay up to date with all the upcoming tech with our deals on the latest phones."
						/>
					</div>

					<CarouselItems
						itemCount={response.length}
						breakpoint={{
							'550': 2,
							'670': 3,
							'890': 4,
							'1140': 5,
							'1200': 6,
						}}
					>
						{response.map((handset, index) => (
							<div key={index}>
								<PhoneSlide key={index} handset={handset} />
							</div>
						))}
					</CarouselItems>
				</div>
				<Terms />
			</div>
		</ErrorBoundary>
	);
}

export { LatestPhones };
