import React from 'react';

function UnlockedIcon({ ...props }) {
  return (
    <svg
      width="30"
      height="59"
      viewBox="0 0 30 59"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.4643 7.44141H2.74805V50.366H26.4643V7.44141Z"
        className="primary-colour"
      />
      <path
        d="M0.0029513 29.0585C0.0029513 21.1086 -0.000368913 13.1619 0.0029513 5.2119C0.00627152 1.63291 1.57673 0.0190548 5.10944 0.012427C11.4145 -0.00414234 17.7196 -0.00414234 24.0247 0.012427C27.6902 0.0190548 29.2906 1.59315 29.2939 5.24835C29.3072 21.0356 29.3072 36.8229 29.2939 52.6069C29.2906 56.5537 27.7168 58.0682 23.7325 58.0682C17.4839 58.0682 11.2352 58.0748 4.98328 58.0649C1.66306 58.0616 0.00627152 56.398 0.0029513 53.0642C-0.00368913 45.0645 0.0029513 37.0615 0.0029513 29.0585ZM0.90605 28.9657C0.90605 37.0218 0.892769 45.0811 0.912691 53.1371C0.919331 55.9341 2.17437 57.1403 4.97996 57.1436C11.2817 57.1502 17.5868 57.1469 23.8886 57.1469C27.159 57.1469 28.2779 56.0633 28.2812 52.8157C28.2879 36.9224 28.2879 21.029 28.2812 5.13568C28.2779 2.04052 27.0428 0.817698 23.9782 0.814385C17.786 0.807757 11.5938 0.807757 5.40162 0.814385C2.10133 0.817698 0.912691 1.99412 0.90605 5.29143C0.89941 13.1851 0.90605 21.0754 0.90605 28.9657Z"
        className="secondary-colour"
      />
      <path
        d="M1.82269 28.7729C1.82269 21.9264 1.86254 15.0766 1.78949 8.23016C1.77289 6.75217 2.2344 6.37771 3.6787 6.39096C11.0363 6.46055 18.3972 6.45061 25.7548 6.39759C27.0397 6.38765 27.4946 6.72898 27.4913 8.06778C27.4514 21.9827 27.4481 35.901 27.4946 49.816C27.4979 51.178 27.0198 51.4762 25.7581 51.4696C18.3507 51.4199 10.94 51.4066 3.53261 51.4795C2.07171 51.4928 1.78949 50.9427 1.79945 49.6503C1.84594 42.6878 1.82269 35.7287 1.82269 28.7729ZM26.4653 7.44146C18.4636 7.44146 10.6379 7.44146 2.74903 7.44146C2.74903 21.8071 2.74903 36.07 2.74903 50.3661C10.7043 50.3661 18.5699 50.3661 26.4653 50.3661C26.4653 36.0203 26.4653 21.7972 26.4653 7.44146Z"
        className="secondary-colour"
      />
      <path
        d="M14.5551 52.041C15.7537 52.0377 16.6801 53.0286 16.6834 54.3044C16.6834 55.6266 15.6741 56.6274 14.3825 56.581C13.1806 56.5413 12.1978 55.5206 12.2111 54.3243C12.2244 53.0683 13.2769 52.041 14.5551 52.041Z"
        className="secondary-colour"
      />
      <path
        d="M16.5442 4.00485C15.0866 4.00485 13.6324 4.00485 12.1748 4.00485C12.1682 3.86566 12.1615 3.72317 12.1582 3.58398C13.6158 3.58398 15.0734 3.58398 16.5309 3.58398C16.5342 3.72317 16.5376 3.86235 16.5442 4.00485Z"
        className="secondary-colour"
      />
      <path
        d="M14.6539 28.4925C16.7636 28.4925 18.8742 28.4917 20.9838 28.4934C21.7343 28.4942 22.0479 28.8053 22.047 29.524C22.0437 31.9134 22.0437 34.3028 22.0412 36.693C22.0395 37.8436 21.6034 38.3597 20.4468 38.542C17.9828 38.9301 15.4987 39.0354 13.0112 38.9058C11.5453 38.8296 10.0802 38.6716 8.62591 38.4723C7.68614 38.3435 7.29172 37.8322 7.28588 36.9004C7.27254 34.8627 7.28671 32.8241 7.28838 30.7855C7.28838 30.3942 7.28755 30.0036 7.27671 29.6123C7.25336 28.7786 7.53688 28.4925 8.4041 28.4925C10.4871 28.4917 12.5709 28.4925 14.6539 28.4925ZM13.9085 34.3595C13.9085 34.494 13.9076 34.6293 13.9085 34.7638C13.9093 34.9121 13.9018 35.0612 13.9143 35.2086C13.9493 35.6454 14.2479 35.9443 14.6423 35.9484C15.0575 35.9524 15.3736 35.6356 15.3827 35.1884C15.3919 34.7298 15.3994 34.2712 15.3811 33.8134C15.3694 33.5258 15.4436 33.3232 15.6821 33.1207C16.3075 32.5899 16.3234 31.6436 15.758 31.0553C15.1918 30.4663 14.2604 30.4396 13.6291 30.9954C13.0087 31.5407 12.9337 32.4935 13.5507 33.0469C13.8743 33.337 13.9377 33.6262 13.9118 33.9965C13.9018 34.1164 13.9093 34.238 13.9085 34.3595Z"
        className="secondary-colour"
      />
      <path
        d="M11.2341 28.0285C11.1174 28.0463 11.0365 28.0682 10.9564 28.0682C10.3894 28.0715 9.82238 28.0698 9.19615 28.0698C9.19615 27.8908 9.19615 27.7482 9.19615 27.6056C9.19615 26.5798 9.19615 25.554 9.19531 24.5283C9.19448 21.5944 11.1816 19.4238 14.1827 19.0794C17.454 18.7043 20.4709 21.6325 20.0881 24.8435C19.4336 24.8435 18.774 24.8435 18.0869 24.8435C18.0593 24.4845 18.0718 24.1191 17.9993 23.7699C17.6382 22.0271 16.1164 20.9178 14.3144 21.0621C12.6125 21.1982 11.2792 22.6226 11.2391 24.3808C11.2149 25.4463 11.2333 26.5134 11.2333 27.5788C11.2341 27.7255 11.2341 27.8721 11.2341 28.0285Z"
        className="secondary-colour"
      />
    </svg>
  );
}
export { UnlockedIcon };
