import React from 'react';
import { RetinaImage } from 'components/Common/Image';
import './SamsungBanner.scss';
import { useScreenSize } from 'components/Common/use-screen-size';

const SamsungBannerMobile = ({
	onClick,
}: {
	onClick: () => void;
}): JSX.Element => {
	return (
		<a
			href="/register-your-interest/samsung"
			onClick={onClick}
			className="SamsungBanner"
		>
			<div className="SamsungBanner--ImageContainer_mobile">
				<RetinaImage
					alt="Samsung logo"
					height={10}
					path="/images/banner/samsung-23/samsung-logo-mobile.png"
				/>
			</div>
			<div className="SamsungBanner--TextContent_mobile">
				<div className="SamsungBanner--CtaText_mobile">
					<p>Are you prepared?</p>
					<h2>Something new is coming!</h2>
					<p className="SamsungBanner--CtaText_mobile_signUpText">
						Sign up for a chance to win big!
					</p>
				</div>
				<div className="SamsungBanner--Badge">Win</div>
			</div>
		</a>
	);
};

export const SamsungBanner = ({
	onClick,
}: {
	onClick: () => void;
}): JSX.Element => {
	const { screenSize } = useScreenSize();
	const isMobile =
		screenSize === 'xsmall' ||
		screenSize === 'small' ||
		screenSize === 'medium';

	if (isMobile) {
		return <SamsungBannerMobile onClick={onClick} />;
	}

	return (
		<a
			href="/register-your-interest/samsung"
			onClick={onClick}
			className="SamsungBanner"
		>
			<div className="SamsungBanner--ImageContainer_desktop">
				<RetinaImage
					alt="Samsung RegisterInterestBanner showcase image"
					height={100}
					path="/images/banner/samsung-23/galaxy-something-new-desktop.png"
				/>
			</div>
			<div className="SamsungBanner--TextContent_desktop">
				<div className="SamsungBanner--CtaText_desktop">
					<p>Are you prepared?</p>
					<h2>Something new is coming!</h2>
					<p className="SamsungBanner--CtaText_desktop_signUpText">
						Sign up for a chance to win big!
					</p>
				</div>
				<div className="SamsungBanner--Badge">Win Prizes</div>
			</div>
		</a>
	);
};
