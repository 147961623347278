import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
	Directions,
	ReducerProps,
	CarouselProps,
} from 'components/Common/Carousel/Carousel.props';
import { carouselReducer } from 'components/Common/Carousel/Carousel.reducer';
import { Slide } from 'components/Common/Carousel/Slide';
import { Controls } from 'components/Common/Carousel/Controls';
import { PropositionBarModal } from '../Header/PropositionBar/PropositionBarModal';
import { ScreenSizeProvider } from '../use-screen-size';

export const CarouselContext = React.createContext(null);

export const position = (size: number, current: number): string =>
	`translate3d(-${(current / size) * 100}%,0,0)`;

const initialState: ReducerProps = {
	current: 1,
	active: [1],
	slides: null,
	slideTheme: [],
	slideCount: 0,
	theme: 'default',
	paused: false,
	locked: false,
};

export function Carousel({
	slides,
	duration,
	...props
}: CarouselProps): JSX.Element {
	const elementSlides = useRef<HTMLDivElement>(null);
	const [modalOpen, setModalOpen] = useState(false);

	// We need to add the last slide to the first and the first slide to the last to simulate continuous scrolling.
	slides = [slides[slides.length - 1], ...slides, slides[0]];

	initialState.slides = elementSlides;
	initialState.slideCount = slides.length;
	initialState.slideTheme = slides.map(slide => slide.theme);

	const [carouselState, carouselDispatch] = useReducer(
		carouselReducer,
		initialState
	);

	// The slide container needs to be wide enough to fit all banners so we can scroll between them.
	const styles = {
		width: `calc(100vw * ${carouselState.slideCount})`,
		transform: position(carouselState.slideCount, 1),
	};

	useEffect(() => {
		const slideTimer = setInterval(() => {
			if (carouselState.paused || carouselState.locked) return;

			carouselDispatch({ type: Directions.Next, nonUser: true });
		}, duration);

		return () => {
			clearTimeout(slideTimer);
		};
	}, [carouselState.paused, carouselState.locked, duration]);

	return (
		<>
			<ScreenSizeProvider>
				<CarouselContext.Provider
					value={{
						carouselState,
						carouselDispatch,
						modalOpen,
						setModalOpen,
					}}
				>
					<div
						className={`mpd-carousel theme_${carouselState.theme}`}
						{...props}
						onMouseEnter={() => carouselDispatch({ pause: true })}
						onMouseLeave={() => carouselDispatch({ pause: false })}
					>
						<div
							className="carousel-slides"
							ref={elementSlides}
							style={styles}
						>
							{slides.map((slide, index) => (
								<Slide key={index} index={index} {...slide} />
							))}
						</div>
						<Controls />
					</div>
				</CarouselContext.Provider>
				<PropositionBarModal
					modalOpen={modalOpen}
					setModalOpen={setModalOpen}
				/>
			</ScreenSizeProvider>
		</>
	);
}
