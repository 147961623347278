import React, { useContext } from 'react';
import { Directions } from 'components/Common/Carousel/Carousel.props';
import { CarouselContext } from 'components/Common/Carousel/Carousel';

export function Controls(): JSX.Element {
	const { carouselState, carouselDispatch } = useContext(CarouselContext);

	const { slideCount } = carouselState;

	return (
		<div className="controls">
			<div
				className="left-arrow"
				aria-hidden="true"
				onClick={() => carouselDispatch({ type: Directions.Previous })}
			></div>
			<div
				className="right-arrow"
				aria-hidden="true"
				onClick={() => carouselDispatch({ type: Directions.Next })}
			></div>
			<ul className="dots">
				{[...Array(slideCount)].map((i, index) => {
					const isFake = index < 1 || index > slideCount - 2;
					const classes = [
						'dot',
						carouselState.active.includes(index)
							? 'active'
							: 'inactive',
						isFake ? 'fake' : '',
					];
					return (
						<li
							aria-hidden="true"
							className={classes.join(' ')}
							key={index}
							onClick={() => carouselDispatch({ to: index })}
						></li>
					);
				})}
			</ul>
		</div>
	);
}
