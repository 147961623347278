import { Column } from '@ao-internal/react-components';
import Context from 'components/Handset/Context';
import { Deal, Handset, Variant } from 'components/types';
import React, { useContext } from 'react';
import { GetClosestDeliveryForDealVariantService } from 'services/GetClosestDeliveryForDealVariantService';
import { LinkIcon } from '../../Icons/LinkIcon';
import { useStockContext } from '../useStockContext';

export function DelayedDelivery() {
	const { setModalOpen } = useStockContext();

	const {
		handset,
		topDeal,
		variant,
		setTopDeal,
		setVariant,
		setAlert,
	}: {
		handset: Handset;
		topDeal: Deal;
		variant: Variant;
		setTopDeal: (deal: Deal) => void;
		setVariant: (variant: Variant) => void;
		setAlert: (alert: JSX.Element) => void;
	} = useContext(Context);

	const findBestVariantAndColourCombo = () => {
		const generator = new GetClosestDeliveryForDealVariantService(
			handset,
			variant,
			topDeal
		);

		const closestDeliveryForHandset = generator.getClosestDeliveryForHandset();

		if (closestDeliveryForHandset) {
			const {
				deal,
				variant: bestVariant,
				changes,
			} = closestDeliveryForHandset;
			const eventLabel = [];

			if (changes.Network) {
				eventLabel.push(
					`${changes.Network.before.name} ${changes.Network.after.name}`
				);
			}

			if (changes.Capacity) {
				eventLabel.push(
					`${changes.Capacity.before.name} ${changes.Capacity.after.name}`
				);
			}

			if (changes.Colour) {
				eventLabel.push(
					`${changes.Colour.before.name} ${changes.Colour.after.name}`
				);
			}

			if (changes.Price && changes.Price.difference !== 0) {
				eventLabel.push(
					`${changes.Price.before} ${changes.Price.after}`
				);
			}

			const ga = {
				event: 'Find Best Variant And Colour',
				eventCategory: `Product Page (${handset.name})`,
				eventAction: 'Find Best Variant And Colour',
				eventLabel: eventLabel.join('|'),
			};

			globalThis.dataLayer.push(ga);

			setAlert(buildChangesMessage(changes));
			setTopDeal(deal);
			setVariant(bestVariant);
		} else {
			setAlert(
				<>
					<p>
						We currently have no next day delivery deals available
						for this device.{' '}
						<a href={`/brands/${handset.manufacturer.slug}`}>
							Please try one of our other models here
						</a>
					</p>
				</>
			);
		}

		setModalOpen(false);
		const alertArea = document.getElementById('alert');

		if (alertArea) {
			alertArea.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<Column
			className="u-mb--xsmall u-pt--none u-pt--xsmall--bp-medium"
			size={12}
			medium={6}
		>
			<div
				className="u-d--flex u-p--small u-p--medium--bp-medium u-ai--flex-start u-fxd--row"
				style={{
					backgroundColor: '#EDF2FF',
					borderRadius: '8px',
				}}
			>
				<div
					className="u-mr--small"
					style={{ transform: 'scale(1.2)' }}
				>
					<i
						style={{ color: '#0564c2' }}
						className="icon-information"
					></i>
				</div>
				<div>
					<p
						style={{ marginTop: '-3px' }}
						className="u-fw--700 u-fz--title"
					>
						Want this phone sooner?
					</p>
					<div style={{ fontSize: '14px' }}>
						If you don&apos;t want to wait that long, try checking
						if we have it available for next day delivery in a
						different storage size or colour variant.
					</div>
					<br />
					<button
						className="next-day-delivery-link u-font-group--link"
						onClick={findBestVariantAndColourCombo}
					>
						Show me next day delivery deals{' '}
						<LinkIcon colour="#0074cc" height="12" width="12" />
					</button>
				</div>
			</div>
		</Column>
	);
}

function buildChangesMessage(dealChanges) {
	const hasPriceChanged = dealChanges.Price || false;
	const hasCapacityChanged = dealChanges.Capacity || false;
	const hasColourChanged = dealChanges.Colour || false;
	const hasNetworkChanged = dealChanges.Network || false;

	return (
		<>
			<p>
				<a href="#!">Available next day delivery deal:</a> We&apos;ve
				recommended a close matching deal{' '}
				{hasNetworkChanged && (
					<span>
						on <strong>{dealChanges.Network.after.name}</strong>{' '}
					</span>
				)}
				{hasCapacityChanged && (
					<span>
						with <strong>{dealChanges.Capacity.after.name}</strong>{' '}
					</span>
				)}
				{hasColourChanged && (
					<span>
						in <strong>{dealChanges.Colour.after.name}</strong>{' '}
					</span>
				)}
				{hasPriceChanged &&
					(dealChanges.Price.difference < 0 ||
						dealChanges.Price.difference > 0) && (
						<span>
							for <strong>&pound;</strong>
							{dealChanges.Price.difference > 0 ? (
								<strong>
									{dealChanges.Price.difference} extra
								</strong>
							) : (
								<strong>
									{Math.abs(dealChanges.Price.difference)}{' '}
									less
								</strong>
							)}{' '}
							a month{' '}
						</span>
					)}
			</p>
		</>
	);
}
