import React from 'react';
import { useStockContext } from './useStockContext';

export function Delivery({ prefix, suffix }: DeliveryProps): JSX.Element {
	const { deliveryMessage } = useStockContext();
	return (
		<span className="StockMessage--Delivery">
			<span className="StockMessage--Delivery--Prefix">{prefix}</span>
			<span className="StockMessage--Delivery--Message">
				{deliveryMessage}
			</span>
			<span className="StockMessage--Delivery--Suffix">{suffix}</span>
		</span>
	);
}

interface DeliveryProps {
	prefix?: string;
	suffix?: string;
}
