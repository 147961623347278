import React, {
	Dispatch,
	FormEvent,
	KeyboardEvent,
	SetStateAction,
	useRef,
	useState,
} from 'react';
import './PropositionBarModal.scss';
import { CentredModal } from 'components/Common/CentredModal/CentredModal';
import { Fetch } from 'helpers/Fetch';
import { KeyCode } from 'enums/KeyCode.enum';
import { OpeningTimes } from 'components/Common/OpeningTimes/OpeningTimes';

type CallBackResp =
	| {
			success: boolean;
			message: never;
			errors: never;
	  }
	| {
			success: never;
			message: string;
			errors: Record<string, string[]>;
	  };

interface PropositionBarModalProps {
	modalOpen: boolean;
	setModalOpen: Dispatch<SetStateAction<boolean>>;
}

export function PropositionBarModal({
	modalOpen,
	setModalOpen,
}: PropositionBarModalProps): JSX.Element {
	const formRef = useRef<HTMLFormElement>(null);
	const [completed, setCompleted] = useState(false);
	const [errors, setErrors] = useState<Record<string, string[]>>({});

	function handleSubmit(e: FormEvent): void {
		e.preventDefault();
		Fetch<CallBackResp>('/api/call-back', {
			method: 'POST',
			body: new FormData(formRef.current),
		})
			.then(response => {
				if (response.data.success) {
					globalThis.dataLayer.push({
						event: 'OOH - Form - Submit - Success',
						eventCategory: 'OOH',
						eventAction: 'OOH - Submit - Success',
						eventLabel:
							'User has input valid data and we are showing a thanks message.',
					});
					setCompleted(true);
				}
			})
			.catch(response => {
				globalThis.dataLayer.push({
					event: 'OOH - Form - Submit - Failed',
					eventCategory: 'OOH',
					eventAction: 'OOH - Submit - Failed',
					eventLabel:
						'User input invalid data so we are showing them an error.',
				});
				setErrors(response.data.errors);
			});
	}

	function numbersOnly(e: KeyboardEvent<HTMLInputElement>): void {
		if (/^[0-9]$/.test(e.key)) {
			return;
		}

		// Allow modifier keys
		const allowedKeys = Object.values(KeyCode);
		if (!allowedKeys.includes(e.key as KeyCode)) {
			e.preventDefault();
		}
	}

	if (!modalOpen) return <></>;

	return (
		<CentredModal
			isOpen={modalOpen}
			setIsOpen={setModalOpen}
			dependencies={[completed, errors]}
			hasPadding={!completed}
		>
			<div
				className={`PropositionBarModal ${
					completed ? 'PropositionBarModal--Thanks' : ''
				}`}
			>
				{(() => {
					if (completed)
						return <ThanksContent setModalOpen={setModalOpen} />;

					return (
						<FormContent
							setModalOpen={setModalOpen}
							errors={errors}
							formRef={formRef}
							handleSubmit={handleSubmit}
							numbersOnly={numbersOnly}
						/>
					);
				})()}
			</div>
		</CentredModal>
	);
}

function ThanksContent({
	setModalOpen,
}: {
	setModalOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
	return (
		<>
			<button
				className="PropositionBarModal--Close"
				onClick={() => setModalOpen(false)}
				aria-label="Close"
			>
				<i className="ico ico-close ico-2x"></i>
			</button>
			<img
				src="https://media.mobilephonesdirect.co.uk/images/header/zzz_left.svg"
				alt=""
				tabIndex={-1}
				aria-hidden={true}
			/>
			<h1>
				<div>Thanks!</div>
				Keep an ear out for our call.
			</h1>
			<p>
				In the meantime, why not take a look at{' '}
				<a href="/deals/new-contract?sort_by=popular">
					our bestselling deals
				</a>{' '}
				to see if you can find what you&apos;re looking for.
			</p>
			<a
				href="/deals/new-contract?sort_by=popular"
				className="c-btn c-btn--mpd-grey"
			>
				View bestsellers
			</a>
			<div className="PropositionBarModal--Thanks--Opening">
				Our opening times are:
				<br />
				<OpeningTimes>
					{({ open, close, day, toDay }, index) => (
						<div key={index}>
							<span>
								{open} {close && `- ${close}`}
							</span>{' '}
							<span>
								({day} {toDay && `- ${toDay}`})
							</span>
						</div>
					)}
				</OpeningTimes>
			</div>
		</>
	);
}

function FormContent({
	setModalOpen,
	errors,
	formRef,
	handleSubmit,
	numbersOnly,
}): JSX.Element {
	return (
		<>
			<h1 id="dialog_label">
				<span>Sorry!</span> Our agents aren&apos;t available right now.
			</h1>
			<button
				className="PropositionBarModal--Close"
				onClick={() => setModalOpen(false)}
				aria-label="Close"
			>
				<i className="ico ico-close ico-2x"></i>
			</button>
			<p>
				Fill in your details below and we&apos;ll call you back as soon
				as we can.
			</p>
			<form
				ref={formRef}
				onSubmit={handleSubmit}
				className="PropositionBarModal--Form"
			>
				<div className="PropositionBarModal--Form--Field">
					<label htmlFor="first_name">
						First Name
						<span className="asterisk" aria-hidden={true}>
							*
						</span>
					</label>
					<input
						id="first_name"
						name="first_name"
						aria-required={true}
						type="text"
						placeholder="eg: Johnathan"
					/>
					{errors.first_name && (
						<div className="PropositionBarModal--Form--Error">
							{errors.first_name.map((value, key) => (
								<div key={key}>{value}</div>
							))}
						</div>
					)}
				</div>
				<div className="PropositionBarModal--Form--Field">
					<label htmlFor="last_name">
						Last Name
						<span className="asterisk" aria-hidden={true}>
							*
						</span>
					</label>
					<input
						id="last_name"
						name="last_name"
						aria-required={true}
						type="text"
						placeholder="eg: Smith"
					/>
					{errors.last_name && (
						<div className="PropositionBarModal--Form--Error">
							{errors.last_name.map((value, key) => (
								<div key={key}>{value}</div>
							))}
						</div>
					)}
				</div>
				<div className="PropositionBarModal--Form--Field">
					<label htmlFor="phone_number">
						Phone Number
						<span className="asterisk" aria-hidden={true}>
							*
						</span>
					</label>
					<input
						id="phone_number"
						name="phone_number"
						aria-required={true}
						onKeyDown={numbersOnly}
						type="text"
						placeholder="eg: 01234567890"
					/>
					{errors.phone_number && (
						<div className="PropositionBarModal--Form--Error">
							{errors.phone_number.map((value, key) => (
								<div key={key}>{value}</div>
							))}
						</div>
					)}
				</div>
				<div className="PropositionBarModal--Form--Field">
					<label htmlFor="email_address">
						Email Address
						<span className="asterisk" aria-hidden={true}>
							*
						</span>
					</label>
					<input
						id="email_address"
						name="email_address"
						aria-required={true}
						type="text"
						placeholder="eg: john.smith@gmail.com"
					/>
					{errors.email_address && (
						<div className="PropositionBarModal--Form--Error">
							{errors.email_address.map((value, key) => (
								<div key={key}>{value}</div>
							))}
						</div>
					)}
				</div>
				<div className="PropositionBarModal--Form--Textarea">
					<label htmlFor="comment">Comments</label>
					<textarea
						id="comment"
						name="comment"
						maxLength={255}
						placeholder="eg: I am interested in a Samsung Galaxy S22 on a pay monthly contract with Vodafone."
					></textarea>
					{errors.comment && (
						<div className="PropositionBarModal--Form--Error">
							{errors.comment.map((value, key) => (
								<div key={key}>{value}</div>
							))}
						</div>
					)}
				</div>
				<div className="PropositionBarModal--Form--Checkbox">
					<input
						id="marketing_agreement"
						name="marketing_agreement"
						type="checkbox"
					/>
					<label htmlFor="marketing_agreement">
						I agree to Mobile Phones Direct sending me marketing
						messages to my email address
					</label>
					{errors.marketing_agreement && (
						<div className="PropositionBarModal--Form--Error">
							{errors.marketing_agreement.map((value, key) => (
								<div key={key}>{value}</div>
							))}
						</div>
					)}
				</div>
				<div className="PropositionBarModal--Form--Button">
					<button
						className="c-btn c-btn--mpd-grey"
						aria-label="Submit, Request a call back"
					>
						Request a call back
					</button>
				</div>
			</form>
		</>
	);
}
