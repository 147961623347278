import React, { Fragment } from 'react';

import { Row, Column } from '@ao-internal/react-components';
import { DealPropType } from '../';
import { Deal as DealType } from 'components/types';
import { Image } from 'components/Common/Image/';
import classnames from 'classnames';

function TariffExtrasModalContent({ deal }: { deal: DealType }) {
	return (
		<div className="u-p--xsmall u-px--large--bp-medium u-py--medium--bp-medium">
			{deal.tariff.extras.map(({ title, description, slug }, index) => {
				return (
					<Fragment key={index}>
						<Row
							className={classnames({
								'u-mb--tiny':
									deal.tariff.extras.length - 1 !== index,
							})}
						>
							<Column size={3} small={2} className="u-pr--large">
								<Image
									alt={title}
									path={`/images/tariff/extras/${slug}.png`}
									style={{ maxHeight: '80px' }}
									aria-hidden={true}
								/>
							</Column>
							<Column size={9} small={10} className="u-px--none">
								<h5 className="u-pt--none u-fw--600">
									{title}
								</h5>

								<p className="u-fz--title-small">
									{description}
								</p>
							</Column>
						</Row>
					</Fragment>
				);
			})}
		</div>
	);
}
TariffExtrasModalContent.propTypes = {
	deal: DealPropType,
};
export { TariffExtrasModalContent };
