import React, { useContext } from 'react';
import classnames from 'classnames';

import {
	Deal as DealType,
	Colour as ColourType,
	HandsetImageItem,
} from 'components/types';
import { Image } from 'components/Common/Image/';
import { DealContext } from '.';

import './DealVariant.scss';
import { trackProductClick } from 'components/Common/analytics';
import { getHandsetLink } from 'components/Common/Deals/helpers';

/**
 * Displays deal product image and colour selector
 *
 * Handles current colour state for deal panels with
 * product images/variants
 */
function DealVariant(extraProps = {}) {
	const {
		deal,
		// @ts-expect-error TODO
		position,
		// @ts-expect-error TODO
		list,
		selectedColour,
		setSelectedColour,
	}: {
		deal: DealType;
		setSelectedColour: (ColourType) => void;
		selectedColour: ColourType;
	} = useContext(DealContext);

	const { variant } = deal;

	function selectColour(colour: ColourType) {
		setSelectedColour(colour);
	}

	const variantImages =
		variant.handset.images[selectedColour.slug] ||
		variant.handset.images[variant.handset.defaultColour.slug];
	const selectedImage: HandsetImageItem =
		variantImages.all[variantImages.featured];

	//  const link = getHandsetDealLink(deal, selectedColour, null, extraProps);
	const link = getHandsetLink(deal, globalThis.Router, {
		...extraProps,
		colour: selectedColour.slug,
	});

	const onClick = event => {
		const dealTrack: DealType = {
			...deal,
		};
		dealTrack.variant.colour = selectedColour;
		trackProductClick(dealTrack, position, list, link);
	};

	const featuredImageIsLayered =
		variantImages.featured !== 'combined' &&
		typeof variantImages.all.front !== 'undefined' &&
		typeof variantImages.all.back !== 'undefined';

	let availableDealColours = variant.handset.colours;

	// @ts-expect-error TODO
	if (extraProps.colours === 'deal') {
		availableDealColours = variant.handset.colours.filter(
			(colour: ColourType) => {
				if (variant.handset.deals[deal.tariff.type.slug]) {
					return (
						colour.slug in
						variant.handset.deals[deal.tariff.type.slug]
					);
				} else {
					return (
						colour.slug in
						variant.handset.deals['free-from'][
							deal.tariff.type.slug
						]
					);
				}
			}
		);
	}

	return (
		<>
			<div
				className="deal-handset-image u-mx--auto"
				style={{ maxWidth: '110px' }}
			>
				<a
					href={link}
					onClick={onClick}
					className="u-d--block u-ta--center"
				>
					{featuredImageIsLayered ? (
						<div className="u-mb--tiny">
							{/* Render front and back layered */}
							<div
								className="u-pos--relative"
								style={{
									width: '100%',
									height: '162px',
								}}
							>
								<Image
									path={variantImages.all.back.image}
									cdnOptions={{
										height: 324,
										fit: 'contain',
									}}
									alt={
										variantImages.all.back.alt_text ||
										variant.displayName + ' back'
									}
									className="u-pos--absolute"
									style={{
										left: 0,
									}}
								/>
								<Image
									path={variantImages.all.front.image}
									cdnOptions={{
										height: 324,
										fit: 'contain',
									}}
									alt={
										variantImages.all.front.alt_text ||
										variant.displayName
									}
									className="u-pos--absolute"
									style={{
										right: 0,
									}}
								/>
							</div>
						</div>
					) : (
						<div
							style={{ height: '162px' }}
							className="u-pb--tiny u-d--flex"
						>
							<Image
								className="u-m--auto"
								cdnOptions={{
									height: 324,
									fit: 'contain',
								}}
								path={selectedImage.image}
								alt={
									selectedImage.alt_text ||
									variant.displayName
								}
							/>
						</div>
					)}
				</a>
			</div>
			<div className="deal-colour-select u-ta--center">
				{availableDealColours.map((colour: ColourType, index) => (
					<button
						onClick={() => selectColour(colour)}
						className={classnames({
							'u-my--micro': true,
							'u-mx--tiny': availableDealColours.length <= 3,
							'u-mx--micro': availableDealColours.length > 3,
							selected: selectedColour.slug === colour.slug,
						})}
						key={index}
						style={{
							background: `#${colour.primaryHex}`,
							// Black outline for pure white colours
							border: `1px solid ${
								colour.primaryHex.toLowerCase() === 'fff' ||
								colour.primaryHex.toLowerCase() === 'ffffff'
									? '#000000'
									: 'transparent'
							}`,
						}}
						aria-label={colour.name}
					></button>
				))}
			</div>
		</>
	);
}

export { DealVariant };
