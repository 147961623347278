/**
 * DealPanelContent
 *
 * Passes all components and props to the provided panelContentRenderer
 * for full customisation of a deal panel
 *
 * See PanelContentRendererPropTypes for available options
 */

import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { DealPropType, DealContext } from '.';

import { Deal as DealType } from 'components/types';

const DealPanelPropTypes = {
  /**
   * @param deal a deal from cloud9
   */
  deal: DealPropType,

  /**
   * @param openModalWithDeal triggers view details modal open with
   * the specified deal
   */
  openModalWithDeal: PropTypes.func.isRequired,

  /**
   * @param panelContentRenderer
   */
  panelContentRenderer: PropTypes.func.isRequired,
};

function DealPanelContent({
  panelContentRenderer,
  deal,
  openModalWithDeal,
  // @ts-expect-error TODO
  extraProps = {},
}: InferProps<
  typeof DealPanelPropTypes & { deal: DealType; position: number }
>) {
  return (
    <>
      {panelContentRenderer({
        deal,
        openModalWithDeal,
        extraProps,
      })}
    </>
  );
}
DealPanelContent.propTypes = DealPanelPropTypes;

export { DealPanelContent };
