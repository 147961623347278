import React, { createContext, useContext } from 'react';

export const StockContext = createContext<StockContextType | null>(null);

export const useStockContext = () => {
	const ctx = useContext(StockContext);

	if (ctx === undefined) {
		throw new Error('useStockContext must be used within a StockProvider');
	}

	return ctx;
};

export interface StockContextType {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNextDay: boolean;
	estimatedDelivery: string;
	deliveryMessage: React.ReactNode;
	inStock: boolean;
	stockMessage: string;
	class: string;
	timer: string;
	cutOff: Date;
}
