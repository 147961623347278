import { Handset, Variant, Colour, Deal, HandsetDeal } from '../components/types';

interface BestDeal {
  variant: Variant;
  deal: Deal;
  changes: Record<string, any>;
  isTopDeal: boolean;
}

const IN_STOCK_MODES = ['good', 'low'];

export class GetClosestDeliveryForDealVariantService {
  private readonly originalVariant: Variant;
  private readonly originalColour: Colour;
  private readonly handset: Handset;
  private readonly originalDeal: Deal;

  private bestDeal: BestDeal;

  private potentialDeals: BestDeal[] = [];

  constructor(handset: Handset, originalVariant: Variant, originalDeal: Deal) {
      this.originalVariant = originalVariant;
      this.originalColour = this.originalVariant.colour;
      this.handset = handset;
      this.originalDeal = originalDeal;
  }

  public getClosestDeliveryForHandset(): BestDeal {
    const target = window.location.href.includes('/upgrade') ? 'upgrade' : 'new-contract';
    const newContractDeals = this.handset.deals['free-from'][target];

    if (newContractDeals) {
      const { slug: colourSlug } = this.originalColour;
      const { slug: capacitySlug } = this.originalVariant.capacity;

      this.findClosestDealInColour(colourSlug, newContractDeals, capacitySlug);

      // If we find a deal with the current colour under a different capacity, this should be what we return.
      if (this.bestDeal) {
        return this.bestDeal;
      }

      const allAvailableColours = Object.keys(newContractDeals).filter(colour => colour !== colourSlug);
      for (const colour of allAvailableColours) {
        this.findClosestDealInColour(colour, newContractDeals);

        if (this.bestDeal && this.bestDeal.variant.capacity.slug === this.originalVariant.capacity.slug) {
          return this.bestDeal;
        }
      }
    }

    return this.bestDeal;
  }

  private findClosestDealInColour(colourName: string, outerDeals: HandsetDeal[], currentCapacity?: string): void {
    const capacities = Object.keys(outerDeals[colourName]);

    if (currentCapacity) {
      const deals = outerDeals[colourName][currentCapacity];
      this.iterateDeals(deals);

      if (this.potentialDeals.length > 0) {
        for (const deal of this.potentialDeals) {
          if (deal.isTopDeal) {
            this.bestDeal = deal;
          }
        }

        if (!this.bestDeal) {
          this.bestDeal = this.potentialDeals[0];
        }
      }
    }

    for (const capacity of capacities) {
      const deals = outerDeals[colourName][capacity];
      this.iterateDeals(deals);
    }

    for (const deal of this.potentialDeals) {
      if (deal.isTopDeal) {
        this.bestDeal = deal;
      }
    }

    if (!this.bestDeal && this.potentialDeals[0]) {
      this.bestDeal = this.potentialDeals[0];
    }
  }

  private iterateDeals(deals: HandsetDeal[]): void {
    for (const { topDeal, deal } of deals) {
      if (IN_STOCK_MODES.includes(deal.variant.stock)) {
        const changes = this.calculateChanges(deal);

        deal.setByAutoService = true;

        this.potentialDeals.push({
          variant: deal.variant,
          deal,
          changes,
          isTopDeal: topDeal,
        });
      }
    }
  }

  private calculateChanges(recommendedBestDeal: Deal): any {
    const changes = {};

    const hasNetworkChanged = recommendedBestDeal.tariff.network.id !== this.originalDeal.tariff.network.id;
    if (hasNetworkChanged) {
      changes['Network'] = {
        before: this.originalDeal.tariff.network,
        after: recommendedBestDeal.tariff.network,
      };
    }

    changes['Price'] = {
      before: this.originalDeal.monthlyBeforeCashbackDisplay,
      after: recommendedBestDeal.monthlyBeforeCashbackDisplay,
      difference: recommendedBestDeal.monthlyBeforeCashback - this.originalDeal.monthlyBeforeCashback,
    };

    const hasCapacityChanged = recommendedBestDeal.variant.capacity.id !== this.originalDeal.variant.capacity.id;
    if (hasCapacityChanged) {
      changes['Capacity'] = {
        before: this.originalDeal.variant.capacity,
        after: recommendedBestDeal.variant.capacity,
      };
    }

    const hasColourChanged = recommendedBestDeal.variant.colour.id !== this.originalDeal.variant.colour.id;
    if (hasColourChanged) {
      changes['Colour'] = {
        before: this.originalDeal.variant.colour,
        after: recommendedBestDeal.variant.colour,
      };
    }

    return changes;
  }
}
