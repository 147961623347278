import React from 'react';
import classnames from 'classnames';
import { LaunchStatus } from './Deals/DealPanel';

export const NEW_COLOUR = '#D92121';
export const PRE_ORDER_COLOUR = '#0074cc';

function PreOrderBadge({
  launchStatus,
  style = {},
  className = '',
  ...props
}: {
  launchStatus: LaunchStatus;
  style: any;
  className?: string;
}) {
  if (launchStatus === 'launched') return null;

  return (
    <span
      style={{
        ...style,
        fontSize: '8px',
        borderRadius: '6px',
        background:
          launchStatus === 'pre-order' ? PRE_ORDER_COLOUR : NEW_COLOUR,
      }}
      className={classnames(className, 'u-c--inverted u-px--micro')}
      {...props}
    >
      {launchStatus === 'pre-order' ? 'Pre Order' : 'New'}
    </span>
  );
}

export { PreOrderBadge };
