/**
 * Generic modal
 *
 * Site wide mpd custom strata modal
 *
 * Usage:
 *
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setModalOpen(true)}
        aria-label="View cashback details"
      >
        View details
      </button>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title="Cashback"
      >
        <div>Modal content</div>
      </Modal>
    </>
    )
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal as StrataModal } from '@ao-internal/react-components';

import './Modal.scss';

const CLASSES_TO_CLOSE_ON = ['c-modal-outer', 'c-modal-inner'];

function Modal(props) {
	const {
		title,
		modalOpen,
		setModalOpen,
		children,
		onModalClose = null,
		modalInnerProps = { className: 'mpd-modal-inner u-p--small' },
		...restProps
	} = props;

	function onModalCloseDefault(evt) {
		evt.preventDefault();
		document.body.classList.remove('strata-modal-open');
		setTimeout(() => {
			setModalOpen(!modalOpen);
		}, 200);
	}

	useEffect(() => {
		const { body } = document;

		const onOutsideClicked = (evt): void => {
			const target = evt.target as Element;
			const classList: string[] = Array.from(target.classList);

			if (
				classList.some(className =>
					CLASSES_TO_CLOSE_ON.includes(className)
				)
			) {
				setModalOpen(false);
			}
		};

		body.addEventListener('click', onOutsideClicked);

		return () => body.removeEventListener('click', onOutsideClicked);
	});

	// #root element is assumed to be container for page content
	const appElement = document.querySelector('#root');
	StrataModal.setAppElement(appElement);

	return (
		<StrataModal
			isOpen={modalOpen}
			shouldCloseOnOverlayClick={true}
			onRequestClose={onModalClose || onModalCloseDefault}
			{...restProps}
		>
			<h2 className="mpd-modal-title u-py--medium u-pr--xlarge">
				{title}
			</h2>
			<div {...modalInnerProps}>{children}</div>
		</StrataModal>
	);
}

Modal.propTypes = {
	modalOpen: PropTypes.bool.isRequired,
	setModalOpen: PropTypes.func.isRequired,
	title: PropTypes.node.isRequired,
	children: PropTypes.node,
	className: PropTypes.string,
	/**
	 * @param onModalClose optional modal close event override
	 */
	onModalClose: PropTypes.func,

	/**
	 * @param modalInnerProps optional props to pass to modal inner div
	 * Default className 'mpd-modal-inner u-p--small' - include this if you want
	 * to override this object
	 */
	modalInnerProps: PropTypes.shape({
		className: PropTypes.string,
	}),
};

export { Modal };
