import { Reducer } from 'react';
import { ActionProps, ContextProps, Directions } from './CarouselItems.props';

export const CarouselItemReducer: Reducer<ContextProps, ActionProps> = (
	state,
	action
): ContextProps => {
	// get window width to calculate breakpoints.
	const { innerWidth } = window;

	// Item width for calculating scroll positions.
	const boxWidth = state.scrollContainer.current
		.querySelector('.slide_container > div')
		.getBoundingClientRect().width;
	// current scroll position so we know which slide we're on.
	const position = state.scrollContainer.current.scrollLeft;
	const onSlide = Math.round(position / boxWidth);
	// set the previous breakpoint as 0 to catch anything >=
	let breakpointPrevious = { bp: 0, amount: 1 };

	// Amount of items we want to scroll by based on breakpoints.
	let scrollBy: number;
	for (const key in state.breakpoint) {
		const bp = Number(key);
		const amount: number = state.breakpoint[key];
		if (innerWidth >= breakpointPrevious.bp && innerWidth <= bp) {
			scrollBy = amount;
			break;
		}
	}

	state.hideControls = state.itemCount <= scrollBy;
	if (state.forceHideControls) state.hideControls = state.forceHideControls;

	if ('type' in action) {
		switch (action.type) {
			case Directions.Next:
				state.scrollContainer.current.scrollTo({
					behavior: 'smooth',
					left: position + boxWidth * scrollBy,
				});
				break;
			case Directions.Previous:
				state.scrollContainer.current.scrollTo({
					behavior: 'smooth',
					left: position - boxWidth * scrollBy,
				});
				break;
		}
	}

	if ('goto' in action) {
		state.scrollContainer.current.scrollTo({
			behavior: 'smooth',
			left: boxWidth * action.goto,
		});
	}

	// Trigger a update to calculate which dots are active.
	if ('update' in action) {
		let inView = [];

		for (const key in state.breakpoint) {
			const bp = Number(key);
			const amount: number = Math.round(state.breakpoint[key]);

			if (innerWidth >= breakpointPrevious.bp && innerWidth <= bp) {
				inView = [...Array(amount)].map((v, index) => index + onSlide);
				state.active = inView;
			}
			breakpointPrevious = { bp, amount };
		}
	}

	return { ...state };
};
