import { Dispatch, HTMLAttributes, ReactNode, RefObject } from 'react';

export enum Directions {
	Next = 'next',
	Previous = 'prev',
}

export interface CarouselItemsProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode | ReactNode[];
	breakpoint: Record<number, number>;
	itemCount: number;
	hideControls?: boolean;
}

export type ActionProps =
	| { type: Directions; update?: never; goto?: never }
	| { type?: never; update: boolean; goto?: never }
	| { type?: never; update?: never; goto?: number };

export interface ContextProps {
	breakpoint?: Record<number, number>;
	itemCount: number;
	active: number[];
	hideControls: boolean;
	forceHideControls: boolean;
	scrollContainer: RefObject<HTMLDivElement>;
	componentId: number;
}

export interface ReducerProps {
	state: ContextProps;
	dispatch: Dispatch<ActionProps>;
}
