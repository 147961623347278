import React, { useState } from 'react';
import './Terms.scss';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { Flags } from 'services/LaunchDarkly/flags';

export function Terms(): JSX.Element {
	const [show, setShow] = useState(true);

	launchDarklyClient.getFlag(Flags.DealTerms).then(setShow);

	if (!show) return <></>;
	return (
		<div className="Homepage--Terms">
			<b>Pricing info:</b> These are today&apos;s prices. Networks
			increase prices each April in line with a published inflation rate +
			3.9% - <a href="#CPICost">learn more</a>
		</div>
	);
}
