import React, {
	PropsWithChildren,
	useRef,
	ReactNode,
	MouseEvent,
	useEffect,
} from 'react';
import './Popover.scss';
import { Button } from 'components/Common/Button';
import LaunchDarkly from '../LaunchDarkly/LaunchDarkly';
import { Flags } from 'services/LaunchDarkly/flags';

type PopoverProps = (
	| {
			buttonOnClick: () => Promise<boolean> | boolean;
			options?: true;
			onConfirm: () => void;
			isNewPopover?: boolean;
	  }
	| {
			buttonOnClick: () => boolean;
			options: false;
			onConfirm?: never;
			isNewPopover?: boolean;
	  }
) & { content: ReactNode };

const Popover = ({
	buttonOnClick,
	onConfirm = () => {},
	children,
	content,
	options = true,
	isNewPopover = true,
}: PropsWithChildren<PopoverProps>) => {
	const popoverRef = useRef<HTMLDialogElement | null>(null);

	const handleYes = () => {
		const hasConfirmed = true;
		globalThis.dataLayer.push({
			event: 'Clear Basket Popover Pressed Yes',
			eventCategory: 'Add To Basket Button',
			eventAction: 'User clicked Yes on the Clear Basket Popover',
		});
		onConfirm();
		handleClose(hasConfirmed);
	};

	const handleClose = (hasConfirmed = false) => {
		if (!hasConfirmed) {
			globalThis.dataLayer.push({
				event: 'Clear Basket Popover Closed',
				eventCategory: 'Add To Basket Button',
				eventAction: 'User clicked No on the Clear Basket Popover',
			});
		}

		popoverRef.current?.close();
	};

	const handleOpen = async () => {
		const shouldOpenTooltip = await buttonOnClick();
		if (isNewPopover && shouldOpenTooltip) {
			globalThis.dataLayer.push({
				event: 'Clear Basket Popover Opened',
				eventCategory: 'Add To Basket Button',
				eventAction:
					'User clicked add to basket with deal or handset in basket',
			});
		}
		if (shouldOpenTooltip) {
			popoverRef.current?.show();
		} else {
			onConfirm();
		}
	};

	const handleDialogClick = (event: MouseEvent<HTMLDialogElement>) => {
		if (
			event.target instanceof HTMLElement &&
			event.target.nodeName === 'DIALOG'
		) {
			handleClose();
		}
	};

	// display ClearBasket.tsx and AccessoryCard.tsx popovers regardless of LaunchDarkly flag
	if (!isNewPopover)
		return (
			<>
				<button className="OpenButton" onClick={handleOpen}>
					{children}
				</button>
				<dialog
					className="Popover"
					ref={popoverRef}
					onClick={handleDialogClick}
				>
					{content}
					{options && (
						<div className="Popover--Buttons">
							<Button
								variant="white"
								onClick={() => handleClose()}
							>
								No
							</Button>
							<Button variant="secondary" onClick={handleYes}>
								Yes
							</Button>
						</div>
					)}
				</dialog>
			</>
		);

	return (
		<>
			<LaunchDarkly flag={Flags.basketPopover}>
				<LaunchDarkly.Boolean value={true}>
					<button className="OpenButton" onClick={handleOpen}>
						{children}
					</button>
					<dialog
						className="Popover"
						ref={popoverRef}
						onClick={handleDialogClick}
					>
						{content}
						{options && (
							<div className="Popover--Buttons">
								<Button
									variant="white"
									onClick={() => handleClose()}
								>
									No
								</Button>
								<Button variant="secondary" onClick={handleYes}>
									Yes
								</Button>
							</div>
						)}
					</dialog>
				</LaunchDarkly.Boolean>
				<LaunchDarkly.Boolean value={false}>
					<button className="OpenButton" onClick={onConfirm}>
						{children}
					</button>
				</LaunchDarkly.Boolean>
			</LaunchDarkly>
		</>
	);
};

export default Popover;
