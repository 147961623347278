import React, { MouseEvent, useContext, useEffect, useRef } from 'react';

import { Button } from 'components/Common/Button';

import { DealPropType } from '../DealPropType';

import { Deal as DealType, Colour as ColourType, Deal } from 'components/types';
import { DealContext } from 'components/Common/Deals/DealPanel';

import {
	getDealBuyLinkByColour,
	getDealBuyLinkByColourHMC,
	getVariantLaunched,
} from 'components/Common/Deals/DealPanel/selectors';
import Popover from 'components/Common/Popover/Popover';
import { useCustomContext } from 'hooks/use-custom-context';

interface BuyNowContextProps {
	deal: DealType;
	selectedColour: ColourType;
}

interface BuyNowProps {
	deal?: Deal;
	extraProps?: any;
}

function BuyNow({ extraProps }: BuyNowProps) {
	const { deal, selectedColour } = useContext<BuyNowContextProps>(
		DealContext
	);
	const handsetLaunched = getVariantLaunched(deal);
	let buyLink;

	if (extraProps.BuyNowPrefix) {
		buyLink =
			extraProps.BuyNowPrefix +
			getDealBuyLinkByColourHMC(deal, selectedColour, extraProps);
	} else {
		buyLink = getDealBuyLinkByColour(deal, selectedColour, extraProps);
	}

	const handleConfirmClick = () => {
		const hasClickedViewMore = window.localStorage.getItem(
			'userClickedViewMoreDeals'
		);
		if (hasClickedViewMore) {
			globalThis.dataLayer.push({
				event: 'Top Deal View More Deals clickthrough',
				eventCategory: 'Top Deal',
				eventAction:
					'User added deal to basket after clicking view more deals',
				eventLabel: 'Top deal - view more deals',
			});
			localStorage.removeItem('userClickedViewMoreDeals');
		}
		window.location.href = buyLink;
	};

	const handleButtonClick = (): boolean => {
		const { variantInBasket, tariffInBasket } = window.MPD;
		return variantInBasket || tariffInBasket;
	};

	return (
		<Popover
			buttonOnClick={handleButtonClick}
			onConfirm={handleConfirmClick}
			content={
				<>
					<h2>Adding this will clear your current basket.</h2>
					<p>Are you sure you want to continue?</p>
					<br />
				</>
			}
		>
			<Button
				variant="primary"
				onContextMenu={e => {
					e.preventDefault();

					const contextMenu = document.getElementById('contextMenu');
					contextMenu.style.display = 'block';
					contextMenu.style.top = e.pageY + 'px';
					contextMenu.style.left = e.pageX + 'px';
					document
						.getElementById('copyBasketLinkButton')
						.addEventListener('click', e => {
							let url = buyLink;
							if (!buyLink.includes('http')) {
								url = new URL(
									`${window.location.origin}${buyLink}`
								).toString();
							}
							navigator.clipboard
								.writeText(url)
								.then(() => {})
								.catch(() => {});
						});

					const handleOutsideClick = (e: any) => {
						if (!contextMenu.contains(e.target as Node)) {
							contextMenu.style.display = 'none';
							document.removeEventListener(
								'click',
								handleOutsideClick
							);
						}
					};

					document.addEventListener('click', handleOutsideClick);
				}}
				style={{
					margin: 'auto',
				}}
			>
				{handsetLaunched ? 'Buy Now' : 'Pre-order'}
			</Button>
		</Popover>
	);
}
BuyNow.propTypes = {
	deal: DealPropType,
};

export { BuyNow };
