import React from 'react';
import { Deal, HandsetPromo } from 'components/types';
import { Column, Row } from '@ao-internal/react-components';
import { Image } from 'components/Common/Image';
import { Accordion } from 'components/Common/Accordion';
import { ProtectionPackIncludedItem } from 'components/Handset/CustomModals/ProtectionPackIncludedItem';
import './ProtectionPackModal.scss';

const SAMSUNG_PROTECTION_PACK_INCLUDED_ITEMS = [
	{
		image:
			'/images/bespoke-promos/free-protection-pack/free-protection-pack.svg',
		title: 'Genuine Samsung Phone Case',
		content:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		image:
			'/images/bespoke-promos/free-protection-pack/free-protection-pack.svg',
		title: 'Genuine Samsung Screen Protector',
		content:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
	{
		image:
			'/images/bespoke-promos/free-protection-pack/free-protection-pack.svg',
		title: '25W Samsung Charger',
		content:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	},
];

const APPLE_PROTECTION_PACK_INCLUDED_ITEMS = [
	{
		image: '/images/bespoke-promos/free-protection-pack/phone-case.png',
		title: 'Clear MagSafe phone case worth £40',
		content:
			'The mobile phone case is clear so you can still see all the design features of your device and Magsafe compatible to make charging easier. Constructed from premium materials, it offers excellent shock absorption and impact resistance, shielding your mobile phone from accidental drops and bumps.',
	},
	{
		image:
			'/images/bespoke-promos/free-protection-pack/screen-and-camera-protector.png',
		title: 'Screen & Camera Protector worth £30',
		content:
			'Complementing the case is our premium camera and screen protector, engineered to provide crystal-clear clarity while defending against scratches, smudges, and fingerprints. Crafted from tempered glass, it boasts high transparency and touch sensitivity, ensuring an optimal viewing and usage experience.',
	},
];

const ProtectionPackIncludedItems = new Map([
	['apple', APPLE_PROTECTION_PACK_INCLUDED_ITEMS],
	['samsung', SAMSUNG_PROTECTION_PACK_INCLUDED_ITEMS],
]);

export function ProtectionPackModal({
	promo,
	deal = null,
}: {
	promo: HandsetPromo;
	deal?: Deal;
}): JSX.Element {
	let protectionPackItems = [];
	if (promo.name.toLowerCase().includes('iphone')) {
		protectionPackItems = ProtectionPackIncludedItems.get('apple');
	} else if (
		promo.name.toLowerCase().includes('samsung') ||
		promo.about.toLowerCase().includes('samsung')
	) {
		protectionPackItems = ProtectionPackIncludedItems.get('samsung');
	}

	return (
		<>
			<div
				className="promo-background-inner ProtectionPackModal u-c--inverted u-p--medium u-px--giant--bp-medium u-py--large--bp-medium"
				style={{ backgroundColor: '#EDF2FF' }}
			>
				<Row className="u-fxw--wrap-reverse">
					<Column size={12} medium={9}>
						<h2
							className="u-fw--600 u-my--small"
							style={{ color: '#0564C2' }}
						>
							{promo.name}
						</h2>
						<p
							className="u-fz--title-small"
							style={{ color: '#0564C2' }}
						>
							Get a free Protection Pack worth £70 when you buy a
							new contract or upgrade on this handset. The clear
							Magsafe compatible mobile phone case has been paired
							with a high-quality camera and screen protector.
							Crafted with precision and designed for durability,
							this bundle offers comprehensive safeguarding for
							your device, ensuring it stays safe from everyday
							wear and tear. Exclusive to Mobile Phones Direct.
							Protection Pack will be shipped with handset.
						</p>
					</Column>
					<Column
						size={12}
						medium={3}
						className="u-ta--center u-my--tiny"
					>
						<Image
							path="/images/bespoke-promos/free-protection-pack/protection-bundle.svg"
							style={{
								maxWidth: '200px',
								maxHeight: '200px',
							}}
							alt={promo.name}
						/>
					</Column>
				</Row>

				<Row>
					{protectionPackItems.length > 0 && (
						<>
							<h2
								className="u-fz--title-small ProtectionPackModal--Includes"
								style={{ color: '#0564C2' }}
							>
								Protection Pack Includes:
							</h2>

							<div className="includes">
								{protectionPackItems.map(item => {
									return (
										<ProtectionPackIncludedItem
											key={item.title}
											title={item.title}
											content={item.content}
											image={item.image}
										/>
									);
								})}
							</div>
						</>
					)}
				</Row>
			</div>
			<Row className="u-pt--large u-px--xlarge--bp-medium u-px--tiny">
				<Column>
					<h2 className="u-my--small">How to claim</h2>
					<div
						className="promo-how-to-claim u-fz--title-small"
						dangerouslySetInnerHTML={{
							__html: promo.howToClaim,
						}}
					/>
				</Column>
			</Row>
			<Row className="u-px--xlarge--bp-medium u-p--medium">
				<Column>
					<Accordion title="Terms and Conditions">
						<div
							dangerouslySetInnerHTML={{
								__html: promo.terms,
							}}
						></div>
					</Accordion>
				</Column>
			</Row>
		</>
	);
}
