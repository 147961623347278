/**
 * Deal modal
 *
 * One modal for all deals in the list. When the modal is opened,
 * the deal is passed to it to display tarrif extras information
 *
 * Passes deal to Renderer as a prop
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../Modal';
import { DealPropType } from './DealPanel';

function DealModal({
	deal,
	Renderer,
	title,
	modalOpen,
	setModalOpen,
	otherProps,
}) {
	const content = deal ? (
		<Renderer deal={deal} setModalOpen={setModalOpen} {...otherProps} />
	) : null;

	return (
		<Modal
			modalOpen={modalOpen}
			setModalOpen={setModalOpen}
			title={title}
			{...otherProps}
		>
			{content}
		</Modal>
	);
}
DealModal.propTypes = {
	deal: DealPropType,
	Renderer: PropTypes.func,
	modalOpen: PropTypes.bool.isRequired,
	setModalOpen: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	otherProps: PropTypes.object,
};

export { DealModal };
