import React, { useEffect, useState } from 'react';
import { Deal } from 'components/types';
import { getTopDeals } from 'components/Common/api';
import { DealList } from 'components/Common/Deals/DealList';
import { DefaultDealPanel } from 'components/Common/Deals/DealPanel/DefaultDealPanel';
import { CarouselItems } from 'components/Common/CarouselItems';
import { Loading } from 'components/Lister/Loading';

export function TopDeals(): JSX.Element {
	const [deals, setDeals] = useState<Array<Deal>>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const topDeals = await getTopDeals();
			setIsLoading(false);
			setDeals(topDeals);
		})();
	}, []);

	if (isLoading || !deals) {
		return (
			<div className="o-wrapper o-flex-container">
				<Loading numberOfSkeletonsToShow={3} isSimo={false} />
			</div>
		);
	}

	return (
		<>
			<CarouselItems
				itemCount={deals.length}
				breakpoint={{ '600': 1, '920': 2, '1200': 3 }}
				className="u-pb--medium"
			>
				<DealList
					panelWrapperClassName="u-px--small"
					deals={deals}
					panelContentRenderer={DefaultDealPanel}
					list="Top Deals"
				/>
			</CarouselItems>
		</>
	);
}
