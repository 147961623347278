import React from 'react';
import { Image } from 'components/Common/Image';
import { createTrackLinkOnClick } from 'components/Common/analytics';

import './MerchPods.scss';

const MERCH_PODS_HEIGHT_MOBILE = 290;
const MERCH_PODS_HEIGHT_DESKTOP = 400;

function MerchPod({ link, trackingEventMerchDetails, imageProps }) {
	return (
		<div className="o-flex-item u-grid--4--bp-medium u-p--xsmall--bp-medium">
			<a
				className="u-d--block u-px--micro--bp-medium u-pos--relative u-mx--auto u-ta--center"
				href={link}
				onClick={createTrackLinkOnClick(link, {
					event: 'merchClick',
					eventCategory: 'Merchandise pods',
					eventAction: trackingEventMerchDetails,
					destinationurl: link,
				})}
				data-promo-pod-name={imageProps.alt}
			>
				<Image {...imageProps} />
			</a>
		</div>
	);
}

function MerchPods({ merchPods, smallMerchPods }) {
	return (
		<div className="o-wrapper home-merch-pods u-mb--small u-mb--none--bp-medium u-px--small u-px--medium--bp-medium">
			<div className="o-flex-container u-px--xsmall--bp-medium">
				{merchPods.map((merchPod, index) => (
					<MerchPod
						key={index}
						link={merchPod.link}
						trackingEventMerchDetails={
							`Pod ${index + 1} - ` + merchPod.tracking
						}
						imageProps={{
							path: `/cdn-cgi/image/height=${
								smallMerchPods
									? MERCH_PODS_HEIGHT_MOBILE
									: MERCH_PODS_HEIGHT_DESKTOP
							},fit=contain,format=auto${
								smallMerchPods
									? merchPod.mobileImage
									: merchPod.desktopImage
							}`,
							alt: merchPod.alt,
							className: 'bf-merch-pod',
						}}
					/>
				))}
			</div>
		</div>
	);
}

export { MerchPods };
