import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'components/Common/ErrorBoundary';

interface LazyLoadProps {
	children: ReactNode | ReactNode[];
}

export function LazyLoad({ children }: LazyLoadProps) {
	const [inView, setInView] = useState(false);
	const referenceDiv = useRef<HTMLDivElement | null>(null);

	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.02,
	};
	const observer = new IntersectionObserver(function (entries, observer) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				setInView(true);
				observer.unobserve(entry.target);
			}
		});
	}, options);

	useEffect(() => {
		if (referenceDiv.current) {
			observer.observe(referenceDiv.current);
		}
	});

	return inView ? (
		<ErrorBoundary>{children}</ErrorBoundary>
	) : (
		<div ref={referenceDiv}></div>
	);
}
