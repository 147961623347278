import * as LDClient from 'launchdarkly-js-client-sdk';
import { generateUUID } from 'services/generateUUID';

class LaunchDarklyClient {
	private client: LDClient.LDClient;
	private flags: Promise<Record<string, any>>;
	public isInitialised = false;

	constructor() {
		let uuid;
		if (localStorage.getItem('ld-uuid')) {
			uuid = localStorage.getItem('ld-uuid');
		} else {
			uuid = generateUUID();
			localStorage.setItem('ld-uuid', uuid);
		}

		const context: LDClient.LDContext = {
			kind: 'user',
			key: uuid,
		};

		this.client = LDClient.initialize(globalThis.featureSwitchKey, context);

		this.flags = this.client.waitForInitialization().then(() => {
			this.isInitialised = true;
			return this.client.allFlags();
		});
	}

	public async getFlag<T = unknown>(flagName: string): Promise<T> {
		return (await this.flags)[flagName];
	}

	public async getFlags() {
		return await this.flags;
	}

	public close() {
		if (this.client.flush)
			this.client.flush(() => {
				this.client.close();
			});
	}

	public on(flag: string, callback: (...args: any[]) => void) {
		this.client.on(flag, callback);
	}

	public off(flag: string, callback: (...args: any[]) => void) {
		this.client.off(flag, callback);
	}
}

export const launchDarklyClient = new LaunchDarklyClient();
