import {
	Basket,
	Basket as BasketType,
	Deal as DealType,
	GenericDeal,
	User as UserType,
} from 'components/types';
import { intervalToDuration } from 'date-fns';

export const timeStringToDate = (dateString: string): null | Date => {
	let year, month, day;

	if (dateString.includes('-')) {
		[year, month, day] = dateString.split('-');
	} else {
		[month, year] = dateString.split('/');
	}

	const date = new Date(Number(year), Number(month) - 1);

	if (isNaN(date.getTime())) {
		return null;
	}

	return date;
};

export const basketToDeal = (basket: Basket): DealType => ({
	type: basket.type,
	basketHash: basket.basketHash || '',
	basketLink: '',
	dealType: basket.dealType,
	dealTypeId: basket.dealTypeId,
	handsetUpfront: basket.handsetUpfront,
	handsetUpfrontDisplay: basket.handsetUpfrontDisplay,
	upfront: basket.upfrontAmountRaw,
	upfrontDisplay: basket.upfrontAmountRawDisplay,
	cashback: basket.cashback,
	cashbackDisplay: basket.cashbackDisplay,
	variant: basket.variant,
	tariff: basket.tariff,
	bundle: basket.bundle,
	monthlyBeforeCashback: basket.monthlyBeforeCashback,
	monthlyAfterCashback: basket.monthlyAfterCashback,
	monthlyAfterCashbackDisplay: basket.monthlyAfterCashbackDisplay,
	monthlyBeforeCashbackDisplay: basket.monthlyBeforeCashbackDisplay,
	tco: basket.tco,
	tcoActual: basket.tcoActual,
	tcoDisplay: basket.tcoDisplay,
	tcoActualDisplay: basket.tcoActualDisplay,
	totalMonthlyOverLengthDisplay: basket.totalMonthlyOverLengthDisplay,
	totalMonthlyOverLengthCashbackDisplay:
		basket.totalMonthlyOverLengthCashbackDisplay,
	promo: basket.promo,
});

export const calculateBasketAddressTime = (
	basket: BasketType,
	user: UserType
): number => {
	if (user.addresses.length === 0 || !basket.requiresAddressHistory) {
		return 0;
	}

	const lastAddress = user.addresses[user.addresses.length - 1];

	const addressDate = timeStringToDate(lastAddress.from_date);

	if (!addressDate) {
		return null;
	}

	const addressTime = intervalToDuration({
		start: addressDate,
		end: new Date(),
	});

	return addressTime.years * 12 + addressTime.months;
};

export interface LoadingState {
	active: boolean;
	message?: string;
}

export const defaultLoadingState = (): LoadingState => ({
	active: false,
	message: null,
});
