declare interface ConfigObject {
  id: number | string;
  title: string;
};

export const TITLES: Array<ConfigObject> = [
  {id: 1, title: 'Mr'},
  {id: 2, title: 'Mrs'},
  {id: 3, title: 'Miss'},
  {id: 4, title: 'Ms'},
  {id: 5, title: 'Dr'},
  {id: 6, title: 'Sir'},
  {id: 7, title: 'Lord'},
  {id: 8, title: 'Lady'},
];

export const MARITAL_STATUSES: Array<ConfigObject> = [
  {id: 1, title: 'Married'},
  {id: 2, title: 'Single'},
  {id: 3, title: 'Divorced'},
  {id: 4, title: 'Separated'},
  {id: 5, title: 'Widowed'},
];

export const OCCUPATION_STATUSES: Array<ConfigObject> = [
  { id: 1, title: 'Full Time'},
  { id: 2, title: 'Part Time'},
  { id: 3, title: 'Student'},
  { id: 4, title: 'House Person'},
  { id: 5, title: 'Retired'},
  { id: 6, title: 'Fixed Term Contract'},
  { id: 7, title: 'Self Employed'},
  { id: 8, title: 'Disabled'},
  { id: 9, title: 'Unemployed'},
];

export const THREE_OCCUPATION_STATUSES: Array<ConfigObject> = [
  { id: 1, title: 'Employed'},
  { id: 2, title: 'Part Time Employed'},
  { id: 3, title: 'Student'},
  { id: 4, title: 'Houseperson'},
  { id: 5, title: 'Retired'},
  { id: 6, title: 'Fixed Term Contract'},
  { id: 7, title: 'Self Employed Professional'},
  { id: 8, title: 'Disabled'},
  { id: 9, title: 'Unemployed'},
  { id: 10, title: 'Other'},
  { id: 11, title: 'Temporary Employment'},
  { id: 12, title: 'Self Employed Non Professional'},

];

export const RESIDENTIAL_STATUSES: Array<ConfigObject> = [
  {id: 1, title: 'Owner'},
  {id: 2, title: 'Joint Owner'},
  {id: 3, title: 'Living With Parents'},
  {id: 4, title: 'Tenant'},
  {id: 5, title: 'Armed Forces'},
];

export const ADDRESS_FIELDS_DISPLAY: Array<ConfigObject> = [
  {id: 'organisation', title: ''},
  {id: 'property', title: ''},
  {id: 'street', title: ''},
  {id: 'town', title: ''},
  {id: 'county', title: ''},
  {id: 'post_code', title: ''},
];

export const TRADE_IN_CONDITION: Array<ConfigObject> = [
  {id: 1, title: 'Working'},
  {id: 2, title: 'Faulty'},
];

export const TRADE_IN_NETWORK: Array<ConfigObject> = [
  {id: 1, title: 'Locked'},
  {id: 2, title: 'Unlocked'},
  {id: 3, title: 'Unknown'},
];
