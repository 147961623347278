import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { CarouselContext } from 'components/Common/Carousel/Carousel';
import { createTrackLinkOnClick } from 'components/Common/analytics';
import { SlideProps } from 'components/Common/Carousel/Carousel.props';
import { PropositionBarModal } from '../Header/PropositionBar/PropositionBarModal';
import { isOOH } from '../Header/PropositionBar/PropositionBar';
import { useScreenSize } from '../use-screen-size';
import { Day } from 'enums/Day.enum';

export function Slide({
	ctaBackgroundColour,
	ctaTextColour,
	theme,
	slideTemplate,
	backgroundColour,
	desktopBackgroundImage,
	mobileBackgroundImage,
	index,
	props,
}: SlideProps): JSX.Element {
	slideTemplate = slideTemplate.toLowerCase();
	const phoneNumber: string =
		globalThis.site.sales_phone_number || '03301 65 55 55';
	const hasEvent = /event__[a-zA-Z]+/gm.test(props.customClassName);

	const { screenSize } = useScreenSize();
	const isMobile =
		screenSize === 'xsmall' ||
		screenSize === 'small' ||
		screenSize === 'medium';

	function event__OOH() {
		const currentDay = new Date().getDay();
		const { open, close } = globalThis.site.json_opening_hours[
			Day[currentDay].toLowerCase()
		];
		if (isOOH(open, close)) {
			return setModalOpen(true);
		} else {
			window.location.href = isMobile
				? `tel: ${phoneNumber}`
				: '/help/contact-us';
		}
	}

	useEffect(() => {
		addEventListener('event__OOH', event__OOH);
		return () => {
			removeEventListener('event__OOH', event__OOH);
		};
	}, []);

	const { carouselState, setModalOpen } = useContext(CarouselContext);
	const classNames = [
		'carousel-slide',
		'carousel_' + index,
		'theme_' + theme,
		'template_' + slideTemplate,
		'status_' + (carouselState.current === index ? 'active' : 'inactive'),
		props.className,
		props.customClassName,
	];
	const { ctaText, ctaLink, gaEvent } = props;

	const css = `
    .carousel_${index}{
		background-color: ${backgroundColour ?? '#fff'};
		background-image: url('${mobileBackgroundImage}');
		background-size: ${slideTemplate === 'linkslide' ? 'contain' : 'cover'};
    }
    @media (min-width:990px) {
		.carousel_${index} {
			background-image: url('${desktopBackgroundImage}');
		}
    }
	`;

	function handleClick(event: MouseEvent<HTMLAnchorElement>): void {
		const track = createTrackLinkOnClick(ctaLink, gaEvent);
		track(event);
		event.preventDefault();
	}

	return (
		<div
			className={classNames.join(' ')}
			data-promo-slide-name={gaEvent.eventAction}
		>
			<style>{css}</style>
			<div>
				<div className="shout-container">
					<img
						src={props.desktopCopyImage.src}
						alt={props.desktopCopyImage.alt}
						loading="lazy"
						className="desktop shout"
					/>
					<img
						src={props.mobileCopyImage.src}
						alt={props.mobileCopyImage.alt}
						loading="lazy"
						className="mobile shout"
					/>
					{props.ctaText && props.showCtaBtn && (
						<div className="cta">
							<a
								className="c-btn"
								style={{
									background: ctaBackgroundColour,
									color: ctaTextColour,
								}}
								href={ctaLink}
								onClick={handleClick}
							>
								{ctaText}
							</a>
						</div>
					)}
				</div>
				{props.desktopProductImage && (
					<div className="product-image">
						<img
							className="desktop"
							src={props.desktopProductImage.src}
							alt={props.desktopProductImage.alt}
							loading="lazy"
						/>
						<img
							className="mobile"
							src={props.mobileProductImage.src}
							alt={props.mobileProductImage.alt}
							loading="lazy"
						/>
					</div>
				)}
				<a className="empty-href" href={ctaLink} onClick={handleClick}>
					{' '}
				</a>
			</div>
		</div>
	);
}
