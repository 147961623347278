import React from 'react';
import { Row, Column } from '@ao-internal/react-components';

import { getUpfrontAmount, DealPropType } from '.';

import { Deal as DealType } from 'components/types';
import { RefurbLabel } from 'components/Common/Deals/DealPanel/RefurbLabel';
import { BuyNow } from 'components/Common/Deals/DealPanel/FooterButtons/BuyNow';
import { checkIsDealRefurbished } from 'helpers/utils';
import { AsNewLabel } from 'components/Common/Deals/DealPanel/AsNewLabel';

function SimFreeDealPanelFooter(props) {
	const deal: DealType = props.deal;

	const upfrontAmount = getUpfrontAmount(deal);

	const { panelFooterButton = null } = props;

	const FooterButtonComponent = panelFooterButton || BuyNow;

	const isRefurb = checkIsDealRefurbished(deal);
	const isAsNew =
		!isRefurb && deal.variant && deal.variant.oracleCode.endsWith('ANRF');

	return (
		<div className="deal-panel-footer">
			{(isRefurb || isAsNew) && (
				<Row>
					<Column size={12}>
						<Row className="u-my--none">
							<Column size={12} className="u-p--none">
								{isRefurb && (
									<RefurbLabel
										openModalWithDeal={
											props.openModalWithDeal
										}
										deal={deal}
									/>
								)}

								{isAsNew && (
									<AsNewLabel
										deal={deal}
										openModalWithDeal={
											props.openModalWithDeal
										}
									/>
								)}
							</Column>
						</Row>
					</Column>
				</Row>
			)}
			<Row
				className="u-bgc--background u-mt--auto u-py--tiny u-px--small sim-free-footer"
				style={{ minHeight: '95px', borderRadius: '0 0 10px 10px' }}
			>
				<Column
					size={7}
					className="u-pos--relative u-p--xsmall u-mt--tiny"
				>
					<div className="u-fz--base-small">Total Cost:</div>
					<div
						className="u-fz--display u-mb--micro u-fw--600"
						style={{ lineHeight: '1' }}
					>
						{upfrontAmount}
					</div>
				</Column>
				<Column
					size={5}
					className="u-p--xsmall u-pos--relative sim-free"
				>
					<div>
						<FooterButtonComponent
							extraProps={props.extraProps}
							deal={deal}
						/>
					</div>
				</Column>
			</Row>
		</div>
	);
}
SimFreeDealPanelFooter.propTypes = {
	deal: DealPropType,
};

export { SimFreeDealPanelFooter };
