import React from 'react';

import { Row, Column } from '@ao-internal/react-components';

import {
	PanelRendererPropTypes,
	DealTitle,
	PlanIncludes,
	TariffFeatureBadges,
	TariffExtrasList,
	ViewPlanDetails,
	Cashback,
	Promo,
	NetworkLogo,
	getHasCashback,
	getHasPromo,
} from 'components/Common/Deals/DealPanel';

import { Deal as DealType } from 'components/types';
import { SimoImage } from 'components/Common/Deals/DealPanel/SimoImage';

function SimOnlyDealPanel({
	deal,
	openModalWithDeal,
}: {
	deal: DealType;
	openModalWithDeal: () => void;
}) {
	const showCashback = getHasCashback(deal);
	const showPromo = getHasPromo(deal);

	return (
		<div className="deal-panel-inner u-d--flex u-fxw--wrap u-h--100 u-pb--small u-mb--micro">
			<div
				style={{ alignItems: 'flex-start' }}
				className="u-w--100 deal-panel-inner-info"
			>
				<Row className="u-pt--xsmall u-px--xsmall">
					<Column
						size={9}
						className="u-pl--small u-pt--small u-pr--none"
					>
						<DealTitle />
					</Column>
					<Column
						size={3}
						className="u-d--flex u-p--none u-jc--flex-end"
					>
						<div style={{ float: 'right' }}>
							<TariffFeatureBadges />
						</div>
					</Column>
				</Row>

				<Row className="u-px--small u-pb--micro">
					<Column
						size={5}
						className="u-pt--xsmall u-mt--tiny u-pl--xsmall"
					>
						<SimoImage
							networkSlug={deal.tariff.network.slug}
							className="u-d--block"
							height="162"
							alt="SIM Card"
						/>
					</Column>
					<Column size={7} className="u-pt--none u-pl--small">
						<Row>
							<Column size={12} className="u-pt--large">
								<NetworkLogo />
								<PlanIncludes />
							</Column>
						</Row>
						<Row>
							<Column>
								{deal.tariff.extras.length > 0 && (
									<>
										<ViewPlanDetails
											openModalWithDeal={
												openModalWithDeal
											}
										/>
									</>
								)}
							</Column>
						</Row>
					</Column>
				</Row>
			</div>

			<Row className="u-px--medium u-mt--auto u-py--micro">
				{showCashback && (
					<Cashback openModalWithDeal={openModalWithDeal} />
				)}
				{showPromo && <Promo openModalWithDeal={openModalWithDeal} />}
			</Row>
		</div>
	);
}
SimOnlyDealPanel.propTypes = PanelRendererPropTypes;

export { SimOnlyDealPanel };
