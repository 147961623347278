import React, { useContext } from 'react';
import { Directions } from '../Carousel/Carousel.props';
import { Context } from './CarouselItems';

export function Controls(): JSX.Element {
	const { state, dispatch } = useContext(Context);

	const { itemCount, active, hideControls } = state;

	const disablePrevious = active?.includes(0) ? 'disabled' : '';
	const disableNext = active?.includes(itemCount - 1) ? 'disabled' : '';

	if (hideControls) return <></>;

	return (
		<div className="controls ">
			<div
				className={`left-arrow ${disablePrevious}`}
				aria-hidden={true}
				onClick={() => {
					dispatch({ type: Directions.Previous });
				}}
			></div>
			<ul className="dots">
				{[...Array(itemCount)].map((i, index) => {
					const isActive = active?.includes(index);
					return (
						<li
							key={index}
							aria-hidden={true}
							className={isActive ? 'active' : 'inactive'}
							onClick={() => {
								dispatch({ goto: index });
							}}
						></li>
					);
				})}
			</ul>
			<div
				className={`right-arrow ${disableNext}`}
				aria-hidden={true}
				onClick={() => {
					dispatch({ type: Directions.Next });
				}}
			></div>
		</div>
	);
}
