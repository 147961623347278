import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Row, Column } from '@ao-internal/react-components';
import { Deal as DealType } from 'components/types';
import { DealContext, DealPropType } from '.';
import { LinkIcon } from 'components/Common/Icons/LinkIcon';
import { CashbackIcon } from 'components/Common/Icons/CashbackIcon';

import { format, addMonths, addDays } from 'date-fns';

import './Cashback.scss';
import { getDealIsAutoCashback } from './selectors';

const claimMonthsOn24MonthContract = [6, 9, 12, 15, 18];
const claimMonthsOn12MonthContract = [4, 6, 8, 10, 12];

function CashbackModalContent({ deal }: { deal: DealType }) {
  const {
    tariff,
    cashback,
    cashbackDisplay,
    monthlyBeforeCashback,
    monthlyAfterCashback,
    monthlyAfterCashbackDisplay,
  } = deal;

  const instalments = 5;

  const cashbackMonthlySavings = (
    monthlyBeforeCashback - monthlyAfterCashback
  ).toFixed(2);

  // 18 and 24 month contracts have the same claim month
  const claimMonths =
    tariff.length === 12
      ? claimMonthsOn12MonthContract
      : claimMonthsOn24MonthContract;

  // Assuming contract starts tomorrow
  const claimItems = claimMonths.map(monthNumber => ({
    date: addDays(addMonths(new Date(), monthNumber), 1),
    claimAmount: (cashback / 5).toFixed(2),
  }));

  const autoCashbackDeal = getDealIsAutoCashback(deal);

  const content = autoCashbackDeal ? (
    <>
      {cashbackDisplay} will be credited directly into your bank account 90 days
      from connection, provided by Mobile Phones Direct. There is no need to
      claim and we will email you to let you know when the money is on its way.
    </>
  ) : (
    <>
      <p className="u-fz--title-small u-mb--large">
        <b>
          The cashback is claimable in {instalments} instalments during your{' '}
          {tariff.length} month contract, these are shown below.
        </b>
        <br />
        Dont worry if it’s a little confusing, futher instructions on how to
        claim will be provided with your order.
      </p>
      <table
        className="mpd-cashback-content__table u-fz--title-small"
        style={{ width: '100%' }}
      >
        <thead>
          <tr>
            <th className="u-ta--left u-pb--micro">When you claim</th>
            <th className="u-ta--right u-pb--micro">Claim amount</th>
          </tr>
        </thead>
        <tbody>
          {claimItems.map(({ claimAmount, date }, index) => (
            <tr key={index}>
              <td>Bill dated {format(date, 'LLLL yyyy')}</td>
              <td className="u-ta--right">&pound;{claimAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      <Row
        className="mpd-cashback-content u-fx--nowrap"
        style={{ backgroundColor: '#5F83D3', margin: '-20px -20px 0 -20px' }}
      >
        <Column
          className="u-d--none u-d--flex--bp-medium u-jc--center u-ai--center u-py--medium u-px--xlarge u-pr--none"
          size={2}
        >
          <CashbackIcon colour="#fff" />
        </Column>
        <Column
          size={12}
          medium={10}
          className="u-pt--small u-py--medium--bp-medium u-px--medium u-px--xlarge--bp-medium u-d--flex u-ai--center"
        >
          <div className="mpd-cashback-content__includes u-py--small u-px--large u-p--none--bp-medium">
            <h3 className="u-font-group--display u-fz--base u-my--xsmall u-mt--none u-c--inverted">
              This deal includes {cashbackDisplay}{' '}
              {autoCashbackDeal ? 'Automatic' : ''} Cashback
            </h3>

            <p className="u-fz--title-small u-c--inverted">
              Cashback will be provided by Mobile Phones Direct which will
              reduce the standard monthly line rental by &pound;
              {cashbackMonthlySavings} per month when divided over the{' '}
              {tariff.length} month term of the network contract, effectively
              reducing your line rental to {monthlyAfterCashbackDisplay} per
              month.
            </p>
          </div>
        </Column>
      </Row>
      <Row>
        <Column
          className="u-d--none u-d--flex--bp-medium u-jc--center u-ai--flex-start u-py--large u-px--xlarge u-pr--none"
          size={2}
        ></Column>
        <Column
          size={12}
          medium={10}
          className="u-py--large u-px--medium u-px--xlarge--bp-medium"
        >
          {content}
          <p className="u-font-group--caption u-my--medium">
            Cashback will become void for customers who disconnect from the
            network prior to their payment being made.
          </p>
        </Column>
      </Row>
    </>
  );
}
CashbackModalContent.propTypes = {
  deal: DealPropType,
};

function Cashback({
  openModalWithDeal,
}: {
  openModalWithDeal: ({ deal, Renderer, title }) => void;
}) {
  const { deal }: { deal: DealType } = useContext(DealContext);

  const { cashbackDisplay, monthlyAfterCashbackDisplay } = deal;

  function trackCashbackPillClicked() {
    globalThis.dataLayer.push({
      event: 'Cashback Panel Clicked',
      eventCategory: 'Cashback Panel Clicked',
      eventAction: window.MPD.Analytics.pageType,
      eventLabel: window.location.href,
    });
  }

  const autoCashbackDeal = getDealIsAutoCashback(deal);

  return (
    <Column size={12} className="u-px--micro u-py--micro">
      <button
        className="c-btn--deal-feature-button u-d--flex u-font-group--display u-fw--600 u-ta--left u-w--100 u-px--tiny u-py--micro"
        style={{ backgroundColor: '#5F83D3' }}
        onClick={() => {
          openModalWithDeal({
            deal,
            Renderer: CashbackModalContent,
            title: 'Cashback',
          });
          trackCashbackPillClicked();
        }}
        aria-label={`Includes ${cashbackDisplay} ${
          autoCashbackDeal ? 'Automatic' : ''
        } Cashback`}
      >
        <div className="u-my--auto u-pl--micro">
          <span
            className="u-fw--600 u-c--inverted"
            style={{ lineHeight: '16px', fontSize: '11px' }}
          >
            Includes {cashbackDisplay} {autoCashbackDeal ? 'Automatic' : ''}{' '}
            Cashback
            <br />
          </span>
          <span className="u-fw--400 u-c--inverted u-font-group--caption">
            Effectively reducing your line rental to{' '}
            {monthlyAfterCashbackDisplay}
          </span>
        </div>
        <LinkIcon
          colour="#ffffff"
          className="u-ml--auto u-my--auto u-mr--micro"
        />
      </button>
    </Column>
  );
}
Cashback.propTypes = {
  openModalWithDeal: PropTypes.func.isRequired,
};

export { Cashback, CashbackModalContent };
