/**
 * Generic deal title
 *
 * Displays a sub title before main title on SIM Only deals
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DealContext, getDealTitle, getDealSubtitle } from '.';
import { Deal } from 'components/types';
import { trackProductClick } from 'components/Common/analytics';
import { getShortDealTitle } from './selectors';
import { DealBadge } from './DealBadge';
import { getHandsetLink } from 'components/Common/Deals/helpers';

function DealTitle({ short = false }) {
	const {
		deal,
		position,
		list,
	}: { deal: Deal; position: number; list: any } = useContext(DealContext);

	const title = short ? getShortDealTitle(deal) : getDealTitle(deal);
	const subtitle = getDealSubtitle(deal);
	const handsetLink = getHandsetLink(deal, globalThis.Router);
	const hasPlacementLabel = deal.variant && deal.variant.handset.placement;

	const onClick = event => {
		trackProductClick(deal, position, list, handsetLink);
	};

	return (
		<a href={handsetLink} onClick={onClick}>
			{subtitle && (
				<div className="u-font-group--title u-fz--base-small u-fw--500 u-py--none">
					{subtitle}
					{hasPlacementLabel && (
						<DealBadge
							className="u-pos--relative"
							style={{
								backgroundColor:
									deal.variant.handset.placement.labelColour,
								top: '-1px',
								marginLeft: '4px',
								padding: '2px 6px',
								borderRadius: '2px',
								fontSize: '10px',
								whiteSpace: 'nowrap',
							}}
						>
							{deal.variant.handset.placement.label}
						</DealBadge>
					)}
				</div>
			)}
			<h3>{title}</h3>
		</a>
	);
}
DealTitle.propTypes = {
	/**
	 * @param short
	 * Whether to use short deal title. Default false
	 */
	short: PropTypes.bool,
};

export { DealTitle };
