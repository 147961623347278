/**
 * Deal selectors
 */

import {
	Deal as DealType,
	Colour as ColourType,
	Tariff as TariffType,
	Order as OrderType,
	Basket,
} from 'components/types';
import { urlToRelative } from 'components/Common/url-to-relative';
import { isBefore } from 'date-fns';
import { HandsetCondition } from 'components/Handset/Conditions';
import { sl } from 'date-fns/locale';

/**
 * Returns true if deal has cashback
 *
 * @param deal cloud9 deal
 */
export const getHasCashback = ({ cashback }: DealType | Basket) => cashback > 0;

/**
 * Gets monthly cost of tarrif after cashback has been applied
 *
 * @param deal cloud9 deal
 */
export const getLineRentalAfterCashback = ({
	monthlyAfterCashbackDisplay,
}: DealType) => monthlyAfterCashbackDisplay;

/**
 * Gets upfront amount from deal
 */
export const getUpfrontAmount = ({ upfrontDisplay }: DealType) =>
	upfrontDisplay;
/**
 * Gets and formats tariff line rental from deal
 */
export const getTariffLineRental = ({ tariff }: DealType) =>
	tariff && tariff.lineRentalDisplay;

/**
 * Returns true if there is a handset promo
 */
export const getHasPromo = ({ variant, tariff }: DealType) => {
	return (
		(tariff && tariff.promo) ||
		(variant && variant.handset.promos && variant.handset.promos[0])
	);
};

export const getHasBundle = ({ bundle }: DealType) => bundle;

const simoSlugs = ['sim-only', 'sim-only-upgrade', 'payg'];
export const isSimo = (tariff: TariffType | undefined): boolean => {
	if (tariff) {
		return simoSlugs.includes(tariff.type.slug);
	}

	return false;
};

const upgradeSlugs = ['upgrade', 'sim-only-upgrade'];
export const isUpgrade = (tariff: TariffType | undefined): boolean => {
	if (tariff) {
		return upgradeSlugs.includes(tariff.type.slug);
	}

	return false;
};

export const getDealTitle = ({ variant, tariff }: DealType) =>
	isSimo(tariff)
		? tariff.type.name
		: `${variant.handset.model} ${variant.capacity.name}`;

export const getShortDealTitle = ({ variant, tariff }: DealType) =>
	isSimo(tariff) ? tariff.type.name : `${variant.handset.model}`;

export const getDealSubtitle = ({ tariff, variant }: DealType) =>
	isSimo(tariff)
		? `${tariff.network.name} ${tariff.length} Month`
		: variant.handset.manufacturer.name;

export const getDealSlug = ({ tariff, variant }: DealType) =>
	isSimo(tariff) ? tariff.slug : variant.handset.slug;

export const getDealType = ({ tariff }: DealType): string =>
	tariff ? tariff.type.slug : 'sim-free';

export const getHandsetLink = (
	deal: DealType,
	extraProps: Record<string, unknown> = {}
) => {
	const routeParams = {
		// @ts-expect-error TODO
		...extraProps.handsetLink,
	};
	let routeName = 'handset.show';

	// SIMO
	if (!deal.variant) {
		return deal.basketLink;
	}

	if (deal.variant) {
		routeParams.manufacturer_slug = deal.variant.handset.manufacturer.slug;
		routeParams.model_slug = deal.variant.handset.modelSlug;

		if (deal.tariff && deal.tariff.type.slug === 'upgrade') {
			routeParams.type = 'upgrade';
			if (
				deal.variant.handset.conditionId ===
				HandsetCondition.Refurbished
			) {
				routeParams.refurbished = 1;
			}
		}

		if (!deal.tariff) {
			routeParams.type = 'sim-free';
		}
	}

	if (deal.bundle) {
		routeParams.bundleSlug = deal.bundle.slug;
		routeName = 'handset.with-bundle.show';
	}

	if (
		deal.variant.handset.conditionId === HandsetCondition.Refurbished &&
		deal.tariff &&
		deal.tariff.type.slug !== 'upgrade'
	) {
		routeParams.type = 'refurbished';
	}

	if (
		deal.variant.capacity.slug !== deal.variant.handset.defaultCapacity.slug
	) {
		routeParams.capacity = deal.variant.capacity.slug;
	}
	if (deal.variant.colour.slug !== deal.variant.handset.defaultColour.slug) {
		routeParams.colour = deal.variant.colour.slug;
	}

	return urlToRelative(globalThis.Router(routeName, routeParams).toString());
};

/**
 * Get default capacity slug
 */
export const getDefaultCapacitySlug = ({ variant }: DealType) =>
	variant.handset.defaultCapacity.slug;

export const getColourByIndex = (
	{ variant }: DealType,
	index: number
): ColourType => variant.handset.colours[index];

/**
 * Returns true if deal is a sim only deal
 */
export const getDealIsSimOnly = (deal: DealType) =>
	simoSlugs.includes(deal.type) ||
	(deal.tariff && simoSlugs.includes(deal.tariff.type.slug));

/**
 * Returns true if order is a sim only deal
 */
export const getOrderIsSimOnly = ({ tariff }: OrderType | DealType) =>
	tariff && simoSlugs.includes(tariff.type.slug);

/**
 * Returns true if deal is a promo
 */
export const getDealIsPromo = ({
	tariff: { dataPromo },
}: DealType | OrderType) => {
	return dataPromo !== 0;
};

export const getDealBuyLinkByColour = (
	deal: DealType,
	targetColour: ColourType,
	extraProps
) => {
	let propType = '';
	if (extraProps.analyticsPropType === 'active') {
		propType = '?prop-type=active';
	}
	if (!deal.variant || !deal.variant.handset.deals) {
		return `/basket/add/${deal.basketHash}${propType}`;
	}

	return deal.variant.handset.deals[deal.tariff.type.slug][targetColour.slug][
		deal.variant.capacity.slug
	][0].basketLink;
};

export const getDealBuyLinkByColourHMC = (
	deal: DealType,
	targetColour: ColourType,
	extraProps
) => {
	let propType = '';
	if (extraProps.analyticsPropType === 'active') {
		propType = '?prop-type=active';
	}
	if (!deal.variant || !deal.variant.handset.deals) {
		return `/basket/add/${deal.basketHash}${propType}`;
	}

	if (deal.variant.handset.deals[deal.tariff.type.slug])
		return deal.variant.handset.deals[deal.tariff.type.slug][
			targetColour.slug
		][deal.variant.capacity.slug][0].basketLink;

	return deal.variant.handset.deals['free-from'][deal.tariff.type.slug][
		targetColour.slug
	][deal.variant.capacity.slug][0].deal.basketHash;
};

/**
 * Returns true if the variant on the deal has launched
 */
export const getVariantLaunched = (deal: DealType) => {
	return (
		getDealIsSimOnly(deal) ||
		deal.type === 'generic' ||
		(deal.variant && deal.variant.launched)
	);
};

/**
 * Returns a launch status string based on the given launchAt date
 *
 * @param launchAt a launch date
 */
export type LaunchStatus = 'launched' | 'pre-order' | 'new';
export const getLaunchStatus = (launchAt): LaunchStatus => {
	if (!launchAt) return 'launched';

	const launchDate = new Date(launchAt);

	if (isBefore(new Date(), launchDate)) {
		return 'pre-order';
	}
	return 'launched';
};

export const getVariantLaunchStatus = (deal): LaunchStatus => {
	if (!deal.variant) return 'launched';
	return getLaunchStatus(deal.variant.launchAt);
};

export const getHandsetFamilyLaunchStatus = (handset): LaunchStatus => {
	if (!handset.family) return 'launched';
	return getLaunchStatus(handset.family.launchAt);
};

const AUTO_CASHBACK_DEAL_TYPE_ID = 2;
export const getDealIsAutoCashback = (deal: DealType) =>
	deal.dealTypeId === AUTO_CASHBACK_DEAL_TYPE_ID;
