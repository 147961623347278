import { addDays, formatDuration, intervalToDuration } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useStockContext } from './useStockContext';
import { isDateBetweenBreak } from 'helpers/utils';

export function Timer({ delayed }: { delayed?: string }): JSX.Element {
	const { cutOff, isNextDay, inStock } = useStockContext();
	const [timeLeftForNextDay, setTimeLeftForNextDay] = useState<string | null>(
		null
	);

	const isBetweenBreak = isDateBetweenBreak();

	useEffect(() => {
		const intervalId = setInterval(() => {
			const passedCutoff = new Date() > cutOff;
			const durationTillCutoff = intervalToDuration({
				start: new Date(),
				end: passedCutoff ? addDays(cutOff, 1) : cutOff,
			});

			setTimeLeftForNextDay(
				formatDuration(durationTillCutoff, {
					format: ['days', 'hours', 'minutes'],
				})
			);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [cutOff]);

	if (!inStock && !isBetweenBreak) {
		return (
			<span className="StockMessage--Timer StockMessage--DelayedMessage">
				{delayed}
			</span>
		);
	}

	return (
		<span className="StockMessage--Timer">
			Order within {timeLeftForNextDay}
		</span>
	);
}
