import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { DealContext, TariffExtrasModalContent } from '../';
import { Deal as DealType } from 'components/types';
import { Image } from 'components/Common/Image/';
import { trackProductView } from 'components/Common/analytics';
function TariffExtrasList({
	size = '20px',
	spacing = 'micro',
	openModalWithDeal = null,
}) {
	const { deal }: { deal: DealType } = useContext(DealContext);

	function openModal() {
		if (openModalWithDeal) {
			openModalWithDeal({
				deal,
				Renderer: TariffExtrasModalContent,
				title: 'Plan Details',
			});
			trackProductView(deal, 'tariff');
		}
	}

	return (
		<div
			role="button"
			tabIndex={0}
			onKeyPress={openModal}
			onClick={openModal}
			className="u-d--inline-block"
			aria-hidden={true}
		>
			<ul
				className="u-reset-list u-my--micro"
				style={openModalWithDeal ? { cursor: 'pointer' } : {}}
			>
				{deal.tariff.extras.map(({ title, slug }, index) => (
					<li key={index} className="u-d--inline-block">
						<div
							style={{ height: size }}
							className={`u-mx--${spacing}`}
						>
							<Image
								alt={title}
								path={`/images/tariff/extras/${slug}.png`}
							/>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
}
TariffExtrasList.propTypes = {
	/**
	 * @param size (optional) what size to display tariff extras tiles
	 * default "20px"
	 */
	size: PropTypes.string,

	/**
	 * @param spacing (optional) strata spacing between extras. Default "tiny"
	 */
	spacing: PropTypes.string,
};

export { TariffExtrasList };
