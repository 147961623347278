import React from 'react';
import classnames from 'classnames';

import { LinkIcon } from 'components/Common/Icons/LinkIcon';
import { InfoIconFill } from 'components/Common/Icons/InfoIconFill';

function AsNewLabel({ deal, openModalWithDeal, className = '' }) {
	return (
		<div
			className={classnames(className, {
				'deal-box-refurb-label': true,
				'deal-box-refurb-label-as-new': true,
				'u-px--none u-py--none': true,
			})}
		>
			<button
				style={{
					fontSize: 'inherit',
					background: 'transparent',
					cursor: 'pointer',
				}}
				className="u-px--tiny u-px--large--bp-medium u-py--tiny u-fw--600 u-w--100"
				onClick={e => {
					e.preventDefault();
					openModalWithDeal({
						deal,
						Renderer: RefurbInfoModal,
						title: 'What’s an As New phone?',
					});
				}}
			>
				<InfoIconFill colour="#042B54" />
				<span>This is an As New Handset</span>
				<LinkIcon colour="#042B54" />
			</button>
		</div>
	);
}

function RefurbInfoModal() {
	return (
		<div className="u-px--small u-px--large--bp-medium u-py--xsmall">
			<p className="u-fz--title-small u-mb--medium u-mt--micro">
				These are handsets which have been bought in from 3rd parties.
				Whether it&apos;s Apple or Samsung, they are repaired officially
				by the manufacturer, so you know they are in tip-top condition.
			</p>
		</div>
	);
}

export { AsNewLabel };
