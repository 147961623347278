export enum Flags {
	JestTest = 'jest-test', // Keep for unit testing
	JestTestString = 'jest-test-string', // Keep for unit testing
	HelpMeChoose = 'help-me-choose',
	InboundCalls = 'inbound-calls',
	InboundCallsPhoneNumber = 'inbound-calls-phone-number',
	Roundel = 'roundel',
	Filters = 'filters-v2',
	CheckoutCta = 'checkout-cta-a-b-c',
	StickyBannerVersion = 'sticky-banner-version',
	SalesPhoneNumber = 'sales-phone-number',
	MerchPodSpace = 'merch-pod-space',
	DealTerms = 'deal-terms',
	AxiosRefactor = 'axios-refactor',
	Esim = 'eSIM',
	HomepageBannerVariant = 'homepage-banner-variant',
	SaveBasket = 'save-basket',
	basketPopover = 'basket-popover',
	handsetHeroRedesign = 'handset-hero-redesign',
}
