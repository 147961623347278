import { PropsWithChildren } from 'react';
import { Flags } from 'services/LaunchDarkly/flags';
import { LaunchDarklyVariant } from './LaunchDarkly.Variant';

interface LaunchDarklyBooleanProps {
	flag?: Flags;
	value: boolean;
}

type LaunchDarklyBoolean = (
	props: PropsWithChildren<LaunchDarklyBooleanProps>
) => JSX.Element;

export const LaunchDarklyBoolean: LaunchDarklyBoolean = LaunchDarklyVariant;
