import React, { useContext } from 'react';

import { DealContext } from '.';

import { Basket, Deal } from 'components/types';
import { NEW_COLOUR, PRE_ORDER_COLOUR } from 'components/Common/PreOrder';
import { getVariantLaunchStatus, isUpgrade } from './selectors';
import { BasketContext } from 'components/Basket/BasketContext';
import Context from 'components/Checkout/Context';

function TariffFeatureBadges({ showNewAndPreOrder = false }) {
	const { deal }: { deal: Deal } = useContext(DealContext);

	const basketCtx = useContext<{ basket: Basket }>(BasketContext);
	const checkoutCtx = useContext<{ basket: Basket }>(Context);
	const isEsim =
		(basketCtx && basketCtx.basket.isEsim) ||
		(checkoutCtx && checkoutCtx.basket.isEsim);

	const { tariff } = deal;

	const features = [];

	const is5G = tariff.dataSpeed === '5G';
	const tariffLength = tariff.length;
	const isUpgradeDeal = isUpgrade(tariff);
	const doubleData = tariff.data === Number(tariff.dataPromo) / 2;
	// 5G Ready
	// 24 Month
	// Upgrade
	// eSIM
	// Double data
	// New/Pre-Order

	features.push({
		title: tariffLength === 1 ? '30 Day' : `${tariffLength} Month`,
		backgroundHex: '#5B6770',
	});
	is5G && features.push({ title: '5G Ready', backgroundHex: '#5B6770' });
	isUpgradeDeal &&
		features.push({ title: 'Upgrade', backgroundHex: '#5B6770' });
	isEsim && features.push({ title: 'eSIM', backgroundHex: '#5B6770' });
	doubleData &&
		features.push({
			title: 'Double Data',
			backgroundHex: '#10377B',
		});

	const launchStatus = getVariantLaunchStatus(deal);

	showNewAndPreOrder &&
		launchStatus !== 'launched' &&
		features.push({
			title: launchStatus === 'new' ? 'New' : 'Pre-Order',
			backgroundHex:
				launchStatus === 'new' ? NEW_COLOUR : PRE_ORDER_COLOUR,
		});

	return (
		<div className="u-ta--right u-mt--micro tariff-feature-badges">
			<ul className="u-font-group--caption u-p--none u-m--none u-lst--none">
				{features.map(({ title, backgroundHex }, index) => (
					<li key={index} className="u-mb--micro u-ta--center">
						<div
							className="u-p--micro u-c--inverted u-d--inline-block u-w--100 u-fw--600"
							style={{
								background: backgroundHex,
								borderRadius: '4px',
								maxWidth: '56px',
								minWidth: '54px',
							}}
						>
							{title}
						</div>
					</li>
				))}
			</ul>
		</div>
	);
}

export { TariffFeatureBadges };
