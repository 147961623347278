import { Basket } from 'components/types';

export const getActiveInsurance = (basket: Basket) => {
  if (basket.hasInsurance) {
    return basket.variant.insurance.find(
      ({ id }) => basket.activeInsuranceId === id
    );
  }
  return null;
};
