import * as React from 'react';
import type { PropsWithChildren, MutableRefObject } from 'react';

interface ScrollPercentageContextType {
	scrollPercentageRef: MutableRefObject<number>;
	onScrollStop: (cb: (percentageScrolled: number) => void) => void;
}

const ScrollPercentageContext = React.createContext<ScrollPercentageContextType | null>(
	null
);

export const ScrollPercentageProvider = ({
	children,
	containerId,
}: PropsWithChildren<{ containerId: string }>) => {
	// Shouldn't be rendered anywhere, only used for tracking.
	// Should be able to get away with a ref, instead of using state (avoid unecessary re-renders)
	const scrollPercentageRef = React.useRef<number>(0);
	const callbacksRef = React.useRef([]);

	React.useEffect(() => {
		const container = document.getElementById(containerId);
		if (!container) {
			console.error(
				`Container with id ${containerId} not found. Scroll listener won't start.`
			);
		}

		function handleScroll() {
			const currentScrollOffset = Math.floor(
				window.scrollY - container.offsetTop
			);
			const percentageScrolled = Math.max(
				Math.floor(
					(currentScrollOffset / container.offsetHeight) * 100
				),
				0
			);
			callbacksRef.current.forEach(cb => cb(percentageScrolled));
			scrollPercentageRef.current = percentageScrolled;
		}

		if (container) {
			window.addEventListener('scrollend', handleScroll);
		}

		return () => {
			// send last known scroll before unmounting
			handleScroll();
			window.removeEventListener('scrollend', handleScroll);
		};
	}, [containerId]);

	return (
		<ScrollPercentageContext.Provider
			value={{
				scrollPercentageRef,
				onScrollStop: cb => callbacksRef.current.push(cb),
			}}
		>
			{children}
		</ScrollPercentageContext.Provider>
	);
};

export const useScrollPercentageContext = () => {
	const ctx = React.useContext(ScrollPercentageContext);

	if (!ctx) {
		throw new Error(
			'useScrollPercentageContext must be used within a ScrollPercentageProvider'
		);
	}

	return ctx;
};
