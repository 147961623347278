import React, { CSSProperties } from 'react';

import { Row, Column } from '@ao-internal/react-components';

import {
	PanelRendererPropTypes,
	DealTitle,
	DealVariant,
	PlanIncludes,
	TariffFeatureBadges,
	Cashback,
	Promo,
	NetworkLogo,
	ViewPlanDetails,
	getHasCashback,
	getHasPromo,
	Bundle,
	getHasBundle,
} from 'components/Common/Deals/DealPanel';

import { Deal as DealType } from 'components/types';
import { SimoImage } from 'components/Common/Deals/DealPanel/SimoImage';

type DealPanelPropsType = {
	deal: DealType;
	openModalWithDeal: () => void;
};

function DefaultDealPanel({ openModalWithDeal, deal }: DealPanelPropsType) {
	const showCashback = getHasCashback(deal);
	const showPromo = getHasPromo(deal);
	const showBundle = getHasBundle(deal);

	const shouldSetSpecialBfColour =
		deal.promo && deal.promo.primaryHex.toLowerCase() === 'ff39ef';

	return (
		<div
			className={`deal-panel-inner u-d--flex u-fxw--wrap u-h--100 u-pb--small u-mb--micro ${
				shouldSetSpecialBfColour ? 'deal-panel-inner-bf-special' : ''
			}`}
		>
			<div
				style={{ alignItems: 'flex-start' }}
				className="u-w--100 deal-panel-inner-info"
			>
				<Row className="u-px--xsmall u-pt--xsmall">
					<Column
						size={9}
						className="u-pl--small u-pt--small u-pr--none"
					>
						<DealTitle />
					</Column>
					<Column
						size={3}
						className="u-d--flex u-p--none u-jc--flex-end u-pos--relative"
					>
						<div className="u-pos--absolute">
							<TariffFeatureBadges showNewAndPreOrder={true} />
						</div>
					</Column>
				</Row>

				<Row className="u-px--small u-pb--micro">
					<Column
						size={5}
						className="u-pt--xsmall u-mt--tiny u-pl--xsmall"
					>
						{deal.variant ? (
							<DealVariant
								// @ts-expect-error TODO
								colours="deal"
							/>
						) : (
							<SimoImage
								networkSlug={deal.tariff.network.slug}
								className="u-d--block"
								height="162"
								alt="SIM Card"
							/>
						)}
					</Column>
					<Column size={7} className="u-pt--none u-pl--small">
						<Row>
							<Column size={12} className="u-pt--large">
								<NetworkLogo />
								<PlanIncludes />
							</Column>
						</Row>
						<Row>
							<Column>
								{deal.tariff.extras.length > 0 && (
									<>
										<ViewPlanDetails
											openModalWithDeal={
												openModalWithDeal
											}
										/>
									</>
								)}
							</Column>
						</Row>
					</Column>
				</Row>
			</div>

			<Row className="u-px--medium u-mt--auto u-py--micro">
				{showPromo && <Promo openModalWithDeal={openModalWithDeal} />}
				{showCashback && (
					<Cashback openModalWithDeal={openModalWithDeal} />
				)}
				{showBundle && <Bundle openModalWithDeal={openModalWithDeal} />}
			</Row>
		</div>
	);
}
DefaultDealPanel.propTypes = PanelRendererPropTypes;

export { DefaultDealPanel };
