import React from 'react';
import { Deal as DealType } from 'components/types';

import { Delivery } from './delivery';
import { Truck } from 'components/Common/Icons';
import './DealStockMessage.scss';
import { add, format } from 'date-fns';
import { getDeliveryDateDuringPeriod, isDateBetweenBreak } from 'helpers/utils';

const NO_STOCK_NAMES = ['none', 'pre'];

export function DealStockMessage({ deal }: { deal: DealType }): JSX.Element {
	const delivery = new Delivery(deal);

	if (delivery.die) return <></>;

	const stockMessages = {
		good: 'In Stock',
		low: 'In Stock',
		none: 'Delayed Delivery',
		pre: 'Pre-Order',
	};

	const isNextDay = delivery.getIsNextDayDelivery();
	const estimatedDelivery = delivery.getEstimatedDeliveryDateFormatted();
	const inStock = !NO_STOCK_NAMES.includes(deal.variant.stock) || isNextDay;

	let stockMessage = stockMessages[deal.variant.stock];
	if (deal.variant.stock === 'none' && isNextDay)
		stockMessage = stockMessages['low'];

	let deliveryMessage: string;
	if (isNextDay) deliveryMessage = 'Next Day Delivery';
	if (!inStock) deliveryMessage = estimatedDelivery + ' delivery';

	const isDateBetweenSwitchover = isDateBetweenBreak();

	if (isDateBetweenSwitchover) {
		if (inStock) {
			deliveryMessage =
				'Delivered from the ' +
				getDeliveryDateDuringPeriod().formattedDate;
		} else if (estimatedDelivery.includes('weeks')) {
			deliveryMessage = `Delivered in ${estimatedDelivery}`;
		} else if (!estimatedDelivery.includes('days')) {
			deliveryMessage = `Delivered from the ${estimatedDelivery}`;
		}
	}

	return (
		<div className="dealStockMessage o-flex-container u-jc--center u-ai--center">
			<Truck fill="#044058" />
			<div className="text u-ml--tiny">
				<strong>{stockMessage}</strong>
				<span>{deliveryMessage}</span>
			</div>
		</div>
	);
}
