import React from 'react';

function Tick({ colour, ...props }) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99987 7.59999C2.86596 7.60344 2.73627 7.553 2.63987 7.45999L0.149869 4.99999C0.0248259 4.87495 -0.024009 4.6927 0.0217599 4.52189C0.0675288 4.35107 0.200948 4.21765 0.37176 4.17189C0.542572 4.12612 0.724826 4.17495 0.849869 4.29999L2.99987 6.39999L8.80987 0.579995C8.9367 0.453166 9.12156 0.403633 9.29481 0.450056C9.46806 0.496479 9.60338 0.631804 9.64981 0.805056C9.69623 0.978308 9.6467 1.16317 9.51987 1.28999L3.34987 7.45999C3.25654 7.55147 3.13054 7.60187 2.99987 7.59999Z"
        fill={colour}
      />
    </svg>
  );
}
export { Tick };
