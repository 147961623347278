import React from 'react';
import { SalesPhoneNumber } from '../ContactInformation';

interface StickyBannerSkinnyProps {
	isOutOfHours?: boolean;
}

export function StickyBannerSkinny({
	isOutOfHours,
}: StickyBannerSkinnyProps): JSX.Element {
	const handleClick = (): void => {
		globalThis.dataLayer.push({
			event: 'SkinnyMobilePropositionBannerClicked',
			eventCategory: 'Skinny Mobile Proposition Banner Click',
			eventAction:
				'Mobile Proposition Banner Click - Dial Contact Number',
		});
	};

	return (
		<SalesPhoneNumber
			className={`HeaderBanner HeaderBanner--Skinny HeaderBanner--Sticky ${
				isOutOfHours ? 'HeaderBanner--OOH' : ''
			}}`}
			onClick={handleClick}
			id="mobileCallUsBanner"
		>
			<>
				<span className="ico ico-call"></span>
				Buy quicker and easier over the phone. Tap to call free.
			</>
		</SalesPhoneNumber>
	);
}
