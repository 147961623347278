import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Column, Row } from '@ao-internal/react-components';
import { Bundle as BundleType, Deal as DealType } from 'components/types';
import { DealContext, getHasBundle } from '.';
import { Image } from 'components/Common/Image';
import { LinkIcon } from 'components/Common/Icons/LinkIcon';

import './Promo.scss';

function BundleModalContent({ bundle }: { bundle: BundleType }) {
	return (
		<>
			<div className="promo-background-inner u-c--inverted u-p--medium u-px--giant--bp-medium u-py--large--bp-medium">
				<Row className="u-fxw--wrap-reverse u-ai--center">
					<Column size={12} medium={8}>
						<h2 className="u-fw--600 u-c--inverted u-mb--small">
							{bundle.name}
						</h2>
					</Column>
					<Column
						size={12}
						medium={4}
						className="u-ta--center u-my--tiny"
					>
						<Image
							path={bundle.image}
							alt={bundle.name}
							cdnOptions={{
								height: 250,
								width: 250,
								fit: 'contain',
							}}
						/>
					</Column>
				</Row>
			</div>
			<Row className="u-pt--xlarge u-pb--large u-px--xlarge--bp-medium u-px--tiny">
				<Column>
					<h3 className="u-font-group--display u-fz--base u-mb--small">
						When will I receive my bundle?
					</h3>
					<div className="promo-how-to-claim u-fz--title-small">
						We’ll send your bundle 14 days after you’ve got your
						phone, so don’t panic when one arrives before the other.
						Subject to stock availability, other terms may apply.
					</div>
				</Column>
			</Row>
		</>
	);
}

function BundleButton({
	bundle,
	onClick,
	className = '',
	isOnProductPage = false,
	...props
}) {
	const buttonText = `Bundle: Includes ${bundle.shortName}`;

	const textClassnames = classnames('u-my--auto', {
		'u-fz--pill-small': isOnProductPage,
	});

	return (
		<button
			className={`u-font-group--display u-fw--600 u-px--tiny u-w--100 u-ta--left ${className}`}
			style={{ paddingTop: '5px', paddingBottom: '5px' }}
			onClick={onClick}
			aria-label={buttonText}
			{...props}
		>
			<div className="u-d--flex">
				<div
					className="u-d--inline-block u-my--auto u-ml--micro u-mr--tiny"
					style={{ height: '27px' }}
				>
					<Image path={bundle.image} alt={bundle.name} />
				</div>
				<span className={textClassnames}>{buttonText}</span>
				<div className="u-ml--auto u-mr--micro u-d--flex">
					<LinkIcon colour="#fff" className="u-my--auto" />
				</div>
			</div>
		</button>
	);
}

function Bundle({ openModalWithDeal }) {
	const { deal }: { deal: DealType } = useContext(DealContext);

	const bundle = getHasBundle(deal);
	return (
		<Column className="u-px--micro u-py--micro">
			<BundleButton
				className="c-btn--deal-feature-button handset-bundle-button u-fz--base-small"
				bundle={bundle}
				onClick={() =>
					openModalWithDeal({
						deal,
						Renderer: () => BundleModalContent({ bundle }),
						title: 'Included Bundle',
					})
				}
			/>
		</Column>
	);
}
Bundle.propTypes = {
	openModalWithDeal: PropTypes.func.isRequired,
};

export { Bundle, BundleButton, BundleModalContent };
