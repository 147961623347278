import React, { useRef } from 'react';
import classnames from 'classnames';
import { InferProps } from 'prop-types';

import { Button } from 'components/Common/Button';
import { Row, Column } from '@ao-internal/react-components';
import { useScreenSize } from 'components/Common/use-screen-size';

import {
	PanelRendererPropTypes,
	PlanIncludes,
	TariffFeatureBadges,
	Cashback,
	NetworkLogo,
	getHasCashback,
	ViewPlanDetails,
	TariffExtrasList,
	getUpfrontAmount,
	getVariantLaunched,
} from 'components/Common/Deals/DealPanel';

import { TotalCostOwnershipLabel } from 'components/Common/Deals/DealPanel/TotalCostOwnershipLabel';

import { Deal as DealType } from 'components/types';
import { RefurbLabel } from 'components/Common/Deals/DealPanel/RefurbLabel';
import { checkIsDealRefurbished } from 'helpers/utils';
import StockMessage, { Delivery, Stock } from 'components/Common/StockMessage';
import { Truck } from 'components/Common/Icons';
import { Terms } from './Terms';
import Popover from 'components/Common/Popover/Popover';
import { useCustomContext } from 'hooks/use-custom-context';

function HandsetDealPanelContent({
	deal,
	openModalWithDeal,
}: InferProps<typeof PanelRendererPropTypes> & { deal: DealType }) {
	const { screenSize } = useScreenSize();

	const isMobile =
		screenSize === 'xsmall' ||
		screenSize === 'small' ||
		screenSize === 'medium';

	const hasCashback = getHasCashback(deal);

	return isMobile ? (
		<Row className="u-p--small">
			<Column size={9} className="u-pb--none u-pl--xsmall">
				<PlanIncludes />
				{deal.tariff.extras.length > 0 && (
					<>
						<ViewPlanDetails
							openModalWithDeal={openModalWithDeal}
						/>
					</>
				)}
			</Column>
			<Column
				size={3}
				className="u-ta--center u-pl--none u-pb--none u-pr--xsmall"
			>
				<div style={{ float: 'right' }}>
					<NetworkLogo />
					<TariffFeatureBadges />
				</div>
			</Column>
			<Column size={12} className="u-pt--none">
				{hasCashback && (
					<Cashback openModalWithDeal={openModalWithDeal} />
				)}
			</Column>
		</Row>
	) : (
		<Row>
			<Column
				className="u-pl--small u-ta--center u-d--flex"
				style={{ borderRight: '1px solid #DADADA', width: '20%' }}
			>
				<div className="u-m--auto u-py--small u-px--medium--bp-large">
					<NetworkLogo />
					<TariffFeatureBadges />
				</div>
			</Column>
			<Column
				className="u-px--medium u-ai--center u-d--flex theme-handset-panel"
				style={{ width: '80%' }}
			>
				<Row>
					<Column size={6} className="u-px--xsmall u-py--medium">
						<PlanIncludes
							planFeaturesClassName="u-d--inline-block--bp-medium"
							largeFeatureSubTitle
						/>
						{hasCashback && (
							<Row className="u-mt--medium">
								<Cashback
									openModalWithDeal={openModalWithDeal}
								/>
							</Row>
						)}
					</Column>

					<Column
						size={6}
						className="u-py--none u-px--none u-my--auto u-mb--tiny u-mb--auto--bp-large"
					>
						{deal.tariff.extras.length > 0 && (
							<>
								<div className="u-ta--center u-pos--relative u-mb--large u-mb--none--bp-large">
									<TariffExtrasList
										size="28px"
										spacing="tiny"
										openModalWithDeal={openModalWithDeal}
									/>
									<div
										style={{
											position: 'absolute',
											left: 0,
											right: 0,
											bottom: '-28px',
										}}
									>
										<ViewPlanDetails
											openModalWithDeal={
												openModalWithDeal
											}
										/>
									</div>
								</div>
							</>
						)}
					</Column>
				</Row>
			</Column>
		</Row>
	);
}
HandsetDealPanelContent.propTypes = PanelRendererPropTypes;

function HandsetDealPanelFooter({
	deal,
	openModalWithDeal,
}: {
	deal: DealType;
	openModalWithDeal: () => void;
}) {
	const upfrontAmount = getUpfrontAmount(deal);

	const showCashback = getHasCashback(deal);

	const monthlyCost = deal.monthlyBeforeCashbackDisplay;

	globalThis.MPD = globalThis.MPD || {};
	const { enableTCOLabel = false } = globalThis.MPD;

	const buyLink = `/basket/add/${deal.basketHash}?prop-type=active`;
	const isRefurb = checkIsDealRefurbished(deal);

	const handsetLaunched = getVariantLaunched(deal);

	const handleButtonClick = (): boolean => {
		const { variantInBasket, tariffInBasket } = window.MPD;

		localStorage.setItem('originalBasketHash', deal.basketHash);
		const hasClickedViewMore = window.localStorage.getItem(
			'userClickedViewMoreDeals'
		);
		if (hasClickedViewMore) {
			globalThis.dataLayer.push({
				event: 'Top Deal View More Deals clickthrough',
				eventCategory: 'Top Deal',
				eventAction:
					'User added deal to basket after clicking view more deals',
				eventLabel: 'Top deal - view more deals',
			});
		}
		localStorage.removeItem('userClickedViewMoreDeals');

		return variantInBasket || tariffInBasket;
	};

	const handleConfirmClick = () => {
		window.location.href = buyLink;
	};

	return (
		<>
			<Row
				className="u-bgc--background u-my--auto u-pos--relative u-px--none"
				style={{ borderRadius: '0 0 10px 10px' }}
			>
				<Column size={12} className={'u-p--none'}>
					{isRefurb && (
						<RefurbLabel
							className="deal-box-refurb-label--handset-deal"
							openModalWithDeal={openModalWithDeal}
							deal={deal}
						/>
					)}
				</Column>
				<Column
					medium={6}
					className={classnames({
						'u-pos--relative': true,
						'u-pl--small': true,
						'u-ta--left': true,
					})}
				>
					<Row className="u-pl--tiny">
						<Column size={12} className="u-pb--none">
							<div className="u-fz--base-small">
								Upfront Cost:
							</div>
							<div
								className="u-fz--title-small u-mb--micro u-fw--600"
								style={{ lineHeight: '1' }}
							>
								{upfrontAmount}
							</div>
						</Column>
						<Column
							size={12}
							className="u-pos--relative u-py--none"
						>
							<div className="u-fz--base-small">
								Monthly Cost:
							</div>
							<div
								className="deal-panel-footer-cost u-fz--display u-mb--micro u-fw--600"
								style={{ lineHeight: '1' }}
							>
								{monthlyCost}
							</div>
						</Column>
					</Row>
				</Column>
				<Column medium={6} className="u-py--small u-pos--relative">
					<div
						className="u-d--flex u-px--tiny u-px--medium--bp-large u-w--100"
						style={{ marginTop: '-5px' }}
					>
						<Popover
							buttonOnClick={handleButtonClick}
							onConfirm={handleConfirmClick}
							content={
								<>
									<h2>
										Adding this will clear your current
										basket.
									</h2>
									<p>Are you sure you want to continue?</p>
									<br />
								</>
							}
						>
							<Button
								className="u-px--tiny u-my--auto"
								variant="primary"
								onContextMenu={e => {
									e.preventDefault();

									const contextMenu = document.getElementById(
										'contextMenu'
									);
									contextMenu.style.display = 'block';
									contextMenu.style.top = e.pageY + 'px';
									contextMenu.style.left = e.pageX + 'px';
									document
										.getElementById('copyBasketLinkButton')
										.addEventListener('click', e => {
											let url = buyLink;
											if (!buyLink.includes('http')) {
												url = new URL(
													`${window.location.origin}${buyLink}`
												).toString();
											}
											navigator.clipboard
												.writeText(url)
												.then(() => {})
												.catch(() => {});
										});

									const handleOutsideClick = (e: any) => {
										if (
											!contextMenu.contains(
												e.target as Node
											)
										) {
											contextMenu.style.display = 'none';
											document.removeEventListener(
												'click',
												handleOutsideClick
											);
										}
									};

									document.addEventListener(
										'click',
										handleOutsideClick
									);
								}}
							>
								{handsetLaunched
									? 'Add to Basket'
									: 'Pre-order'}
							</Button>
						</Popover>
					</div>

					<div className="Tariff--StockMessage">
						<StockMessage deal={deal}>
							<Truck fill="#044058" />
							<div>
								<Stock />
								<Delivery />
							</div>
						</StockMessage>
					</div>
				</Column>
				<Column size={12} className={'u-p--none'}>
					<Terms deal={deal} />
				</Column>
				<Column
					size={12}
					className={!enableTCOLabel ? 'u-p--none' : ''}
				>
					<TotalCostOwnershipLabel isBasket={false} deal={deal} />
				</Column>
			</Row>
		</>
	);
}

export { HandsetDealPanelContent, HandsetDealPanelFooter };
