import type { AxiosResponse } from 'axios';
import { HandsetCondition } from 'components/Handset/Conditions';
import { Deal } from 'components/types';
import { add, format } from 'date-fns';
import type { ResponseProps } from './Fetch';

export const checkIsDealRefurbished = (deal: Deal): boolean => {
	return (
		deal.variant &&
		(deal.variant.conditionId === HandsetCondition.Refurbished ||
			deal.variant.handset.conditionId === HandsetCondition.Refurbished)
	);
};

const CHRISTMAS_BREAK_START = new Date('2024-03-27 17:00');
const CHRISTMAS_BREAK_END = new Date('2024-04-02 00:00');

const NEW_YEAR_BREAK_START = new Date('2023-12-29 17:00');
const NEW_YEAR_BREAK_END = new Date('2024-01-02 00:00');

export const isDateBetweenBreak = (): boolean => {
	const date = new Date();

	const time = date.getTime();

	return (
		(time >= CHRISTMAS_BREAK_START.getTime() &&
			time <= CHRISTMAS_BREAK_END.getTime()) ||
		(time >= NEW_YEAR_BREAK_START.getTime() &&
			time <= NEW_YEAR_BREAK_END.getTime())
	);
};

interface DeliveryDateDuringPeriod {
	formattedDate: string;
	endDate: Date;
}

export const getDeliveryDateDuringPeriod = (): DeliveryDateDuringPeriod => {
	if (!isDateBetweenBreak()) return;

	const timestampNow = new Date().getTime();

	if (
		timestampNow >= CHRISTMAS_BREAK_START.getTime() &&
		timestampNow <= CHRISTMAS_BREAK_END.getTime()
	) {
		return {
			formattedDate: format(add(CHRISTMAS_BREAK_END, { days: 1 }), 'do'),
			endDate: CHRISTMAS_BREAK_END,
		};
	}

	return {
		formattedDate: format(add(NEW_YEAR_BREAK_END, { days: 1 }), 'do'),
		endDate: NEW_YEAR_BREAK_END,
	};
};

export function convertAxiosResponseToFetchResponse(
	response: AxiosResponse
): ResponseProps {
	return {
		data: response.data,
		status: response.status,
		ok: isNumberWithinRange(response.status, 200, 299),
	};
}

function isNumberWithinRange(number: number, min: number, max: number) {
	return number >= min && number <= max;
}
