import { Deal as DealType } from 'components/types';
import { HandsetCondition } from 'components/Handset/Conditions';
import { urlToRelative } from 'components/Common/url-to-relative';
import { getDealIsSimOnly } from 'components/Common/Deals/DealPanel';

interface RouteParams {
	handsetLink?: string;
	type: string;
	capacity?: string;
	colour?: string;
	manufacturer_slug: string;
	model_slug: string;
	refurbished?: number;
}

interface Router {
	(routeName: string, params: Record<string, unknown>): {
		toString: () => string;
	};
}

export const getHandsetLink = (
	deal: DealType,
	router: Router,
	extraProps: Record<string, unknown> = {}
) => {
	const routeParams = {
		// @ts-expect-error TODO
		...extraProps.handsetLink,
	};
	const routeName = 'handset.show';

	if (getDealIsSimOnly(deal)) {
		return deal.basketLink;
	}

	const extendedRouteParams: RouteParams = {
		manufacturer_slug: deal.variant.handset.manufacturer.slug,
		model_slug: deal.variant.handset.modelSlug,
		type: getTypeForDeal(deal),
	};

	if (
		deal.variant.capacity.slug !== deal.variant.handset.defaultCapacity.slug
	) {
		routeParams.capacity = deal.variant.capacity.slug;
	}

	if (
		!routeParams.colour &&
		deal.variant.colour.slug !== deal.variant.handset.defaultColour.slug
	) {
		routeParams.colour = deal.variant.colour.slug;
	}

	if (deal.variant.handset.conditionId === HandsetCondition.Refurbished) {
		routeParams.refurbished = 1;
	}

	const url = new URL(location.href);

	if (url.searchParams.get('networks')) {
		routeParams.networks = url.searchParams.get('networks');
	}

	return urlToRelative(
		globalThis
			.Router(routeName, { ...routeParams, ...extendedRouteParams })
			.toString()
	);
};

const getTypeForDeal = (deal: DealType): string => {
	if (!deal.tariff) {
		return 'sim-free';
	}

	if (deal.tariff.type.slug === 'upgrade') {
		return 'upgrade';
	}

	if (deal.variant?.handset.conditionId === HandsetCondition.Refurbished) {
		return 'refurbished';
	}

	return 'new-contract';
};
