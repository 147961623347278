import { Flags } from 'services/LaunchDarkly/flags';
import { LaunchDarklyVariant } from './LaunchDarkly.Variant';
import { PropsWithChildren } from 'react';

interface LaunchDarklyStringProps {
	flag?: Flags;
	value: string;
}

type LaunchDarklyString = (
	props: PropsWithChildren<LaunchDarklyStringProps>
) => JSX.Element;

export const LaunchDarklyString: LaunchDarklyString = LaunchDarklyVariant;
