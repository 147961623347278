import React, { useContext } from 'react';

import { DealContext } from '.';

import { NetworkIcon } from '../../NetworkIcon';

import { Deal } from 'components/types';

function NetworkLogo() {
  const {
    deal: { tariff },
  }: { deal: Deal } = useContext(DealContext);

  const isVodafoneLogo =
    tariff.network.slug && tariff.network.slug === 'vodafone';

  return (
    <NetworkIcon
      networkSlug={tariff.network.slug}
      style={{
        width: isVodafoneLogo ? '50px' : '36px',
        height: isVodafoneLogo ? '50px' : '36px',
        margin: 'auto',
        marginTop: isVodafoneLogo ? '-12px' : 'auto',
      }}
    />
  );
}

export { NetworkLogo };
