import React from 'react';

import { ErrorBoundary } from 'components/Common/ErrorBoundary';
import { BrandSlide } from './BrandSlide';
import { HomeTitle } from '../HomeTitle';
import { Brand as BrandType } from 'components/types';
import { CarouselItems } from 'components/Common/CarouselItems';

import './PhoneBrands.scss';
import { HttpMethod, useFetch } from 'hooks/use-fetch';

const trackPhoneBrandsViewAll = {
	event: 'Phone Brands Slide Click',
	eventCategory: 'HomePage',
	eventAction: 'Brands',
	eventLabel: 'View all',
};

export function PhoneBrands() {
	const url = new URL(window.location.href);
	url.pathname = `/api/home/brands`;

	const { response } = useFetch<BrandType[]>({
		url: url.toString(),
		method: HttpMethod.GET,
	});

	if (!response) return <></>;
	return (
		<ErrorBoundary>
			<div>
				<div className="o-wrapper u-px--large--bp-medium">
					<div className="u-p--small">
						<HomeTitle
							title="Phone Brands"
							description="From Apple to Samsung, we’ve got deals on all the best for you to take your pick from."
							viewAllLink="/brands"
							trackingObject={trackPhoneBrandsViewAll}
						/>
					</div>

					<div className="u-py--small u-pt--none--bp-medium u-pb--xlarge--bp-medium u-px--xsmall--bp-medium">
						<CarouselItems
							itemCount={response.length}
							breakpoint={{ '768': 1.2, '990': 3 }}
							hideControls={true}
						>
							{response.map((brand, index) => (
								<BrandSlide key={index} {...brand} />
							))}
						</CarouselItems>
					</div>
				</div>
			</div>
		</ErrorBoundary>
	);
}
