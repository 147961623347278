import React, { AnchorHTMLAttributes, useContext } from 'react';
import { SupportPhoneNumberContext } from './SupportPhoneNumber.context';

const SupportPhoneNumber = ({
	children,
	...props
}: AnchorHTMLAttributes<HTMLAnchorElement>): JSX.Element => {
	const phoneNumber = globalThis.site.support_phone_number || '0345 470 4000';

	const ctx = useContext(SupportPhoneNumberContext);
	if (!ctx) {
		return (
			<SupportPhoneNumberContext.Provider value={phoneNumber}>
				<a href={`tel: ${phoneNumber}`} {...props}>
					{children || phoneNumber}
				</a>
			</SupportPhoneNumberContext.Provider>
		);
	}

	// We have context so we are now being called inside ourselves
	return <span {...props}>{phoneNumber}</span>;
};

export default SupportPhoneNumber;
