import { ReactElement } from 'react';

export enum Directions {
	Next = 'next',
	Previous = 'prev',
}
export interface ReducerProps {
	current: number;
	active: number[];
	slides: any;
	slideTheme: string[];
	slideCount: number;
	theme: string;
	paused: boolean;
	locked: boolean;
}

export interface ActionProps {
	type?: Directions;
	to?: number;
	pause?: boolean;
	nonUser?: boolean;
}

export interface CarouselProps {
	duration: number;
	slides?: SlideProps[] | null;
	children?: ReactElement<SlideProps> | Array<ReactElement<SlideProps>>;
}

// Slide Props
export interface SlideProps {
	children: JSX.Element;
	ctaLink: string;
	ctaText: string;
	ctaTextColour: string;
	ctaBackgroundColour: string;
	backgroundColour: string;
	desktopBackgroundImage: string | null;
	mobileBackgroundImage: string | null;
	position: string | null;
	slideTemplate: string;
	theme: string;
	props: Props;
	index: number;
}

interface ImageProps {
	src: string;
	alt: string;
	style: {
		width: string;
		height: string;
	};
}

interface Props {
	ctaLink: string;
	ctaText: string;
	className: string;
	customClassName: string;
	gaEvent: {
		event: string;
		eventCategory: string;
		eventAction: string;
		destinationurl: string;
	};
	desktopCopyImage: ImageProps;
	desktopProductImage: ImageProps;
	mobileCopyImage: ImageProps;
	mobileProductImage: ImageProps;
	showCtaBtn: boolean;
}
