import React from 'react';
import PropTypes from 'prop-types';

function NetworkIcon({ networkSlug, className = '', ...props }) {
  return (
    <span
      aria-hidden="true"
      {...props}
      className={['network-icon', networkSlug, className].join(' ')}
    />
  );
}

NetworkIcon.propTypes = {
  /**
   * @param network_id The slug of the network; e.g. "vodafone"
   */
  networkSlug: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { NetworkIcon };
