import React, { CSSProperties } from 'react';

import { Row, Column } from '@ao-internal/react-components';

import { getUpfrontAmount, DealPropType } from '.';

import { Deal as DealType } from 'components/types';
import { BuyNow } from 'components/Common/Deals/DealPanel/FooterButtons/BuyNow';
import { RefurbLabel } from 'components/Common/Deals/DealPanel/RefurbLabel';
import { TotalCostOwnershipLabel } from 'components/Common/Deals/DealPanel/TotalCostOwnershipLabel';
import './DealPanelFooter.scss';
import { getPanelFooterStylesFromPromo, IS_THEME_ACTIVE } from 'themes/helpers';
import { DealStockMessage } from 'components/Handset/DealStockMessage';
import { checkIsDealRefurbished } from 'helpers/utils';
import { AsNewLabel } from 'components/Common/Deals/DealPanel/AsNewLabel';
import classnames from 'classnames';
import { Terms } from './Terms';

function DealPanelFooter(props) {
	const deal: DealType = props.deal;
	const upfrontAmount = getUpfrontAmount(deal);
	const monthlyCost = deal.monthlyBeforeCashbackDisplay;
	const { panelFooterButton = null, isTopDeal = false } = props;
	const FooterButtonComponent = panelFooterButton || BuyNow;
	const isRefurb = checkIsDealRefurbished(deal);

	const isAsNew =
		!isRefurb && deal.variant && deal.variant.oracleCode.endsWith('ANRF');

	globalThis.MPD = globalThis.MPD || {};
	const { enableTCOLabel = false } = globalThis.MPD;

	let style: CSSProperties = {};
	const labelStyles: CSSProperties = {
		minWidth: '74px',
	};
	const costStyles: CSSProperties = {
		lineHeight: '1',
	};

	const isSpecialPink =
		deal.promo &&
		IS_THEME_ACTIVE &&
		deal.promo.primaryHex.toLowerCase() === 'ff39ef';
	let hasPromo;

	const { panelWrapperClassName } = props;

	const classNames = panelWrapperClassName.split(' ');

	if (deal.promo && IS_THEME_ACTIVE && !classNames.includes('no-bf-theme')) {
		hasPromo = true;
		style = getPanelFooterStylesFromPromo(deal.promo);
		labelStyles.color = style.color;
		costStyles.color = style.color;
	}

	return (
		<div
			className={`deal-panel-footer ${hasPromo ? 'promo-deal' : ''} ${
				isSpecialPink ? 'special-deal' : ''
			}`}
			style={style}
		>
			<Row>
				<Column
					size={12}
					className="u-bgc--background"
					style={{
						minHeight: '95px',
						borderRadius: '0 0 6px 6px',
						...style,
					}}
				>
					<Row>
						<Column size={12} className={'u-p--none'}>
							{isRefurb && (
								<RefurbLabel
									openModalWithDeal={props.openModalWithDeal}
									deal={deal}
								/>
							)}
							{!isRefurb && isAsNew && (
								<AsNewLabel
									openModalWithDeal={props.openModalWithDeal}
									deal={deal}
								/>
							)}
						</Column>
					</Row>
					<Row
						className={
							'u-p--small u-ai--center' +
							(enableTCOLabel ? 'u-pb--none' : '')
						}
					>
						<Column
							size={6}
							className={classnames('u-p--micro', {
								'u-pt--none': isTopDeal,
							})}
						>
							<Row>
								<Column size={12} className="u-pb--none">
									<div className="u-pl--tiny">
										<div
											className="u-fz--base-small"
											style={labelStyles}
										>
											Upfront Cost:
										</div>
										<div
											className="u-fz--title-small u-mb--micro u-fw--600"
											style={costStyles}
										>
											{upfrontAmount}
										</div>
									</div>
								</Column>
								<Column size={12} className="u-pt--none">
									<div className="u-pl--tiny">
										<div
											className="u-fz--base-small"
											style={labelStyles}
										>
											Monthly Cost:
										</div>
										<div
											className="deal-panel-footer-cost u-fw--600"
											style={costStyles}
										>
											{monthlyCost}
										</div>
									</div>
								</Column>
							</Row>
						</Column>
						<Column
							size={6}
							//added in conditional css as the footer component is the same for top deal as deals in mobile view, the new View More Deals button was causing too much padding making the grey footer box larger
							//same as conditional css above, upfront cost and monthly const were no longer aligned
							className={classnames(
								'u-py--small u-pr--xsmall u-pos--relative u-bc--1',
								{ 'u-py--none': isTopDeal }
							)}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									height: '100%',
								}}
							>
								<FooterButtonComponent
									extraProps={props.extraProps}
									deal={deal}
								/>
							</div>

							{/* if !panelFooterButton this means we're showing the buy now button so that we can show our stock message on the correct section. */}
							{!panelFooterButton && (
								<DealStockMessage deal={deal} />
							)}
						</Column>
					</Row>
					<Row className={!enableTCOLabel ? 'u-m--none' : ''}>
						<Column
							size={12}
							className={
								enableTCOLabel ? 'u-p--xsmall' : 'u-p--none'
							}
						>
							<TotalCostOwnershipLabel
								isBasket={false}
								deal={deal}
							/>
						</Column>
					</Row>
					<Row>
						<Column size={12} className={'u-pt--none'}>
							<Terms deal={deal} />
						</Column>
					</Row>
				</Column>
			</Row>
		</div>
	);
}
DealPanelFooter.propTypes = {
	deal: DealPropType,
};

export { DealPanelFooter };
