import React from 'react';

function TagIconDouble({ colour, ...props }) {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={colour}
      {...props}
    >
      <path d="M14.2819 7.33937L8.32275 1.33222C8.06963 1.07705 7.72622 0.933594 7.36819 0.933594H1.62734C0.881748 0.933594 0.277344 1.54287 0.277344 2.29447V8.08162C0.277344 8.44253 0.419656 8.78871 0.67278 9.04387L6.63188 15.051C7.15894 15.5823 8.01394 15.5826 8.541 15.051L14.2819 9.26388C14.8092 8.73257 14.8092 7.87097 14.2819 7.33937ZM3.42733 5.46986C2.68174 5.46986 2.07734 4.86058 2.07734 4.10898C2.07734 3.35738 2.68174 2.7481 3.42733 2.7481C4.17292 2.7481 4.77733 3.35738 4.77733 4.10898C4.77733 4.86058 4.17292 5.46986 3.42733 5.46986ZM17.8818 9.26388L12.141 15.051C11.6139 15.5826 10.7589 15.5826 10.2319 15.051L10.2217 15.0408L15.1172 10.1059C15.5953 9.62395 15.8585 8.9832 15.8585 8.30163C15.8585 7.62005 15.5953 6.97931 15.1172 6.49733L9.59793 0.933594H10.9682C11.3262 0.933594 11.6696 1.07705 11.9227 1.33222L17.8818 7.33937C18.4092 7.87097 18.4092 8.73257 17.8818 9.26388Z" />
    </svg>
  );
}
export { TagIconDouble };
