import React, { AnchorHTMLAttributes, useContext } from 'react';
import { SalesPhoneNumberContext } from './SalesPhoneNumber.context';

type SalesPhoneNumberProps = {
	returnText?: boolean;
};

const SalesPhoneNumber = ({
	children,
	returnText = false,

	...props
}: AnchorHTMLAttributes<HTMLAnchorElement> &
	SalesPhoneNumberProps): JSX.Element => {
	const phoneNumber = globalThis.site?.sales_phone_number ?? '03301 65 55 55';

	const ctx = useContext(SalesPhoneNumberContext);
	if (!ctx && !returnText) {
		return (
			<SalesPhoneNumberContext.Provider value={phoneNumber}>
				<a href={`tel: ${phoneNumber}`} {...props}>
					{children || phoneNumber}
				</a>
			</SalesPhoneNumberContext.Provider>
		);
	}

	// We have context so we are now being called inside ourselves
	return <span {...props}>{phoneNumber}</span>;
};

export default SalesPhoneNumber;
