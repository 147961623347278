import { Site } from 'components/types';
import React, { ReactNode } from 'react';

interface OpeningTimesProps {
	shortenDay?: boolean;
	to12Hours?: boolean;
	children: (
		props: {
			open: string;
			close: string | null;
			day: string;
			toDay: string | null;
		},
		index: number
	) => ReactNode;
}

export function OpeningTimes({
	children,
	shortenDay = true,
	to12Hours = true,
}: OpeningTimesProps) {
	const groups = groupConsecutiveDays(
		(globalThis.site as Site).json_opening_hours
	);

	return (
		<>
			{groups.map((group, index) => {
				let open: string,
					close: string | null,
					day: string,
					toDay: string | null;

				if (Array.isArray(group)) {
					open = group[0].open
						? convertTo12Hours(group[0].open, to12Hours)
						: 'Closed';
					close = group[0].close
						? convertTo12Hours(group[0].close, to12Hours)
						: null;
					day = capitalizeFirstLetter(group[0].day, shortenDay);
					toDay = capitalizeFirstLetter(
						group[group.length - 1].day,
						shortenDay
					);
				} else {
					open = group.open
						? convertTo12Hours(group.open, to12Hours)
						: 'Closed';
					close = group.close
						? convertTo12Hours(group.close, to12Hours)
						: null;
					day = capitalizeFirstLetter(group.day, shortenDay);
					toDay = null;
				}
				return children(
					{
						open,
						close,
						day,
						toDay,
					},
					index
				);
			})}
		</>
	);
}

const daysOrder = [
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday',
];

type JsonOpeningHoursProps = Site['json_opening_hours'];

function groupConsecutiveDays(data: JsonOpeningHoursProps) {
	const ranges = [];
	let currentRange = [];

	daysOrder.forEach((day, index) => {
		if (
			currentRange.length === 0 ||
			(data[day].open === currentRange[currentRange.length - 1].open &&
				data[day].close === currentRange[currentRange.length - 1].close)
		) {
			currentRange.push({
				day,
				open: data[day].open,
				close: data[day].close,
			});
		} else {
			ranges.push(
				currentRange.length === 1 ? currentRange[0] : currentRange
			);
			currentRange = [
				{ day, open: data[day].open, close: data[day].close },
			];
		}

		if (index === daysOrder.length - 1) {
			ranges.push(
				currentRange.length === 1 ? currentRange[0] : currentRange
			);
		}
	});

	return ranges;
}

function capitalizeFirstLetter(string: string, makeShort: boolean): string {
	if (makeShort) {
		string = shortenDay(string);
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
}

function shortenDay(day: string): string {
	return day.slice(0, 3);
}

function convertTo12Hours(time: string, convert = false): string {
	if (!convert) return time;

	const [startHours, minutes] = time.split(':');
	const hours = +startHours % 12 || 12;

	return `${hours}:${minutes}${+startHours < 12 ? 'am' : 'pm'}`;
}
