import React, { useContext } from 'react';
import { Flags } from 'services/LaunchDarkly/flags';

export const LdContext = React.createContext(null);

export interface LdContextProps {
	flags: Record<Flags, any>;
	flag?: Flags;
}

export function useLdContext<T = LdContextProps>(component?: string): T {
	const context = useContext(LdContext);

	if (!context) {
		throw new Error(
			`LaunchDarkly.${component} must be rendered as child of <LaunchDarkly /> component`
		);
	}

	return context;
}
