import React, { useEffect, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';
import { Button } from '@ao-internal/react-components';

const AccordionPropTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  openCallback: PropTypes.func,
  closeCallback: PropTypes.func,
  initiallyOpen: PropTypes.bool,
};

function Accordion({
  title,
  children,
  type,
  openCallback = null,
  closeCallback = null,
  initiallyOpen = false,
}: InferProps<typeof AccordionPropTypes>) {
  const [open, setOpen] = useState(initiallyOpen);

  const onClick = event => {
    event.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    if (open && openCallback) {
      openCallback();
    }

    if (!open && closeCallback) {
      closeCallback();
    }
  }, [open]);

  return (
    <div
      className={classnames({
        'c-accordion c-accordion--panel u-mt--tiny': true,
        'c-accordion--active': open,
        'mpd-accordion': type && type === 'button-style',
      })}
    >
      <a href="#clicked" onClick={onClick} className="c-accordion-title">
        {title}
        {type && type === 'button-style' && (
          <span className="c-btn c-btn--tertiary u-mx--tiny">
            {!open ? 'Read More' : 'Close'}
          </span>
        )}
      </a>
      <div
        className="c-accordion-content"
        aria-expanded={open ? 'true' : 'false'}
        hidden={open}
      >
        {children}
      </div>
    </div>
  );
}
Accordion.propTypes = AccordionPropTypes;

export { Accordion };
