import { add, isWithinInterval, parse, sub } from 'date-fns';

import dates from 'services/BankHoliday/BankHolidays.json';

interface bankHolidayProps {
	title: string;
	date: string;
}

export class BankHoliday {
	now: Date;

	isBankHoliday = false;
	bankHoliday: bankHolidayProps[] = [];
	cutoff: Date;

	constructor(now: Date = new Date()) {
		this.now = now;

		this.bankHoliday = dates['england-and-wales']['events'].filter(
			({ date }) =>
				isWithinInterval(this.now, {
					start: sub(parse(date, 'yyyy-MM-dd', this.now), {
						days: 1,
					}),
					end: add(parse(date, 'yyyy-MM-dd', this.now), { days: 1 }),
				})
		);

		this.isBankHoliday = this.bankHoliday.length > 0;
	}

	getCutoff(): Date {
		if (this.isBankHoliday) {
			return parse(this.bankHoliday[0].date, 'yyyy-MM-dd', this.now);
		}
	}
}
