import React, { useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { DealContext } from '.';
import { getDealIsPromo } from 'components/Common/Deals/DealPanel';

import { Deal } from 'components/types';

function Small({ children, largeFeatureSubTitle }) {
	return (
		<div
			className={classnames('u-font-group--base-small', {
				'u-fz--title-small': largeFeatureSubTitle,
			})}
			style={{ lineHeight: '1' }}
		>
			{children}
		</div>
	);
}

function PlanIncludes({
	planFeaturesClassName = '',
	largeFeatureSubTitle = false,
}: {
	planFeaturesClassName?: string;
	largeFeatureSubTitle?: boolean;
}) {
	const {
		deal,
	}: {
		deal: Deal;
	} = useContext(DealContext);

	const { minutes, texts, dataDisplay, dataPromoDisplay } = deal.tariff;

	return (
		<>
			<div
				className="u-mb--micro u-font-group--caption"
				style={{
					color: '#666666',
				}}
			>
				Plan includes:
			</div>
			<div className="o-flex-container theme-handset-panel">
				<div
					className={`o-flex-item u-pb--none u-mb--micro ${planFeaturesClassName}`}
				>
					{getDealIsPromo(deal) ? (
						<>
							<s className="u-fz--caption">was {dataDisplay}</s>
							<div className="u-fz--display u-fw--700">
								{dataPromoDisplay}{' '}
								<span className="u-fz--title">Data</span>
							</div>
						</>
					) : (
						<>
							<div className="u-fz--display u-fw--700">
								{dataDisplay}{' '}
								<span className="u-fz--title">Data</span>
							</div>
						</>
					)}
				</div>
				<div
					className={`o-flex-item u-py--none u-mb--micro ${planFeaturesClassName}`}
				>
					<div className="u-fz--base-small">{minutes} Minutes</div>
				</div>
				<div
					className={`o-flex-item u-pt--none ${planFeaturesClassName}`}
				>
					<div className="u-fz--base-small">{texts} Texts</div>
				</div>
			</div>
		</>
	);
}
PlanIncludes.propTypes = {
	/**
	 * @param planFeaturesClassName optional className to add to each plan feature's container
	 * typically for responsive customisations
	 */
	planFeaturesClassName: PropTypes.string,
};

export { PlanIncludes };
