import React from 'react';
import { Image } from 'components/Common/Image';
import './ProtectionPackIncludedItem.scss';

export function ProtectionPackIncludedItem({
	title,
	content,
	image,
}: {
	title: string;
	content: string;
	image: string;
}): JSX.Element {
	return (
		<div className="IncludedItem">
			<div className="IncludedItem--Image">
				<Image path={image} alt="" />
			</div>

			<div className="IncludedItem--Content">
				<h3 className="IncludedItem--Title">{title}</h3>
				<p>{content}</p>
			</div>
		</div>
	);
}
