import React, { CSSProperties, PropsWithChildren } from 'react';
import { SalesPhoneNumber } from '../ContactInformation';

interface StickyBannerChunkyProps {
	isBannerSticky: boolean;
	bannerVersion: string;
	isOutOfHours?: boolean;
}

export function StickyBannerChunky({
	isBannerSticky,
	bannerVersion,
	children,
	isOutOfHours,
}: PropsWithChildren<StickyBannerChunkyProps>): JSX.Element {
	const handleClick = (): void => {
		globalThis.dataLayer.push({
			event: 'MobilePropositionBannerClicked',
			eventCategory: `Mobile Proposition Banner Click ${
				bannerVersion === 'version-one'
					? 'With Phone Number'
					: 'Without Phone Number'
			}`,
			eventAction:
				'Mobile Proposition Banner Click - Dial Contact Number',
		});
	};

	const css: CSSProperties = {};

	if (bannerVersion === 'version-two') {
		css.width = '80%';
	}

	return (
		<SalesPhoneNumber
			onClick={handleClick}
			id="mobileCallUsBanner"
			className={`HeaderBanner ${
				isBannerSticky ? 'HeaderBanner--Sticky' : ''
			} ${isOutOfHours ? 'HeaderBanner--OOH' : ''}`}
		>
			<span className="inner" style={css}>
				<span className="bold">{children}</span>
			</span>
		</SalesPhoneNumber>
	);
}
