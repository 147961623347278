import React, {
	CSSProperties,
	Fragment,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { HttpMethod, useFetch } from 'hooks/use-fetch';
import { useScreenSize } from '../use-screen-size';
import './PromoInjector.scss';
import { launchDarklyClient } from 'services/LaunchDarkly/LaunchDarklyClient';
import { Flags } from 'services/LaunchDarkly/flags';
import { isBefore, isWithinInterval, parse } from 'date-fns';

enum ContentType {
	IMAGE = 'image',
	TEXT = 'text',
}

interface AssetProps {
	content_type: ContentType;
	image?: string;
	content?: string;
	offset_x: number;
	offset_y: number;
	media: string;
	position_x: string;
	position_y: string;
	force_contain: boolean;
}

interface ResponseProps {
	date_start: string;
	date_end: string | null;
	span_columns: number;
	href: string;
	assets: AssetProps[];
	background: string;
	location_mobile: number;
	location_desktop: number;
}

interface PromoInjectorProps {
	children: JSX.Element[];
}

interface BodyProps {
	url: string;
	token?: string;
}

export type CustomStyleProperties = CSSProperties & {
	'--offset_x'?: string;
	'--offset_y'?: string;
	'--abs-offset_x'?: string;
	'--abs-offset_y'?: string;
};

export function PromoInjector({ children }: PromoInjectorProps) {
	const { screenSize } = useScreenSize();
	const [loadMerchPod, setLoadMerchPod] = useState(false);
	const params = new URLSearchParams(window.location.search);

	useEffect(() => {
		launchDarklyClient
			.getFlag<boolean>(Flags.MerchPodSpace)
			.then(setLoadMerchPod);
	}, []);

	const body: BodyProps = {
		url: window.location.href.split('?')[0],
	};

	if (params.get('token')) {
		body.token = params.get('token');
	}

	const { response } = useFetch<ResponseProps[]>({
		url: '/api/merchpodspace',
		method: HttpMethod.POST,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify(body),
	});

	const isDesktop = screenSize === 'large';

	const newChildren = useMemo(() => {
		if (!response || !response.length || !loadMerchPod) return children;

		const modifiedChildren = children;
		response.forEach((promo, index) => {
			if (!body.token) {
				const start = parse(
					promo.date_start,
					'yyyy-MM-dd HH:mm:ss',
					new Date()
				);
				const end =
					promo.date_end !== null
						? parse(
								promo.date_end,
								'yyyy-MM-dd HH:mm:ss',
								new Date()
						  )
						: null;

				if (
					end !== null &&
					!isWithinInterval(new Date(), {
						start,
						end,
					})
				) {
					return <></>;
				}
				if (end === null && isBefore(new Date(), start)) return <></>;
			}

			const position =
				promo[isDesktop ? 'location_desktop' : 'location_mobile'] - 1;

			if (modifiedChildren.length < position) {
				modifiedChildren.push(
					<Promo key={`promo-${index}`} {...promo} />
				);
			} else {
				modifiedChildren.splice(
					position,
					0,
					<Promo key={`promo-${index}`} {...promo} />
				);
			}
		});
		return modifiedChildren;
	}, [isDesktop, response, loadMerchPod, children]);

	return <>{newChildren}</>;
}

function Promo({
	assets,
	span_columns,
	href,
	background,
}: ResponseProps): JSX.Element {
	const css: CSSProperties = {};
	if (background) {
		if (/^http/g.test(background)) {
			css.backgroundImage = `url('${background}')`;
		} else {
			css.backgroundColor = `#${background.replace('#', '')}`;
		}
	}

	if (!assets || !assets.length) return <></>;

	return (
		<a
			href={href}
			className={`PromoInjector PromoInjector--Span${span_columns}`}
		>
			<div className="PromoInjector__Inner" style={css}>
				{assets.map((props, key) => {
					return (
						<Fragment key={'PromoAsset' + key}>
							{props && <Asset {...props} />}
						</Fragment>
					);
				})}
			</div>
		</a>
	);
}

function Asset(
	{
		content_type,
		content,
		offset_x,
		offset_y,
		position_x,
		position_y,
		media,
		force_contain,
	}: AssetProps,
	index: number
): JSX.Element {
	const classNames = [
		'PromoInjector__Asset',
		`PromoInjector__Asset--${content_type.toUpperCase()}`,
		`PromoInjector__Asset--${position_x}-${position_y}`,
		`PromoInjector__Asset--Media-${media}`,
	];
	if (force_contain) classNames.push('PromoInjector__Contain');

	if (content_type === ContentType.IMAGE)
		return (
			<img
				className={classNames.join(' ')}
				style={
					{
						'--offset_x': `${offset_x}px`,
						'--offset_y': `${offset_y}px`,
						'--abs-offset_x': `${Math.abs(offset_x)}px`,
						'--abs-offset_y': `${Math.abs(offset_y)}px`,
					} as CustomStyleProperties
				}
				key={index}
				src={content}
				alt=""
			/>
		);
	else if (content_type === ContentType.TEXT)
		return (
			<div
				className={classNames.join(' ')}
				dangerouslySetInnerHTML={{ __html: content }}
			></div>
		);

	return <></>;
}
