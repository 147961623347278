import { Deal } from 'components/types';
import { getNow } from 'components/Common/date';
import {
	addDays,
	isAfter,
	parse,
	subDays,
	format,
	intervalToDuration,
	formatDuration,
} from 'date-fns';
import { BankHoliday } from 'services/BankHoliday/BankHoliday';
import { getDeliveryDateDuringPeriod, isDateBetweenBreak } from 'helpers/utils';

interface DeliveryDataProp {
	dispatch_date: string;
	delivery_day: string;
	cut_offs: any;
	cut_off: string;
}
/**
 * * Delivery
 * Majority of this code is copied from common/Delivery/delivery.ts
 * @param deal {Deal} pass the deal for the checks.
 */
export class Delivery {
	deal: Deal;
	data: DeliveryDataProp;
	isAfterCutoff: boolean;
	cutoff: Date;
	die: boolean;
	constructor(deal: Deal) {
		this.deal = deal;
		this.die = false;
		// Where the magic happens allows us to kill the script if delivery-data is not present
		// e.g. if (Delivery.die) return <></>;
		if (document.getElementById('delivery-data')) {
			this.data = JSON.parse(
				document.getElementById('delivery-data').innerHTML
			);

			this.cutoff = this.getCutoff();

			const bankHoliday = new BankHoliday();
			if (bankHoliday.isBankHoliday) {
				if (bankHoliday.getCutoff()) {
					this.cutoff = bankHoliday.getCutoff();
				}
			}

			this.isAfterCutoff = isAfter(getNow(), this.cutoff);
		} else {
			this.die = true;
		}

		return this;
	}

	getIsNextDayDelivery(): boolean {
		if (this.deal?.variant?.deliveryRange) return false;
		return (
			!this.isAfterCutoff &&
			isAfter(getNow(), this.getEstimatedDispatchDate())
		);
	}

	getCutoff(): Date {
		if (isDateBetweenBreak()) {
			return getDeliveryDateDuringPeriod().endDate;
		}

		return parse(
			this.data.dispatch_date + ' ' + this.data.cut_off,
			'yyyy-MM-dd HH:mm:ss',
			getNow()
		);
	}

	getEstimatedDispatchDate(deal: Deal = this.deal): Date {
		const overrideDispatchDate = this.getOverrideDispatchDate();
		// Use override dispatch date when no variant is available
		if (!deal?.variant?.deliveryDate) return overrideDispatchDate;

		const stockDeliveryDate: Date = this.getStockEstimatedDeliveryDate(
			deal
		);
		// Convert delivery date to dispatch date
		const stockDispatchDate: Date = subDays(stockDeliveryDate, 1);

		const stockDispatchAfterOverrideDispatch = isAfter(
			stockDispatchDate,
			overrideDispatchDate
		);

		const latestDispatchDate = stockDispatchAfterOverrideDispatch
			? stockDispatchDate
			: overrideDispatchDate;

		// Cutoff has passed, we are delivering a day later now
		const dispatchDate =
			getNow() > this.cutoff
				? addDays(latestDispatchDate, 1)
				: latestDispatchDate;

		return dispatchDate;
	}
	getOverrideDispatchDate(): Date {
		const overrideDispatchDate = parse(
			this.data.dispatch_date,
			'yyyy-MM-dd',
			getNow()
		);
		if (this.isAfterCutoff) {
			return addDays(overrideDispatchDate, 1);
		}
		return overrideDispatchDate;
	}
	getStockEstimatedDeliveryDate(deal: Deal = this.deal): Date {
		return parse(
			deal && deal.variant && deal.variant.deliveryDate,
			'do LLLL',
			getNow()
		);
	}

	/**
	 * Returns the estimated *delivery* date of the handset
	 * based on the delivery overrides or the basket
	 * handset's dispatch date - whichever is later
	 *
	 * TODO: add bank holiday checks
	 */
	getEstimatedDeliveryDate(deal: Deal = this.deal): Date {
		return addDays(this.getEstimatedDispatchDate(deal), 1);
	}
	getEstimatedDelivery(deal: Deal = this.deal): Date {
		return addDays(this.getEstimatedDispatchDate(deal), 1);
	}
	getEstimatedDeliveryDateFormatted(deal: Deal = this.deal): string {
		const deliveryRangeActive = deal?.variant?.deliveryRange;
		return deliveryRangeActive
			? deal.variant.deliveryRange
			: format(this.getEstimatedDeliveryDate(deal), 'do LLLL');
	}

	calcTimeLeftForNextDay(format = ['days', 'hours', 'minutes']) {
		const passedCutoff = getNow() > this.cutoff;
		const durationTillCutoff = intervalToDuration({
			start: getNow(),
			end: passedCutoff ? addDays(this.cutoff, 1) : this.cutoff,
		});

		return formatDuration(durationTillCutoff, { format });
	}
}
