import React from 'react';
import { InferProps } from 'prop-types';

import { Image, ImageProps, RetinaImage } from 'components/Common/Image';
import { HandsetImages, Variant } from 'components/types';

/**
 * Renders a layered or combined handset image depending upon
 * variant.images/featured
 */
function HandsetImage({
	variant,
	...props
}: {
	variant: Variant;
	retina?: boolean;
	displayHeight?: number;
	containerProps?: any;
} & InferProps<typeof ImageProps & React.ImgHTMLAttributes<HTMLImageElement>>) {
	const { containerProps, ...rest } = props;

	return variant.images.featured === 'combined' ? (
		<CombinedHandsetImage
			images={variant.images}
			displayName={variant.displayName}
			{...rest}
		/>
	) : (
		<LayeredHandsetImage
			images={variant.images}
			displayName={variant.displayName}
			containerProps={containerProps}
			{...rest}
		/>
	);
}

/**
 * Renders a single pre-combined handset image
 */
function CombinedHandsetImage({
	images,
	displayName,
	retina = false,
	...props
}: {
	displayName: string;
	displayHeight?: number;
	images: HandsetImages;
	retina?: boolean;
} & InferProps<typeof ImageProps & React.ImgHTMLAttributes<HTMLImageElement>>) {
	const ImageComponent = retina ? RetinaImage : Image;

	return (
		<ImageComponent
			path={images.all[images.featured].image}
			alt={images.all[images.featured].alt_text || displayName}
			aria-hidden={true}
			{...props}
		/>
	);
}

/**
 * Renders overlayed front and back handset images
 */
function LayeredHandsetImage({
	images,
	displayName,
	className = '',
	containerProps = {},
	retina = false,
	...props
}: {
	images: HandsetImages;
	displayName: string;
	displayHeight?: number;
	className?: string;
	containerProps?: any;
	retina?: boolean;
} & InferProps<typeof ImageProps & React.ImgHTMLAttributes<HTMLImageElement>>) {
	const ImageComponent = retina ? RetinaImage : Image;

	const { style = {} } = props;

	return (
		<div {...containerProps}>
			<div className={`u-pos--relative ${className}`}>
				<ImageComponent
					{...props}
					path={images.all.back.image}
					alt={images.all.back.alt_text || displayName}
					aria-hidden={true}
					className="u-pos--absolute"
					style={{
						maxHeight: 'none',
						...style,
					}}
				/>
				<ImageComponent
					{...props}
					path={images.all.front.image}
					alt={images.all.front.alt_text || displayName}
					aria-hidden={true}
					className="u-pos--absolute"
					style={{
						maxHeight: 'none',
						...style,
						transform: 'translateX(30%)',
					}}
				/>
			</div>
		</div>
	);
}

export { HandsetImage, LayeredHandsetImage, CombinedHandsetImage };
