import React, { useState } from 'react';

import { Row, Column } from '@ao-internal/react-components';

import { Modal } from 'components/Common/Modal';
import { Deal as DealType } from 'components/types';

import { InfoIcon } from 'components/Common/Icons/InfoIcon';
import { Tick } from 'components/Common/Icons/Tick';

function trackTcoLabelClicked() {
  globalThis.dataLayer.push({
    event: 'TCO Label Click',
    eventCategory: 'Ecommerce',
    eventAction: 'Product Detail',
    eventLabel: 'TCO Label Clicked',
  });
}

function trackUfcPageLinkClicked() {
  globalThis.dataLayer.push({
    event: 'TCO Modal Learn More Click',
    eventCategory: 'Ecommerce',
    eventAction: 'Product Detail',
    eventLabel: 'TCO Modal Learn More Clicked',
  });
}

function TotalCostOwnershipLabel({
  deal,
  isBasket,
}: {
  deal: DealType;
  isBasket: boolean;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const isRolling = deal.tariff && deal.tariff.length === 1;
  const isSimFree = deal.type && deal.type === 'sim-free';
  const years = deal.tariff && deal.tariff.length / 12;
  const period = years > 1 ? 'years' : 'year';

  globalThis.MPD = globalThis.MPD || {};
  const { enableTCOLabel = false } = globalThis.MPD;

  function openModal() {
    setModalOpen(true);
    trackTcoLabelClicked();
  }

  if (enableTCOLabel && isRolling) {
    return (
      <>
        <div
          style={{
            background: 'transparent',
            backgroundColor: 'rgba(0, 116, 204, 0.1)',
            borderRadius: '10px',
          }}
          className="u-fw--400 u-fz--base-small u-pl--small u-py--xsmall u-pos--relative"
        >
          <div>
            <div
              className="u-pr--micro u-fw--700"
              style={{ lineHeight: '1.7' }}
            >
              No Contract
            </div>
            <div style={{ lineHeight: '1.7' }}>
              Unlike contracts, this is a rolling monthly SIM plan, so you can
              change or cancel it at any time
            </div>
          </div>
        </div>
      </>
    );
  }

  if (enableTCOLabel && !isSimFree) {
    return (
      <>
        <div
          role="button"
          tabIndex={0}
          onKeyPress={openModal}
          onClick={!isRolling ? openModal : null}
          style={{
            background: 'transparent',
            backgroundColor: 'rgba(0, 116, 204, 0.1)',
            borderRadius: '10px',
          }}
          className="c-btn--deal-feature-button u-fw--400 u-fz--base-small u-pl--small u-py--xsmall u-pos--relative"
          aria-label="View cost breakdown"
        >
          {isRolling ? (
            <div>
              <div
                className="u-pr--micro u-fw--700"
                style={{ lineHeight: '1.7' }}
              >
                No Contract
              </div>
              <div style={{ lineHeight: '1.7' }}>
                Unlike contracts, this is a rolling monthly SIM plan, so you can
                change or cancel it at any time
              </div>
            </div>
          ) : (
            <div>
              <div
                className="u-pr--micro u-fw--700"
                style={{ lineHeight: '1.7' }}
              >
                Total cost over {`${years} ${period}`}: {deal.tcoDisplay}
              </div>
              <div style={{ lineHeight: '1.7' }}>
                {isBasket
                  ? 'View the cost breakdown of your contract'
                  : 'You can reduce the total cost by paying more upfront'}
              </div>
              <div
                className="u-fw--700 u-c--action"
                style={{ lineHeight: '1.7' }}
              >
                Learn More
              </div>
            </div>
          )}

          <div
            className="u-pos--absolute"
            style={{ right: '10px', top: '10px' }}
          >
            <InfoIcon colour="#0074CC" />
          </div>
        </div>
        <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title="Cost Breakdown"
        >
          <TotalCostOwnershipModal deal={deal} />
        </Modal>
      </>
    );
  }

  return null;
}

function TotalCostOwnershipModal({ deal }: { deal: DealType }) {
  const isCashback = deal && !!deal.cashback;
  const isSimo = deal.tariff && deal.tariff.type.slug === 'sim-only';

  return (
    <>
      <div
        className="u-px--large u-px--giant--bp-medium u-py--medium u-bgc--background"
        style={{
          margin: '-16px',
        }}
      >
        <div className=" u-w--100">
          {!isSimo && (
            <>
              <Row>
                <h3
                  className="u-pb--micro u-w--100"
                  style={{
                    borderBottom: '2px solid #DADADA',
                  }}
                >
                  What you pay today
                </h3>
              </Row>
              <Row className="u-py--large">
                <Column size={9}>
                  <Row>
                    <p>Upfront Cost</p>
                  </Row>
                </Column>
                <Column size={3}>
                  <Row className="u-ta--right">
                    <p className="u-w--100">{deal.upfrontDisplay}</p>
                  </Row>
                </Column>
              </Row>
            </>
          )}

          <Row>
            <h3
              className="u-pb--micro u-w--100"
              style={{
                borderBottom: '2px solid #DADADA',
              }}
            >
              What you pay over the contract
            </h3>
          </Row>
          <Row className="u-pb--large">
            <Column size={9}>
              <Row className="u-pt--large">
                <p>Contract Length</p>
              </Row>
              <Row className="u-pt--large">
                <p>Monthly Cost </p>
              </Row>
              <Row className="u-pt--large">
                <p>Monthly Cost Over {deal.tariff.length} Months</p>
              </Row>
              {isCashback && (
                <>
                  <Row className="u-pt--large">
                    <p>Monthly cost after cashback</p>
                  </Row>
                  <Row className="u-pt--large">
                    <p>
                      Monthly Cost Over {deal.tariff.length} Months after
                      cashback
                    </p>
                  </Row>
                </>
              )}
            </Column>
            <Column size={3}>
              <Row className="u-pt--large u-ta--right">
                <p className="u-w--100">{deal.tariff.length} Months </p>
              </Row>
              <Row className="u-pt--large u-ta--right">
                <p className="u-w--100">{deal.monthlyBeforeCashbackDisplay}</p>
              </Row>
              <Row className="u-pt--large u-ta--right">
                <p className="u-w--100">{deal.totalMonthlyOverLengthDisplay}</p>
              </Row>
              {isCashback && (
                <>
                  <Row className="u-pt--large u-ta--right">
                    <p className="u-w--100">
                      {deal.monthlyAfterCashbackDisplay}
                    </p>
                  </Row>
                  <Row className="u-pt--large u-ta--right">
                    <p className="u-w--100">
                      {deal.totalMonthlyOverLengthCashbackDisplay}
                    </p>
                  </Row>
                </>
              )}
            </Column>
          </Row>
        </div>
      </div>
      <div
        className="u-bgc--inverted w-100 u-px--large u-px--giant--bp-medium u-py--medium"
        style={{
          margin: '-16px',
        }}
      >
        <Row>
          <Column size={9} className="u-pl--none">
            <h3 className="u-pl--small u-pl--none">Total Cost</h3>
          </Column>
          <Column size={3} className="u-ta--right u-pr--none">
            <h3 className="u-c--brand u-w--100">{deal.tcoDisplay}</h3>
          </Column>
          <Column size={12} className="u-px--none u-py--medium">
            <div
              className="u-fz--base-small"
              style={{
                background: 'rgba(0, 116, 204, 0.1)',
                borderRadius: '10px',
              }}
            >
              <div className="u-px--small u-py--xsmall">
                <div
                  className="u-pr--micro u-fw--700"
                  style={{ lineHeight: '1.7' }}
                >
                  Benefits of paying more upfront:
                </div>
                <ul className="u-lst--none u-m--none u-pl--none u-py--tiny">
                  <li style={{ lineHeight: '1.7' }}>
                    <span className="u-pr--tiny">
                      <Tick colour="#0564C2" />
                    </span>
                    Helps you pass credit checks
                  </li>
                  <li style={{ lineHeight: '1.7' }}>
                    <span className="u-pr--tiny">
                      <Tick colour="#0564C2" />
                    </span>
                    Lowers the total cost you’ll end up paying overall
                  </li>
                </ul>
                <a
                  className="u-fw--700 u-c--action"
                  style={{ lineHeight: '1.7' }}
                  href="/help/upfront-cost-explained"
                  target="_blank"
                  onClick={() => trackUfcPageLinkClicked()}
                >
                  Learn More
                </a>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    </>
  );
}

export { TotalCostOwnershipLabel };
