import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import './HomeTitle.scss';

const HomeTitleProps = {
	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	description: PropTypes.string,
	viewAllLink: PropTypes.string,
	viewAllProps: PropTypes.object,
	trackingObject: PropTypes.object,
};

/**
 * Home page section titles with view all button
 */
function HomeTitle({
	title,
	className = '',
	description = null,
	viewAllLink = null,
	viewAllProps,
	trackingObject,
}: InferProps<typeof HomeTitleProps>) {
	function trackingFunction() {
		globalThis.dataLayer.push(trackingObject);
	}

	return (
		<div
			className={`home-title u-py--medium--bp-medium u-d--flex u-d--block--bp-medium u-jc--space-between ${className}`}
		>
			<div>
				<h2 className="u-ta--center--bp-medium u-mb--tiny--bp-medium">
					{title}
				</h2>
				{description && (
					<p className="u-fz--title-small u-fz--display-small--bp-medium u-pt--micro u-pr--large--bp-medium u-ta--center--bp-medium">
						{description}
					</p>
				)}
			</div>
			{viewAllLink && (
				<div className="u-ml--xsmall u-ml--none--bp-medium">
					<a
						href={viewAllLink}
						className="u-fz--base-small u-fw--500 u-mb--micro--bp-medium"
						{...viewAllProps}
						onClick={() => trackingFunction()}
						aria-label={`view all ${title}`}
					>
						View all &gt;
					</a>
				</div>
			)}
		</div>
	);
}
HomeTitle.propTypes = HomeTitleProps;

export { HomeTitle };
