import React from 'react';

function InfoIconFill({ colour, ...props }) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
      fill={colour}
      {...props}
    >
      <path d="M6.5 0C2.90927 0 0 2.9119 0 6.5C0 10.0907 2.90927 13 6.5 13C10.0907 13 13 10.0907 13 6.5C13 2.9119 10.0907 0 6.5 0ZM6.5 2.88306C7.10806 2.88306 7.60081 3.37581 7.60081 3.98387C7.60081 4.59194 7.10806 5.08468 6.5 5.08468C5.89194 5.08468 5.39919 4.59194 5.39919 3.98387C5.39919 3.37581 5.89194 2.88306 6.5 2.88306ZM7.96774 9.54032C7.96774 9.71331 7.82621 9.85484 7.65323 9.85484H5.34677C5.17379 9.85484 5.03226 9.71331 5.03226 9.54032V8.91129C5.03226 8.73831 5.17379 8.59677 5.34677 8.59677H5.66129V6.91935H5.34677C5.17379 6.91935 5.03226 6.77782 5.03226 6.60484V5.97581C5.03226 5.80282 5.17379 5.66129 5.34677 5.66129H7.02419C7.19718 5.66129 7.33871 5.80282 7.33871 5.97581V8.59677H7.65323C7.82621 8.59677 7.96774 8.73831 7.96774 8.91129V9.54032Z" />
    </svg>
  );
}
export { InfoIconFill };
