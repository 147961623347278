import { Basket, Deal } from '../types';
import { basketToDeal } from 'components/Checkout/helpers';
import { TRADE_IN_CONDITION } from 'config/checkout';

import { getHasCashback } from 'components/Common/Deals/DealPanel';
import { getActiveInsurance } from 'components/Basket/InsurancePanel/selectors';
import { checkIsDealRefurbished } from 'helpers/utils';

/**
 * E.g. "Lister Page"
 */
const getPageType = () => window.MPD?.Analytics?.pageType || '';
/**
 * E.g. "Promotions" or "Brand Apple"
 */
const getPageProductType = () =>
	window.MPD?.Analytics?.productType?.replace(': ', ' ') || '';
/**
 * Combination of the above so we have a unique page identifier
 * in analytics, e.g. "Lister Page Promotions: "
 */
const getPageName = () => {
	const pageType = getPageType();
	const productType = getPageProductType();
	if (!pageType && !productType) {
		return 'Unknown';
	}
	return (pageType + ':' + productType).trim();
};

export const stateToString = (state: any): string =>
	getPageName() +
	'|' +
	Object.keys(state)
		.map(filterKey => {
			const filter = state[filterKey];

			if (filter && typeof filter === 'object') {
				const activeOptions = filter
					.filter(({ active }) => active)
					.map(({ name }) => name)
					.join(',')
					.replace(' ', '_');

				if (!activeOptions) {
					return `${filterKey}:0`;
				}

				return `${filterKey}:${activeOptions}`;
			}
			// This filter is not applicable to the page type
			else if (filter === null) {
				return `${filterKey}:NA`;
			}

			return `${filterKey}:${filter ? 1 : 0}`;
		})
		.filter(Boolean)
		.join('|');

export const getActiveFilters = filter => {
	let activeFilter = !filter
		? 'none'
		: filter
				.filter(({ active }) => active)
				.map(({ name }) => name)
				.join(',')
				.replace(' ', '_');

	if (!activeFilter) {
		activeFilter = 'none';
	}

	return activeFilter;
};

export const getActiveFilterList = (newState): string => {
	const filterList = `offers:${getActiveFilters(
		newState.offers
	)}|monthlyCosts:${getActiveFilters(
		newState.monthlyCosts
	)}|upfrontCosts:${getActiveFilters(
		newState.upfrontCosts
	)}|sortingModes:${getActiveFilters(
		newState.sortingModes
	)}|dataOptions:${getActiveFilters(
		newState.dataOptions
	)}|brands:${getActiveFilters(newState.brands)}|fiveG:${
		newState.fiveG
	}|cashback:${newState.cashback}|page:${newState.page}|refurbished:${
		newState.refurbished
	}|network:${newState.network}|networks:${getActiveFilters(
		newState.networks
	)}`;

	return filterList;
};

export const productFilterClick = (action, newState) => {
	globalThis.dataLayer.push({
		event: 'productFilterClick',
		productFilter: action.type,
		productFiltersSelected: getActiveFilterList(newState),
	});
};

export const dealToDataLayerStructure = (
	deal: Deal,
	onlyTrack: 'variant' | 'tariff' = null,
	trackPropType = false
) => {
	const dataLayer = [];

	if (deal.variant && (!onlyTrack || onlyTrack === 'variant')) {
		const productDataLayer = {
			name: deal.variant.handset.name,
			id: deal.variant.mpn ?? deal.variant.handset.slug,
			brand: deal.variant.handset.manufacturer.name,
			price: deal.upfront,
			category: 'Handset',
			variant: `${deal.variant.colour.name} ${deal.variant.capacity.name}`,
		};

		if (trackPropType) {
			let cashback = 'NCB ';

			if (deal.dealTypeId === 2) {
				cashback = 'ACB ';
			}

			if (deal.dealTypeId === 3) {
				cashback = 'RCB ';
			}

			const linkedProposition = `${deal.variant.handset.name}|${
				deal.variant.capacity.name
			}|${deal.monthlyAfterCashbackDisplay}|${
				deal.tariff ? deal.tariff.dataDisplay : 'No Tariff'
			}|${deal.upfrontDisplay}|${cashback}${deal.cashbackDisplay}|${
				deal.bundle ? deal.bundle.name : 'No Gift'
			}|${deal.tariff ? deal.tariff.network.name : 'Sim Free'}`;
			const propositionType =
				globalThis.MPD.AnalyticsPropType === 'active'
					? 'Active'
					: 'Passive';

			productDataLayer['dimension19'] = linkedProposition;
			productDataLayer['dimension25'] = propositionType;
		}

		dataLayer.push(productDataLayer);
	}

	if (deal.tariff && (!onlyTrack || onlyTrack === 'tariff')) {
		let category = 'Tariff / ';

		if (deal.tariff) {
			switch (deal.tariff.type.slug) {
				case 'new-contract':
					category += 'New Contract / ';
					break;
				case 'upgrade':
					category += 'Upgrade / ';
					break;
				case 'sim-only':
					category += 'SIM Only / ';
					break;
			}

			category += `${deal.monthlyBeforeCashbackDisplay}~${deal.tariff.length} / `;
		} else {
			category += 'SIM Free / ';
			category += 'NA~NA / ';
		}

		category += deal.bundle ? deal.bundle.name : 'NA';
		category +=
			deal.cashback > 0
				? `~${deal.cashbackDisplay} ${deal.dealType}`
				: '~£0 Cashback';
		category += ` / ${deal.upfrontDisplay}`;

		let tariffVariant = 'NA';
		let tariffName = 'NA';
		let tariffId = 'NA';
		let tariffPrice = 0;
		let tariffBrand = 'NA';

		if (deal.tariff) {
			tariffVariant = `${deal.tariff.dataDisplay}~${deal.tariff.minutes}~${deal.tariff.texts}`;
			tariffName = deal.tariff.name.replace('&pound;', '£');
			tariffId = deal.tariff.slug;
			tariffPrice = deal.monthlyBeforeCashback;
			tariffBrand = deal.tariff.network.name;
		}

		dataLayer.push({
			category,
			name: tariffName,
			id: tariffId,
			brand: tariffBrand,
			price: tariffPrice,
			variant: tariffVariant,
		});
	}

	if (deal.bundle && (!onlyTrack || onlyTrack === 'tariff')) {
		dataLayer.push({
			name: deal.bundle.name,
			category: 'Bundle',
			id: deal.bundle.slug,
			brand: 'NA',
			variant: 'NA',
		});
	}

	if (deal.cashback > 0 && (!onlyTrack || onlyTrack === 'tariff')) {
		const cashbackType =
			deal.dealTypeId === 2 ? 'Auto Cashback' : 'Cashback By Redemption';
		dataLayer.push({
			name: cashbackType,
			category: 'Cashback',
			id: deal.dealType,
			brand: 'NA',
			variant: 'NA',
		});
	}

	return dataLayer;
};

export const pushDealsToDataLayer = (
	deals: Deal[],
	list,
	countBase = 0,
	track: 'variant' | 'tariff' = null
) => {
	const impressions: Array<any> = [];

	deals.forEach((deal: Deal) => {
		const position = ++countBase;
		const dataLayer = dealToDataLayerStructure(deal, track);
		const isRefurb = checkIsDealRefurbished(deal);

		dataLayer.forEach(dataLayerItem => {
			impressions.push({
				...dataLayerItem,
				name: isRefurb
					? `${dataLayerItem.name} Refurb`
					: dataLayerItem.name,
				list,
				position,
				dimension30: isRefurb ? 'Refurb' : 'New',
			});
		});
	});

	globalThis.dataLayer.push({
		event: 'productImpression',
		ecommerce: {
			currencyCode: 'GBP',
			impressions,
		},
	});
};

export const trackProductView = (
	deal: Deal,
	track: 'variant' | 'tariff' = null,
	event = 'productDetail'
) => {
	const isRefurb = checkIsDealRefurbished(deal);

	globalThis.dataLayer.push({
		event,
		ecommerce: {
			detail: {
				products: dealToDataLayerStructure(deal, track).map(
					product => ({
						...product,
						name: isRefurb
							? `${product.name} Refurb`
							: product.name,
						position: 1,
						dimension30: isRefurb ? 'Refurb' : 'New',
					})
				),
			},
		},
	});
};

export const trackProductClick = (
	deal: Deal,
	position,
	list,
	href,
	track: 'variant' | 'tariff' = null
) => {
	const isRefurb = checkIsDealRefurbished(deal);

	const products = dealToDataLayerStructure(deal, track).map(
		dataLayerItem => ({
			...dataLayerItem,
			position,
			list,
			dimension30: isRefurb ? 'Refurb' : 'New',
		})
	);

	globalThis.dataLayer.push({
		event: 'productClick',
		ecommerce: {
			click: {
				products,
				actionField: {
					list,
				},
			},
		},
		eventCallback: function () {
			document.location.href = href;
		},
	});
};

/**
 * Tracks the most recently interacted field upon leaving checkout
 *
 * @param lastInteractedField name of the most recently interacted field
 */
export const trackCheckoutExit = lastInteractedField => {
	globalThis.dataLayer.push({
		event: 'lastInteractedField',
		lastInteractedField,

		eventCategory: 'Checkout',
		eventAction: 'Last interacted field',
		eventLabel: lastInteractedField,
		navigatingTo: window.MPD.navigatingTo || 'back or closed',
	});
};

declare interface UpdateCartDataLayer {
	name?: string;
	id?: number | string;
	price?: number;
	category?: string;
	dimension20?: string;
}

export const trackUpdateCart = (
	props: UpdateCartDataLayer,
	action: 'add' | 'remove' = 'add'
) => {
	const actionToEvent = {
		add: 'addToCart',
		remove: 'removeFromCart',
	};
	globalThis.dataLayer.push({
		event: actionToEvent[action],
		ecommerce: {
			currencyCode: 'GBP',
			[action]: {
				products: [
					{
						...props,
						quantity: 1,
					},
				],
			},
		},
	});
};

const trackedCartSteps = new Set([]);

export const trackCheckoutInteraction = (
	basket: Basket,
	step,
	option = null,
	eventCallback = null
) => {
	if (trackedCartSteps.has(step)) {
		return;
	}
	trackedCartSteps.add(step);

	const deal = basketToDeal(basket);

	const isRefurb = checkIsDealRefurbished(deal);

	let products = dealToDataLayerStructure(deal, null, true).map(
		dataLayerItem => ({
			...dataLayerItem,
			name: isRefurb
				? `${dataLayerItem.name} Refurb`
				: dataLayerItem.name,
			quantity: 1,
			dimension30: isRefurb ? 'Refurb' : 'New',
		})
	);

	if (basket.hasTradeIn) {
		const condition = TRADE_IN_CONDITION.find(
			({ id }) => basket.tradeIn.condition === id
		).title;
		products.push({
			name: `Trade in: ${basket.tradeIn.name}`,
			id: basket.tradeIn.id,
			price: basket.tradeIn.price,
			category: `Trade In / ${condition} / ${basket.tradeIn.priceDisplay}`,
			quantity: 1,
		});
	}

	if (basket.hasInsurance) {
		const activeInsurance = getActiveInsurance(basket);
		products.push({
			name: 'Insurance',
			id: 'insurance',
			price: activeInsurance.monthlyCost,
			category:
				activeInsurance.type +
				' Insurance / ' +
				activeInsurance.monthlyCostDisplay,
			dimension20: getDimension20StringFromBasket(basket),
		});
	}

	if (basket.accessories.length > 0) {
		products = [
			...products,
			...basket.accessories.map(accessory => ({
				name: accessory.name,
				id: accessory.sku,
				price: Number(accessory.cost.amount) / 100,
				category: `Accessory / ${accessory.type} / ${
					basket.variant && basket.variant.handset.name
				}`,
				dimension20: getDimension20StringFromBasket(basket),
				variant: '',
				quantity: accessory.quantity,
			})),
		];
	}

	const dataLayer = {
		event: 'checkoutVPV',
		totalValue: basket.tcoActual,
		ecommerce: {
			currencyCode: 'GBP',
			checkout: {
				actionField: { step, option },
				products,
			},
		},
		eventCallback,
	};

	globalThis.dataLayer.push(dataLayer);
};

export const trackEmptyCart = (basket: Basket) => {
	const deal = basketToDeal(basket);

	const isRefurb = checkIsDealRefurbished(deal);

	let products = dealToDataLayerStructure(deal).map(dataLayerItem => ({
		...dataLayerItem,
		name: isRefurb ? `${dataLayerItem.name} Refurb` : dataLayerItem.name,
		quantity: 1,
		dimension30: isRefurb ? 'Refurb' : 'New',
	}));

	if (basket.hasTradeIn) {
		const condition = TRADE_IN_CONDITION.find(
			({ id }) => basket.tradeIn.condition === id
		).title;
		products.push({
			name: `Trade in: ${basket.tradeIn.name}`,
			id: basket.tradeIn.id,
			price: basket.tradeIn.price,
			category: `Trade In / ${condition} / ${basket.tradeIn.priceDisplay}`,
			quantity: 1,
		});
	}

	if (basket.hasInsurance) {
		const activeInsurance = getActiveInsurance(basket);
		products.push({
			name: 'Insurance',
			id: 'insurance',
			price: activeInsurance.monthlyCost,
			category:
				activeInsurance.type +
				' Insurance / ' +
				activeInsurance.monthlyCostDisplay,
			dimension20: getDimension20StringFromBasket(basket),
		});
	}

	if (basket.accessories.length > 0) {
		products = [
			...products,
			...basket.accessories.map(accessory => ({
				name: accessory.name,
				id: accessory.sku,
				price: Number(accessory.cost.amount) / 100,
				category: `Accessory / ${accessory.type} / ${
					basket.variant && basket.variant.handset.name
				}`,
				dimension20: getDimension20StringFromBasket(basket),
				variant: '',
				quantity: accessory.quantity,
			})),
		];
	}

	globalThis.dataLayer.push({
		event: 'removeFromCart',
		totalValue: basket.tcoActual,
		ecommerce: {
			currencyCode: 'GBP',
			remove: {
				products,
			},
		},
	});
};

/**
 * A click event handler generator for links
 *
 * <a href={link} onClick={createTrackLinkOnClick(link, {
 *    eventCategory: "Merchandis banners",
 *    eventAction: "Merch banner click"
 *  })} />
 */
export function createTrackLinkOnClick(href: string, gaEvent) {
	return function (e) {
		e.stopPropagation();

		globalThis.dataLayer.push({
			...gaEvent,
			eventCallback: function () {
				document.location.href = href;
			},
		});
	};
}

export function getDimension20StringFromBasket(basket: Basket) {
	if (!basket.variant) return;

	const dimensions: string[] = [
		basket.variant.handset.name,
		basket.variant.capacity.name,
	];
	if (basket.tariff) {
		dimensions.push(basket.monthlyAfterCashbackDisplay);
	}
	dimensions.push(
		(basket.tariff && basket.tariff.dataDisplay) || 'No Tariff'
	);
	dimensions.push(basket.upfrontDisplay);
	let cashbackIdentifier = 'NCB';
	if (getHasCashback(basket)) {
		cashbackIdentifier = basket.dealTypeId === 2 ? 'ACB' : 'RCB';
	}
	dimensions.push(`${cashbackIdentifier} ${basket.cashbackDisplay}`);

	dimensions.push(basket.bundle ? basket.bundle.name : 'No Gift');

	dimensions.push(
		(basket.tariff && basket.tariff.network.name) || 'Sim Free'
	);

	return dimensions.join('|');
}
