import { useScreenSize } from 'components/Common/use-screen-size';
import { RetinaImage } from 'components/Common/Image';
import React from 'react';

export const BlackFridayBanner = ({
	onClick,
}: {
	onClick: () => void;
}): JSX.Element => {
	const { screenSize } = useScreenSize();
	const isMobile =
		screenSize === 'xsmall' ||
		screenSize === 'small' ||
		screenSize === 'medium';

	const renderMobileBanner = (): JSX.Element => {
		return (
			<a
				onClick={onClick}
				className="banner-bg u-my--medium"
				href="/deals/black-friday-deals"
			>
				<span className="images-container">
					<RetinaImage
						alt="Hero handset 1"
						height={70}
						path="/images/themes/black-friday/hero-handsets-desktop-exploaded-top-left.png"
						className="image top-left"
					/>
					<RetinaImage
						alt="Hero handset 2"
						height={70}
						path="/images/themes/black-friday/hero-handsets-desktop-exploaded-top-right.png"
						className="image top-right"
					/>
					<RetinaImage
						alt="Hero handset 3"
						height={70}
						path="/images/themes/black-friday/hero-handsets-desktop-exploaded-bottom.png"
						className="image bottom"
					/>
				</span>
				<span className="message banner-bg-mobile u-px--micro u-py--medium u-ta--center u-mx--auto">
					<p className="u-mb--micro u-fz--base u-bf--cta-text u-fw--400">
						Black Friday competition
					</p>
					<h2 className="u-font-group--title u-fw--600">
						<span className="red">win</span> a new smartphone
					</h2>
					<p className="u-fz--base-small u-fw-400">Find out more</p>
				</span>
			</a>
		);
	};

	if (isMobile) {
		return renderMobileBanner();
	}

	return (
		<a
			className="banner-bg u-my--medium u-d--flex u-ai--center"
			href="/deals/black-friday-deals"
			onClick={onClick}
		>
			<span className="left-wrap">
				<span className="banner-left">
					<RetinaImage
						path="/images/themes/black-friday/bf-mega-deals-main-shout.png"
						alt="Black Friday deals logo"
						displayHeight={70}
					/>
					<h2
						className="u-fz--title u-ta--center"
						style={{ margin: '3px 25px 0 0' }}
					>
						Coming soon!
					</h2>
				</span>
			</span>

			<span className="right-wrap">
				<span className="banner-right u-d--flex u-ai--center u-jc--space-between">
					<span className="message u-ta--center u-mx--auto">
						<p className="u-mb--small u-fz--base u-bf--cta-text u-fw--400">
							Enter our Black Friday competition
						</p>
						<h2 className="u-fz--title-large">
							<span className="red">win</span> a new smartphone
						</h2>
						<p className="u-fz--base u-fw--400">Find out more</p>
					</span>
					<RetinaImage
						path="/images/landing-pages/black-friday-deals/bf-ryi-handset-images.png"
						displayHeight={130}
						className="u-mb--small u-mr--small"
						alt="Black friday register your interest handset"
					/>
				</span>
			</span>
		</a>
	);
};
