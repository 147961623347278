/**
 * Quick Link component
 */
import React from 'react';
import PropTypes from 'prop-types';

import './QuickLink.scss';

function QuickLink({ url, title, text, Icon }) {
  const onClick = event => {
    globalThis.dataLayer.push({
      event: 'homePod',
      eventCategory: 'Quick link',
      eventAction: title,
      eventCallback: function () {
        document.location.href = url;
      },
      eventLabel: '',
    });
  };

  return (
    <a
      className="mpd-quick-link u-mr--medium u-pl--small u-py--small"
      href={url}
      onClick={onClick}
    >
      <Icon className="mpd-quick-link__icon u-mr--small" />
      <div className="mpd-quick-link__copy">
        <h3 style={{ lineHeight: '1.4' }}>{title}</h3>
        <div style={{ lineHeight: '1.4' }} className="u-font-group--base-small">
          {text}
        </div>
      </div>
    </a>
  );
}

QuickLink.propTypes = {
  /**
   * @param url url for quick link
   */
  url: PropTypes.string.isRequired,
  /**
   * @param title title for quick link
   */
  title: PropTypes.string.isRequired,
  /**
   * @param text text for quick link
   */
  text: PropTypes.string.isRequired,
  /**
   * @param Icon Icon component to use
   */
  Icon: PropTypes.func.isRequired,
};

export { QuickLink };
