import axios from 'axios';
import { urlToRelative } from 'components/Common/url-to-relative';
import { Deal } from 'components/types';

export const getTopDeals = (): Promise<Deal[]> => {
	return fetch('/api/home/top-deals')
		.then(res => res.json())
		.then(data => data)
		.catch(error => {});
};

/**
 * Gets the latest CSRF token from laravel
 */
export async function getCSRFToken() {
	const url = urlToRelative(globalThis.Router('api.csrf').toString());

	const response = await axios.get(url);
	if (response.status !== 200) {
		// eslint-disable-next-line no-console
		console.error("Couldn't retrieve CSRF: " + status);
	}
	return response.data;
}

/**
 * Updates axios global headers to use latest CSRF token from
 * /api/csrf
 */
export async function updateCSRFToken() {
	const CSRF = await getCSRFToken();
	globalThis.axios.defaults.headers.common['X-CSRF-TOKEN'] = CSRF;
}
