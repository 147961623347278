/**
 * Generic deal title
 *
 * Displays a sub title before main title on SIM Only deals
 */

import React, { useContext } from 'react';
import { DealContext, getDealSubtitle, getShortDealTitle } from '.';
import { trackProductClick } from 'components/Common/analytics';
import { Deal } from 'components/types';
import { checkIsDealRefurbished } from 'helpers/utils';
import { getHandsetLink } from 'components/Common/Deals/helpers';

function ShortDealTitle(extraProps = {}) {
	const {
		deal,
		position,
		list,
	}: { deal: Deal; position: number; list: any } = useContext(DealContext);

	const title = getShortDealTitle(deal);
	const subtitle = getDealSubtitle(deal);
	const handsetLink = getHandsetLink(deal, globalThis.Router, extraProps);
	const hasPlacementLabel = deal.variant && deal.variant.handset.placement;

	const isRefurb = checkIsDealRefurbished(deal);

	const onClick = event => {
		trackProductClick(deal, position, list, handsetLink);
	};

	return (
		<a href={handsetLink} onClick={onClick}>
			{subtitle && (
				<div className="u-font-group--title u-fz--base-small u-fw--500 u-py--none">
					{subtitle}
					{hasPlacementLabel && (
						<span
							className="u-c--inverted u-fw--600 u-pos--relative deal-box-placement-label"
							style={{
								backgroundColor:
									deal.variant.handset.placement.labelColour,
								top: '-1px',
								marginLeft: '4px',
								padding: '2px 6px',
								borderRadius: '2px',
								fontSize: '10px',
								whiteSpace: 'nowrap',
							}}
						>
							{deal.variant.handset.placement.label}
						</span>
					)}
				</div>
			)}
			<h3>
				{title} {isRefurb && '- Refurbished'}
			</h3>
		</a>
	);
}
export { ShortDealTitle };
